<template>
	<div :class="{'question-wrapper': true, 'collapsed': collapsed}">
		<div class="question-title" @click.prevent="collapsed = !collapsed">{{ question }}</div>
		<div :class="{'question-body': true, 'collapsed': collapsed}">
			<slot/>
		</div>
	</div>
</template>

<script>
export default {
	name: "QuestionBox",
	props: ['question', 'open'],
	data() {
		return {
			collapsed: this.open === null || this.open !== true,
		}
	},
}
</script>

<style lang="scss" scoped>
.question-wrapper {
    padding: 0.5rem 0;

	@media (max-width: 1199.99px) {
		max-width: 100%;
	}

	& + .question-wrapper {
		margin-top: 0.5rem;
	}

	.question-title {
		font-weight: bold;
		color: #16193A;
		font-size: 1rem;
		cursor: pointer;
		transition: 0.15s ease all;
		user-select: none;
		position: relative;

		&:after {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 1rem;
			width: 0.5rem;
			height: 0.5rem;
			border-right: 2px solid #16193A;
			border-bottom: 2px solid #16193A;
			content: ' ';
			transform-origin: center;
			transform: rotate(-135deg);
			margin: auto;
			opacity: 0.25;
			transition: 0.15s ease all;
		}

		&:hover {
			color: rgba(22, 25, 58, 0.7);
		}
	}

	.question-body {
		color: #16193A;
        padding-top: 0.5rem;

		*:last-child {
			margin-bottom: 0;
		}

		a {
			color: #16193A;
			font-weight: 600;
		}
	}

	&.collapsed {
		.question-title {
			&:after {
				transform: rotate(45deg);
			}
		}
		.question-body {
			display: none;
		}
	}
}

</style>