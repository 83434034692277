<template>
	<div class="screen">

		<!-- Mobile Header -->
		<MobileHeader :back-link="true" :title="name ? name : 'Loading'"/>

		<div class="container-fluid">
			<div :class="{'property-details': true, 'loading': !loaded}">
				<div class="row">
					<div class="col-xl-6 order-xl-2">
						<div class="property-images">
							<div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel" ref="carousel">
								<div class="carousel-inner">
									<div :class="{'carousel-item': true, 'active': key === 0}" v-for="(item, key) in images" :key="key">
										<img :src="item.image" class="d-block w-100" alt="name">
									</div>
								</div>
								<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
									<span class="carousel-control-prev-icon" aria-hidden="true"></span>
									<span class="visually-hidden">Previous</span>
								</button>
								<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
									<span class="carousel-control-next-icon" aria-hidden="true"></span>
									<span class="visually-hidden">Next</span>
								</button>
							</div>
						</div>
					</div>
					<div class="col-xl-6 order-xl-1 mt-4 mt-xl-0">
						<h1 class="property-name">{{ name ? name : 'Loading' }}</h1>
						<div class="property-address">{{ address ? address : 'Loading' }}</div>
						<div class="property-info-box">
							<h4>Property Overview</h4>
							<div class="info-box-item">
								<strong>Purchase Price</strong>
								<span>${{ purchasePrice ? purchasePrice.toLocaleString() : "Loading" }}</span>
							</div>
							<div class="info-box-item">
								<strong>Asset Profile</strong>
								<span>{{ assetProfile ? assetProfile : "Loading" }}</span>
							</div>
							<div class="info-box-item">
								<strong>Unit Count</strong>
								<span>{{ units ? units + " Units" : "Loading" }}</span>
							</div>
							<div class="info-box-item">
								<strong>Market</strong>
								<span>{{ market ? market : "Loading" }}</span>
							</div>
							<div class="info-box-item">
								<strong>Sub market</strong>
								<span>{{ subMarket ? subMarket : "Loading" }}</span>
							</div>
							<div class="info-box-item">
								<strong>Acquisition Date</strong>
								<span>{{ acquisition ? acquisition : "Loading" }}</span>
							</div>
						</div>
						<div class="text-center">
							<div class="property-confidential">Proprietary and Confidential</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import repository from "@/repository/repository";
import MobileHeader from "@/components/MobileHeader.vue";
import {Carousel} from "bootstrap";

export default {
	name: "PortfolioPropertyDetails",
	components: {MobileHeader},
	data() {
		return {
			images: [],
			name: null,
			address: null,
			purchasePrice: null,
			assetProfile: null,
			units: null,
			market: null,
			subMarket: null,
			acquisition: null,
			loaded: false,
			error: false,
		}
	},
	created() {
		if(this.propertyId) this.loadDetails(this.propertyId);
	},
	methods: {
		loadDetails(id) {
			this.loaded = false;
			this.error = false;
			this.images = [];
			this.name = null;
			this.address = null;
			this.purchasePrice = null;
			this.assetProfile = null;
			this.units = null;
			this.market = null;
			this.subMarket = null;
			this.acquisition = null;
			repository.get("/properties/" + id)
				.then(response => {
					let property = response.data.property;
					this.images = property.images;
					this.name = property.name;
					this.address = property.address;
					this.purchasePrice = property.purchase_price;
					this.assetProfile = property.asset_profile;
					this.units = property.units;
					this.market = property.market;
					this.subMarket = property.sub_market;
					this.acquisition = property.acquisition;
					this.loaded = true;
					this.initiateCarouselAutoplay();
				})
				.catch(() => {
					this.error = true;
					this.loaded = false
				})
		},
		initiateCarouselAutoplay() {
			new Carousel(this.$refs.carousel, {
				interval: 2000,
				touch: true,
				ride: 'carousel',
			});
		},
	},
	computed: {
		propertyId() {
			return this.$route.params.id;
		}
	},
	watch: {
		propertyId(newId) {
			if(newId) {
				this.loadDetails(newId);
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.property-details {
	padding-bottom: 1.5rem;

	.property-images {
		width: 100%;
		height: 25rem;
		overflow: hidden;
		border-radius: 0.5rem;
		background: #fafbfc;
		user-select: none;
		z-index: 0;
		position: relative;

		@media (max-width: 767.99px) {
			width: calc(100% + 3rem);
			margin: -2.5rem -1.5rem 0 -1.5rem;
			border-radius: 0;
			height: 20rem;
		}

		img {
			width: 100%;
			height: 25rem;
			object-fit: cover;

			@media (max-width: 767.99px) {
				height: 20rem;
			}
		}

		.carousel-control-prev {
			opacity: 1;
			width: 10%;
		}

		.carousel-control-next {
			opacity: 1;
			width: 10%;
		}
	}

	.property-name {
		font-weight: bold;
		color: #16193A;
		font-size: 1.5rem;
	}

	.property-address {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: rgba(#16193A, 0.5);
		font-size: 0.9rem;
		padding-left: 1.25rem;
		position: relative;

		@media (max-width: 767.99px) {
			white-space: normal;
		}

		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: -0.25rem;
			opacity: 0.25;
			width: 1.25rem;
			height: 1.25rem;
			background: url('../../assets/icons/map-marker.svg') center no-repeat;
			background-size: contain;
			content: ' ';
			margin: auto;
		}
	}

	.property-info-box {
		padding: 1rem;
		background: #fafbfc;
		border-radius: 0.5rem;
		margin: 1rem 0 0 0;

		h4 {
			font-size: 1rem;
			font-weight: bold;
			color: #16193A;
			margin: 0;
		}

		.info-box-item {
			display: flex;
			justify-content: space-between;
			font-size: 0.9rem;
			color: #16193A;
			margin-top: 0.25rem;

			strong {
				font-weight: 600;
			}
		}
	}

	.property-confidential {
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: rgba(#16193A, 0.5);
		font-size: 0.9rem;
		padding-left: 1.25rem;
		position: relative;
		margin-top: 1.5rem;

		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: -0.25rem;
			opacity: 0.25;
			width: 1.25rem;
			height: 1.25rem;
			background: url('../../assets/icons/lock.svg') center no-repeat;
			background-size: contain;
			content: ' ';
			margin: auto;
		}
	}

	&.loading {
		.property-images {
			background: rgba(#16193A, 0.025);

			img {
				opacity: 0;
				transition: 0.15s ease all;
			}
		}

		.property-name {
			background: rgba(#16193A, 0.025);
			color: transparent;
			user-select: none;
			pointer-events: none;
			border-radius: 0.5rem;
			animation: pulse-light 1s infinite;
		}

		.property-address {
			background: rgba(#16193A, 0.025);
			color: transparent;
			user-select: none;
			pointer-events: none;
			border-radius: 0.5rem;
			transform: translateY(0.25rem);
			animation: pulse-light 1s infinite;

			&:before {
				opacity: 0;
			}
		}

		.property-info-box {
			background: rgba(#16193A, 0.025);
			color: transparent;
			user-select: none;
			pointer-events: none;
			border-radius: 0.5rem;

			* {
				color: transparent;
			}
		}

		.property-confidential {
			background: rgba(#16193A, 0.025);
			color: transparent;
			user-select: none;
			pointer-events: none;
			border-radius: 0.5rem;

			&:before {
				opacity: 0;
			}
		}
	}
}
</style>