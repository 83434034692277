import { render, staticRenderFns } from "./AccountVerificationStatus.vue?vue&type=template&id=38bb519e&scoped=true&"
import script from "./AccountVerificationStatus.vue?vue&type=script&lang=js&"
export * from "./AccountVerificationStatus.vue?vue&type=script&lang=js&"
import style0 from "./AccountVerificationStatus.vue?vue&type=style&index=0&id=38bb519e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38bb519e",
  null
  
)

export default component.exports