<template>
	<div class="screen">

		<!-- Mobile Header -->
		<MobileHeader :dark="false" :menu-toggle="true" title="FAQ"/>

		<div class="container-fluid">

			<!-- Fund Overview -->
			<h3 class="screen-title mb-3">Fund Overview</h3>
			<div class="row">
				<div class="col-xxl-12 mb-4">
					<div class="faq-box">
						<div class="d-flex mb-3">
							<img src="../../assets/logo.svg" alt="Reiturn" class="logo">
						</div>
						<p>Birgo Reiturn Fund LLC (“Reiturn” or “Fund”) is a public, non-traded real estate investment trust
							(REIT) that owns and operates multifamily properties in the heartland of America.</p>
						<p>The Fund’s objective is to achieve an annualized net return of 9-11% to investors, comprising a
							mix of income (5%) and capital appreciation (4-6%). Reiturn seeks to achieve this objective by
							purchasing underutilized properties, investing capital to upgrade the real estate, and improving
							operations to maximize profitability. Reiturn is an impact-oriented venture that seeks to provide
							superior service to an underserved tenant population while maintaining affordability. The Fund
							is managed by Birgo, a vertically-integrated real estate investment and property management
							firm headquartered in Pittsburgh, PA.</p>

						<div class="faq-investment-characteristic">
							<img src="../../assets/icons/income.svg" alt="Income" class="icon">
							<div class="info">
								<h5>Income</h5>
								<p>Consistent cash flow is produced through collecting monthly rental income and managing expenses to generate positive yield. Birgo’s existing funds have consistently produced a <strong>cash yield of between 6-8% since the firm’s inception</strong>.</p>
							</div>
						</div>
						<div class="faq-investment-characteristic">
							<img src="../../assets/icons/growth.svg" alt="Growth" class="icon">
							<div class="info">
								<h5>Capital Account Growth</h5>
								<p>Investments grow in value over time as debt is paid down, rents are increased, and properties appreciate. Birgo has a <strong>track record of achieving “forced appreciation”</strong> at its properties through upgrades, repositioning, and rent growth.</p>
							</div>
						</div>
						<div class="faq-investment-characteristic">
							<img src="../../assets/icons/impact.svg" alt="Impact" class="icon">
							<div class="info">
								<h5>Impact Oriented</h5>
								<p>Focus on the undervalued “Heartland” regions where properties are purchased below replacement cost in economically resilient markets, and managed with dignity by our team members.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Popups -->
			<div class="row mb-4">
				<div class="col-md-4">
					<button class="btn btn-light popup-button" data-bs-toggle="modal" data-bs-target="#fundDetails">
						<img src="../../assets/icons/dollar.svg" alt="Dollar">
						<span>About the Fund</span>
					</button>
				</div>
				<div class="col-md-4 mt-2 mt-md-0">
					<button class="btn btn-light popup-button" data-bs-toggle="modal" data-bs-target="#keyTerms">
						<img src="../../assets/icons/document-alt.svg" alt="Document">
						<span>Key Terms</span>
					</button>
				</div>
				<div class="col-md-4 mt-2 mt-md-0">
					<button class="btn btn-light popup-button" data-bs-toggle="modal" data-bs-target="#shareRepurchaseProgram">
						<img src="../../assets/icons/wallet.svg" alt="Wallet">
						<span>Share Repurchase Program</span>
					</button>
				</div>
			</div>

			<!-- About & Partners -->
			<h3 class="screen-title mb-3">About Birgo</h3>
			<div class="faq-box mb-4">
				<div class="row">
					<div class="col-xl-12 me-auto mb-4 mb-xl-0">
						<p>Reiturn is managed by Birgo Realty LLC (“Birgo”), a real estate investment and property management company headquartered in Pittsburgh, PA.</p>
						<p>Birgo is a vertically-integrated real estate investment and property management company headquartered in Pittsburgh, PA. Founded in 2012, the firm has grown rapidly since its inception. The firm has launched three investment vehicles and owns and operates over <strong>2,700 apartment units</strong> in Pennsylvania, West Virginia, Western New York, and Ohio.</p>
						<p class="mb-0">Birgo’s team of real estate and finance professionals has underwritten, financed, and operated over $270 million in aggregate cost basis. It has raised more than <strong>$75 million in investor capital</strong> and returned over <strong>$25 million in distributions</strong>. As an impact-oriented firm, Birgo aims to honor all participants in its value chain - investors, tenants, employees, and the communities in which it invests - as it seeks to achieve its core purpose of “improving lives through real estate.”</p>
					</div>
				</div>
			</div>

			<!-- General Questions -->
            <h3 class="screen-title mb-3">Frequently Asked Questions</h3>
            <div class="faq-box mb-4">
                <h4>General Questions</h4>
                <QuestionBox question="What is Reiturn?" :open="true">
                    <p>Reiturn is a real estate investment trust (REIT) that owns and operates workforce multifamily housing in the Heartland of America. The fund is managed by Birgo Reiturn Fund Manager LLC.</p>
                </QuestionBox>
                <QuestionBox question="Who is Birgo Reiturn Fund Manager?">
                    <p>Birgo Reiturn Fund Manager is a real estate investment firm that acquires multifamily, office, and retail properties in western New York, Ohio, Pennsylvania, and West Virginia. Founded in 2012, the firm has over $270 million in assets and approximately 2,700 units under management.</p>
                </QuestionBox>
                <QuestionBox question="What is this fund’s strategy?">
                    <p>The fund acquires and operates stabilized but underutilized real estate in the Heartland of America. Birgo defines the Heartland as states in the Rust Belt/Midwest including but not limited to Pennsylvania, Ohio, New York, West Virginia, and Kentucky. Most assets acquired by the fund are Class B multifamily properties.</p>
                </QuestionBox>
                <QuestionBox question="When did the fund start, and when will it end?">
                    <p>The fund was formed in Q4 of 2022 as an evergreen fund with no termination date.</p>
                </QuestionBox>
                <QuestionBox question="How much capital is Birgo raising for this fund?">
                    <p>There is no specific target raise amount for the fund. However, per SEC regulations, the maximum amount that can be raised in this offering in one year is $75 million.</p>
                </QuestionBox>
                <QuestionBox question="Does the fund borrow money to buy properties? How much of the purchase price does it generally borrow?">
                    <p>Yes, the Fund uses loans from banks and agency lenders to fund its purchases. Typically 70%+ of the initial purchase price is funded by a lender, and we may also borrow a corresponding 70%+of the expected cost of initial capital improvements.</p>
                </QuestionBox>
                <QuestionBox question="Who is the Birgo team that oversees and manages the Fund’s portfolio?">
                    <p>Birgo has a team of 80 employees that oversee acquisitions, property management, maintenance, leasing, asset management, and finance on behalf of the fund.</p>
                </QuestionBox>

                <h4 class="mt-4">Economics</h4>
                <QuestionBox question="What is the total target return for the fund?">
                    <p>The fund’s target annualized net rate of return is 9% to 11%. This return is achieved through a combination of cash flow, debt retirement, and appreciation.</p>
                </QuestionBox>
                <QuestionBox question="How does the fund manager make money?">
                    <p>The fund manager is primarily compensated through an incentive allocation that is tied to the performance of the fund. It also receives property management fees, brokerage fees, and other transactional / operational fees for the ongoing servicing and management of the portfolio.</p>
                </QuestionBox>
                <QuestionBox question="How is the incentive allocation calculated?">
                    <p>The first 5% of annualized return goes to the investors. For everything above 5%, the returns are allocated 70% to the investors and 30% to the fund manager. Investors are entitled to a 5% annualized return before the fund manager is compensated for its efforts.</p>
                </QuestionBox>
                <QuestionBox question="Will I pay a premium or penalty for coming into the Fund later than other investors?">
                    <p>The Fund is revalued every quarter based on fluctuations in market value of its underlying assets. As such, the price of buying into the fund will move on a quarterly basis as the underlying value of the Fund’s assets changes. We anticipate that the Fund’s first revaluation will take place as of December 31, 2022.</p>
                </QuestionBox>
                <QuestionBox question="Other than the incentive allocation of 30% of all returns over a 5% annualized return, what fees is the fund manager (or its affiliates) entitled to receive?">
                    <p>Through its affiliates, the fund manager charges fees for property management, brokerage, leasing, financing, eviction facilitation, accounting, and construction management. For a full list of the fees that are charged and the corresponding rates, please refer to the fund’s fee disclosure document</p>
                </QuestionBox>
                <QuestionBox question="How much capital are the fund managers investing in the Fund?">
                    <p>Birgo’s team members have collectively invested $2 million into the Fund in 2022. This is the most our team has ever committed to invest in a single Birgo offering, and demonstrates both strong alignment of incentives as well as our confidence in this new fund’s investment strategy.</p>
                </QuestionBox>
                <QuestionBox question="Do the fund managers personally guarantee the fund’s mortgages?">
                    <p>Yes, the fund managers do regularly have personal guarantees on the fund’s mortgages, which allows the fund to obtain the most competitive lending terms available in the marketplace.</p>
                </QuestionBox>

                <h4 class="mt-4">Investor Experience</h4>
                <QuestionBox question="Can I invest through an LLC, LP, or trust?">
                    <p>Yes. Investors can invest through an LLC, trust, partnership, or whichever vehicle they prefer.</p>
                </QuestionBox>
                <QuestionBox question="How will I be kept up-to-date on my investment?">
                    <p>Updates on fund activity are distributed via email at least quarterly, and all investors are encouraged to follow us on social media for regular updates. Additionally, our investor relations team is happy to discuss the status of the fund with you at any time. They can be reached at <a href="mailto:InvestorRelations@Reiturn.com">InvestorRelations@Reiturn.com</a>.</p>
                </QuestionBox>
                <QuestionBox question="Is there a place that I can log-in to see information on the fund and my investment?">
                    <p>You can log into your account at <a href="https://www.reiturn.com">www.reiturn.com</a>. This contains all historical legal documents, quarterly reports, and contribution/distribution history, as well as details on the properties within the portfolio.</p>
                </QuestionBox>
                <QuestionBox question="How often will I receive distributions?">
                    <p>Distributions are paid quarterly, typically one month following the quarter-end. So, distributions are paid at the end of January, April, July, and October.</p>
                </QuestionBox>
                <QuestionBox question="When will my first distribution be?">
                    <p>The fund plans to begin issuing distributions in the first half of 2023.</p>
                </QuestionBox>
                <QuestionBox question="When should I expect to receive my principal back?">
                    <p>Distributions of principal may come when the Fund either sells or refinances a property, but the fund manager may elect to reinvest that capital into additional acquisitions or property improvements, thereby increasing the Fund’s net asset value. Liquidity in this fund will primarily be at the discretion of the investor via redemption requests.</p>
                </QuestionBox>
                <QuestionBox question="Does the fund have a third-party CPA firm review its financial statements?">
                    <p>Yes, the fund’s financial statements are reviewed annually by a nationally recognized CPA firm.</p>
                </QuestionBox>
                <QuestionBox question="Will I receive a 1099-DIV? If so, when can I expect to receive the form each year?">
                    <p>Yes, all investors will receive a 1099-DIV. We will issue them by January 31st of each year.</p>
                </QuestionBox>
            </div>

			<!--
			=================
			Modals
			=================
			-->

			<!-- About the fund -->
			<div class="modal fade faq-modal" id="fundDetails" tabindex="-1" aria-labelledby="fundDetailsLabel" aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="fundDetailsLabel">About the Fund</h1>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="faq-table">
								<div class="faq-table-item">
									<strong>Target Annualized Return</strong>
									<p>9-11%</p>
								</div>
								<div class="faq-table-item">
									<strong>Distribution Frequency</strong>
									<p>Quarterly (subject to available cash flow)</p>
								</div>
								<div class="faq-table-item">
									<strong>Location</strong>
									<p>The Heartland: Pennsylvania, Ohio, West Virginia, Western New York, Kentucky, and other similar markets as deemed appropriate by the fund manager</p>
								</div>
								<div class="faq-table-item">
									<strong>Property Type</strong>
									<p>Workforce multifamily housing</p>
								</div>
								<div class="faq-table-item">
									<strong>Fund Life</strong>
									<p>Evergreen; no termination date</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Key Terms -->
			<div class="modal fade faq-modal" id="keyTerms" tabindex="-1" aria-labelledby="keyTermsLabel" aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="keyTermsLabel">Key Terms</h1>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="faq-table">
								<div class="faq-table-item">
									<strong>Investment Legal Structure</strong>
									<p>Limited Liability Company taxed as a Real Estate Investment Trust</p>
								</div>
								<div class="faq-table-item">
									<strong>Sponsor Co-Investment</strong>
									<p>$2 million</p>
								</div>
								<div class="faq-table-item">
									<strong>Method of Determining Net Asset Value</strong>
									<p>NAV is calculated by taking the total value of the assets less the total value of the liabilities, divided by the number of units outstanding. NAV will be adjusted at the beginning of each fiscal quarter, or as soon as commercially reasonable thereafter.</p>
								</div>
								<div class="faq-table-item">
									<strong>Asset Management Fee</strong>
									<p>1.25% asset management fee annualized, payable monthly in arrears</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Share Repurchase Program -->
			<div class="modal fade faq-modal" id="shareRepurchaseProgram" tabindex="-1" aria-labelledby="shareRepurchaseProgramLabel" aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="shareRepurchaseProgramLabel">Share Repurchase Program</h1>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="faq-table">
								<div class="faq-table-item">
									<strong>Less than 1 year from investment date</strong>
									<p>No repurchase allowed</p>
								</div>
								<div class="faq-table-item">
									<strong>1-2 years from investment date</strong>
									<p>Redemptions at 95% of NAV</p>
								</div>
								<div class="faq-table-item">
									<strong>2-3 years from investment date</strong>
									<p>Redemptions at 98% of NAV</p>
								</div>
								<div class="faq-table-item">
									<strong>3+ years from investment date</strong>
									<p>Redemptions at 100% of NAV</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import 'bootstrap/js/dist/collapse';
import MobileHeader from "@/components/MobileHeader.vue";
import QuestionBox from "@/components/widgets/QuestionBox.vue";

export default {
    name: "FAQ",
	components: {QuestionBox, MobileHeader},
}
</script>

<style lang="scss" scoped>
.faq-box {
	display: block;
	position: relative;
	padding: 2.5rem;
	background: #fff;
	border-radius: 0.5rem;

	@media (max-width: 767.99px) {
		padding: 0;
		background: none;
		border-radius: 0;
	}

	.logo {
		display: block;
		max-height: 3rem;
		margin-bottom: 1.5rem;
	}

    h4 {
        font-weight: bold;
        color: #F37021;
        font-size: 1.25rem;
    }
}

.faq-investment-characteristic {
	display: flex;
	align-items: center;

	& + .faq-investment-characteristic {
		margin-top: 1rem;
		padding-top: 1rem;
		border-top: 1px solid #eee;
	}

	img.icon {
		width: 8rem;
		height: 8rem;
		object-fit: contain;
	}

	.info {
		padding-left: 1rem;

		h5 {
			font-weight: bold;
			color: #F37021;
			font-size: 1.25rem;
		}

		p {
			margin-bottom: 0;
		}
	}
}

.btn.btn-light.popup-button {
	display: flex;
	align-items: center;
	width: 100%;
	background: #fff;
	padding: 2rem 2.5rem;
	font-weight: bold;
	color: #16193A;
	border-radius: 0.5rem;
	border: none;
	transition: 0.15s ease all;
	position: relative;
	height: 100%;
	text-align: left;

	&:hover {
		box-shadow: rgba(#161931, 0.1) 0 0.1rem 0.25rem;
	}

	&:active {
		box-shadow: rgba(#161931, 0.25) 0 0.2rem 0.5rem;
	}

	img {
		width: 3rem;
		height: 3rem;
		margin: -1rem 0.5rem -1rem -1rem;
	}

	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 1.5rem;
		width: 0.75rem;
		height: 0.75rem;
		content: ' ';
		border-right: 2px solid #16193A;
		border-top: 2px solid #16193A;
		transform-origin: center;
		transform: rotate(45deg);
		margin: auto;
	}
}

.modal.faq-modal {
	.modal-content {
		padding: 2rem;

		@media (max-width: 767.99px) {
			padding: 0.5rem;
		}
	}

	.modal-header {
		border-bottom: none;

		.modal-title {
			font-weight: bold;
			color: #16193A;
		}

		.btn-close {
			outline: none;
			box-shadow: none;
		}
	}

	.faq-table {

		.faq-table-item {
			display: flex;
			justify-content: space-between;
			color: #16193A;

			& + .faq-table-item {
				margin-top: 1rem;
				padding-top: 1rem;
				border-top: 1px solid rgba(#16193A, 0.1);
			}

			strong {
				width: 40%;
				flex-shrink: 0;
				padding-right: 1rem;
				display: block;
			}

			p {
				width: 60%;
				flex-shrink: 0;
				text-align: left;
				margin: 0;
			}
		}
	}
}

.faq-partners {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	img {
		max-height: 2.5rem;
		max-width: 15rem;
		object-fit: contain;
		margin-bottom: 1.5rem;
		object-position: left center;
	}
}
</style>