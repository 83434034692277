<template>
    <div class="modal-wrapper" @click.prevent="back">
        <div @click.stop :class="{'modal-box': true, 'modal-large': size === 'large'}">
			<button class="btn btn-close" @click.prevent="back"></button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Popup",
    props: ['size'],
	created() {
	},
	methods: {
        back() {
            history.back();
        }
    },
}
</script>

<style lang="scss">
.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    padding: 5rem 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.15s ease all;
	overflow-x: hidden;
	display: block;
	background: rgba(#000, 0.35);
	backdrop-filter: blur(2px);

    &.active {
        opacity: 1;
        visibility: visible;

        .modal-overlay {
            opacity: 0.25;
            visibility: visible;
        }

        .modal-box {
            transform: translateY(0);
        }
    }

    .modal-box {
        border-radius: 0.5rem;
        border: none;
        box-shadow: rgba(#16193A, 0.1) 0 0.15rem 0.5rem;
        width: 460px;
        background: #fff;
        padding: 1.5rem;
        z-index: 100;
        transform: translateY(-50px);
		transition: 0.15s ease all;
		position: relative;
		margin: auto;

		@media (max-width: 767.99px) {
			max-width: 320px;
			padding: 20px 20px;
		}

        &.modal-large {
            width: 720px;
        }

		.btn.btn-close {
			position: absolute;
			top: 1rem;
			right: 1rem;
			box-shadow: none;
			border: none;
			background-color: #fff;
			width: 2rem;
			height: 2rem;
			opacity: 1;
			z-index: 9;
		}
    }
}
</style>