<template>
	<div class="legal-wrapper">

		<!-- Mobile Header -->
		<MobileHeader title="Privacy Policy" :back-link="true"/>

		<div class="container">
			<div class="legal-screen">
				<div class="legal-content">

					<!-- Desktop Screen Title -->
					<h1 class="screen-title mb-3 d-none d-md-block">Privacy Policy</h1>

					<p>Last Modified: July 12, 2022</p>
					<p>Go Realty, LLC and its affiliates (collectively, &ldquo;BIRGO,&rdquo; &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;) value their customers, their information, their privacy, and their trust. This Privacy Policy (&ldquo;Policy&rdquo;) applies to information collected online from users of the BIRGO websites, <a href="http://www.birgo.com">www.birgo.com</a> and <a href="http://www.reiturn.com">www.reiturn.com</a> (the &ldquo;Sites&rdquo;), and explains how we collect, use, and protect that information. This Policy applies to BIRGO&rsquo;s collection, use, processing, storage, and transfer of information associated with BIRGO&rsquo;s Sites.</p>

					<p>Throughout this Policy, we refer to the term &ldquo;Personal Information.&rdquo; &ldquo;Personal Information&rdquo;&nbsp;means data which identifies, describes, is associated with, or could be linked to an individual, such as a name, birthdate, postal address, email address, telephone number, driver&rsquo;s license number, Social Security Number or other government-issued identification number, credit card number, or other unique identifiers.</p>

					<p>Personal Information does not include anonymous or aggregated data that can no longer be used to identify a specific person, even if combined with other data. Also, we may collect information related to businesses, companies, institutions, or other entities rather than individuals (&ldquo;Business Information&rdquo;). We do not consider Business Information, such as title, employer&rsquo;s name, work email, work phone number, work address, and other similar business information, to be Personal Information.</p>

					<h2>Information we Collect&nbsp;</h2>

					<p>We&nbsp;collect information about you in two ways: 1) directly from you; and 2) indirectly from you via your use of the Sites.</p>

					<h3>Information Collected Directly From You</h3>

					<p>When you contact us using our Sites or use the Sites&rsquo; forms to subscribe to our newsletter, fill out a form, use Live Chat, or otherwise enter information on our Site we will collect certain Personal Information about you so that we may adequately respond to your inquiry or offer services to you such as your first and last name, country, phone number, email address, date of birth, address, information about your investment interests, and other information shared through our forms. If you are making an investment additional identifying info may be requested through our forms including investment amount, payment method, citizenship, social security number, and photo ID. Further, BIRGO may collect geolocation information about you.</p>

					<h3>Information Collected Indirectly from You</h3>

					<p>When you visit our Sites, we may collect information about your visit and maintain that information in our web server logs, which are records of the activities on our Sites. The servers automatically capture and store the information electronically. Examples of the information we may collect include: Internet Protocol (IP) address; browser type and language; Internet Service Provider (ISP), referring and exit websites and applications; operating system; date/time stamp of activity; clickstream data; whether you are a first-time or a returning user; links you clicked on while in the Sites; and your flow and navigation path through the Sites.&nbsp;We use this information in a deidentified and aggregated fashion to help us administer the Sites, analyze its usage, protect the websites and its content from inappropriate use, and improve your Sites experience.</p>

					<p>In some of our email messages, you will find a &ldquo;click-through URL&rdquo; linked to content on our Sites. We track this click-through data to help us determine interest in particular topics and measure the effectiveness of our customer communications. If you prefer not to be tracked in this way, you should not click such links in our email messages.</p>

					<h4>Cookies</h4>

					<p>We use technologies, such as &ldquo;cookies,&rdquo; so we can remember certain information about you, remember your preferences, or recognize you when you return to the Sites.&nbsp;Cookies also help us compile aggregate data about Sites traffic and Sites interaction so that we can improve our Sites and protect our Sites from fraud.</p>

					<p>A cookie is a text file that is stored on your computer. As text files, cookies cannot read information on your hard drive, run programs, or deliver viruses to your computer. &nbsp;</p>

					<p>Most browsers are set to accept cookies automatically. You can set your browser to decline all cookies automatically or to prompt you for a response each time a cookie is offered. If you decline cookies, you may find that you cannot access all our Sites&rsquo; features.</p>

					<p>During your browsing session, we use &ldquo;session&rdquo; cookies to store information and monitor Sites function. These session cookies are installed during your browsing session and are deleted when the browsing session has ended.</p>

					<p>Please refer to this link [<a href="www.birgo.com/cookies">www.birgo.com/cookies</a> or <a href="www.reiturn.com/cookies">www.reiturn.com/cookies</a>] for more information on the cookies used on the site.</p>

					<p>We also use log files when you visit our Sites. Log files track IP addresses, browser type, Internet Service Provider (ISP) identity, referring/exit pages, platform type, date/time stamp, and number of clicks. We utilize this information in our legitimate interest in administering the Sites, preventing fraud, tracking websites navigation in the aggregate, and gathering broad demographic information for aggregate use.</p>

					<p>Web beacons, also known as clear GIFs, are often used in combination with cookies. Often transparent, these images are part of web pages, and allow us to count users who have visited certain pages and to generate statistics about how our Sites are used. They are not used to access Personal Information</p>

					<p>Pixel tags enable us to send email messages in a format that customers can read, and they tell us whether mail has been opened. We may use this information to reduce or eliminate messages sent to you.</p>

					<p>We do not and will not use cookies to collect Personal Information from you that you did not intentionally provide to us. We do not collect Personal Information automatically, but we may tie this information to Personal Information about you that we collect from other sources or you provide to us.</p>

					<h4>Website Analytics</h4>

					<p>We use third-party websites analytics software from third-party providers (Google Analytics) in connection with our Sites to gather data such as age, gender, and interests to provide advertising targeted to suit your interests and preferences.</p>

					<p>This analytics software helps us target our online ads based on information collected directly from you or by automated means, like cookies. These companies also use automated technologies to collect information when you click on our ads, which helps track and manage the effectiveness of our marketing efforts. &nbsp;</p>

					<p>You may opt out of the automated collection of information by third-party ad networks for the purpose of delivering advertisements tailored to your interests by visiting the consumer opt-out page for the Self-Regulatory Principles for Online Behavior Advertising at <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">https://support.google.com/adwordspolicy/answer/1316548?hl=en</a> and edit or opt-out your Google Display Network ads&rsquo; preferences at <a href="http://www.google.com/ads/preferences/">http://www.google.com/ads/preferences/</a>.</p>

					<p>BIRGO utilizes analytics software provided by Google Analytics, Inc. Google collects anonymous information that is not used to identify a user. This information helps us better understand how visitors use our Sites, detect and defend against fraud and other security risks, and present advertisements, products, and/or services of interest to you.</p>

					<p>To learn more about how Google Analytics collects and processes data, please visit &ldquo;How Google users data when you use our partners&rsquo; Site or apps&rdquo;, located at <a href="http://www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a>. To learn about how to opt-out of Google Analytics, please visit <a href="http://tools.Google.com/dlpage/gaoptout/">http://tools.Google.com/dlpage/gaoptout/</a>.</p>

					<h2>Children&rsquo;s Information</h2>

					<p>Our Sites are not directed at children. We do not knowingly collect Personal Information from children under the age of 16. If you are a parent or guardian and believe your child has provided us with Personal Information without your consent, please contact us by using the information in the &ldquo;Questions and Contact Information&rdquo; section, below, and we will take steps to remove such Personal Information from our systems.</p>

					<h2>Use of Personal Information</h2>

					<p>We only use your Personal Information for the original purposes it was given and to further our legitimate business interests, such as to transact business, to provide information about our company and products, and to communicate with visitors of our Sites. Personal Information is used also to provide you with a more enjoyable, convenient online experience. We may use your Personal Information to send you requested information, to personalize content for you, to compile, analyze, and better understand user data, demographics and behavior, and to detect, investigate, and prevent activities that may violate our policies or be illegal. We may also use your Personal Information to communicate with you about products and services we believe you might find of interest and to administer marketing materials and surveys in which you might participate. &nbsp;</p>

					<p>We also use the information automatically collected about you during your use of the Sites, as described above, to improve the effectiveness of our websites, operations, mobile experience, or marketing efforts, to prevent against fraud and theft, and otherwise to protect our Sites&rsquo; visitors and our business.</p>

					<p>Our employees and agents may view your Personal Information to assess and evaluate your investment application or to otherwise perform their jobs and address your needs. We authorize their access to your Personal Information on a need-to-know basis to evaluate your qualifications as an investor and/or to provide you with our products or services, or for one of the other reasons listed in this section, and always in connection with a contractual obligation to protect the privacy of that information.</p>

					<p>We may also use information you provide to us to communicate with you in the future. If you do not wish to receive such communications, you may opt out (unsubscribe) as described below in the &ldquo;Access and Consent to Use of Your Personal Information&rdquo; and &ldquo;Questions and Contact Information&rdquo; section.</p>

					<h2>Disclosure of Your Information</h2>

					<p>We will not sell, rent, or lease any information we collect from you to others. We will not make your Personal Information available to any unaffiliated parties, except as follows:</p>

					<ul>
						<li>To employees, contractors, agents, websites vendors, and/or contractors who may use it on our behalf or in connection with their relationship with us;</li>
						<li>To our vendors, contractors, and third-parties who assist us in providing you with the services requested (like the Dalmore Group and North Capital Investment Technology, Inc. and those otherwise listed herein)<a href="#_ftn1" name="_ftnref1" title="">[1]</a></li>
						<li>To our affiliates, service providers, and third-parties (e.g., Google Analytics) for marketing purposes, building targeted ad campaigns, and completing your order of products or services.</li>
						<li>To others, including law enforcement, if we believe such release is reasonably necessary to comply with law or legal process; enforce or apply the applicable BIRGO Terms of Use per BIRGO application, if reasonably necessary to protect our operations, clients, or users; detect, prevent or otherwise address fraud, security, or technical issues; protect the rights, property, or safety of others; in a matter of public safety or Policy; or as needed in connection with the transfer of our business assets (for example, if we are acquired by another firm or if we are liquidated during bankruptcy proceedings). In the event of an insolvency, bankruptcy, or receivership, your Personal Information may also be transferred as a business asset.</li>
					</ul>

					<p>In addition, we may share aggregate, non-individual information, incapable of identifying a person, with third parties for lawful purposes. We will not disclose any of your Personal Information except when we have your permission or under special circumstances, such as when we believe in good faith that the law requires it or under circumstances described in this Policy.</p>

					<h2>Access and Consent to Use of Your Personal Information</h2>

					<p>If you believe that the Personal Information that we have collected about you is incomplete, inaccurate, or not up to date, or if you would like to review and/or request changes or deletion of that information, please see the contact information in the &ldquo;Questions and Contact Information&rdquo; section.</p>

					<p>If you do not consent or want to withdraw your consent for us to collect, use, or disclose your Personal Information as described in this Policy, please use the contact information provided in the &ldquo;Questions and Contact Information&rdquo; section. In your request, please identify the Personal Information at issue and the collection, use, or disclosure you wish to stop.</p>

					<p>Denying or withdrawing consent may not allow us to provide you with some or all the products, services, or information you request.</p>

					<p>You may opt-out of receiving marketing-related emails, targeted advertising messages, or promotional offers from BIRGO by clicking the &quot;unsubscribe&quot; link at the bottom of any email you receive from us or emailing us at <a href="mailto:legal_info-request@birgo.com" target="_blank">legal_info-request@birgo.com</a>.</p>

					<p>If you opt-out from receiving marketing emails or text messages, we may still need to send you communications about your account, orders, customer service inquiries, and other matters. If you agreed to receive future marketing communications directly from a third party through our Sites, you will need to contact that party to opt-out of such communications. This process may be outlined on that party&#39;s privacy policy. We do not control third parties&rsquo; collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&ldquo;NAI&ldquo;) on the <a href="https://optout.networkadvertising.org/?c=1">NAI&rsquo;s websites</a>.</p>

					<h2>Your California Privacy Rights</h2>

					<p>If you are a California resident, you may have certain rights with respect to your Personal Information including the following rights:</p>

					<ol>
						<li>The right to know, by way of our Policy and any specific inquiries you direct to us, where Personal Information we have about you comes from, what we use that information for, whether it is being disclosed or sold, and to whom it is being disclosed or sold;</li>
						<li>The right to &ldquo;opt-out&rdquo; of BIRGO&rsquo;s sale of your Personal Information to any third parties, in the event BIRGO sells data;</li>
						<li>The right, with some exceptions, to have your Personal Information deleted from BIRGO&rsquo;s possession or control; and</li>
						<li>The right to receive equal service and pricing from BIRGO even if you exercise any of your privacy rights.</li>
					</ol>

					<p>California Civil Code Section 1798.83 permits users of the Sites who are California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. A California resident may also use an authorized agent to submit a right to access request or a request to delete. To make such a request, to learn more, or if you have any questions or concerns about your California privacy rights, please visit the Privacy Notice for California Residents.</p>

					<h2>Virginia Residents</h2>

					<p>If you are a Virginia resident, you may, upon request, have certain rights with respect to your Personal Information, including the following rights:</p>

					<ol>
						<li>To confirm whether or not BIRGO is processing your Personal Information and to access such Personal Information;</li>
						<li>To correct inaccuracies in your Personal Information, taking into account the nature of the Personal Information and the purposes of the processing of the Personal Information;</li>
						<li>To delete your Personal Information;</li>
						<li>To obtain a copy of your Personal Information that you previously provided to BIRGO in a portable and, to the extent technically feasible, readily usable format that allows you to transmit the Information to another controller without hindrance, where the processing is carried out by automated means; and</li>
						<li>To opt-out of the Personal Information processing for purposes of targeted advertising, the sale of personal Information, or profiling in furtherance of decisions that produce legal or similarly significant effects concerning you.</li>
					</ol>

					<p>BIRGO will respond to any of the above requests within forty-five days. BIRGO may extend this deadline once by an additional forty-five days when reasonably necessary, taking into account the complexity and number of your requests, so long as we inform you of the extension within the initial forty-five-day response period along with the reasons for the extension. All requests pursuant to the above rights should be submitted to:</p>

					<ul>
						<li><a href="mailto:Virginia_Info-Request@birgo.com" target="_blank">Virginia_Info-Request@birgo.com</a> and Legal Dept, 848 W North Ave, Pittsburgh, PA 15233.</li>
					</ul>

					<p>If BIRGO declines to take action regarding your request, BIRGO shall inform you within the deadlines discussed above, along with the justification for that decision. Should you choose to, you may appeal the decision not to take action (or to take limited action) by submitting an appeal within fourteen days of receipt of BIRGO&#39;s initial response to the following:</p>

					<ul>
						<li><a href="mailto:Virginia_Info-Request-Appeal@birgo.com" target="_blank">Virginia_Info-Request-Appeal@birgo.com</a> and Legal Dept, 848 W North Ave, Pittsburgh, PA 15233</li>
					</ul>

					<p>Within sixty days of receipt of your appeal, BIRGO shall inform you in writing of any action taken or not taken, in response to the appeal, including a written explanation of the reasons for the decisions. If unsatisfied, you may file a complaint with the Virginia Attorney General at <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint</a>.</p>

					<h2>Colorado Residents</h2>

					<p>If you are a Colorado resident, you may, upon request, have certain rights with respect to your Personal Information, including the following rights:</p>

					<ol>
						<li>To opt-out of the processing of your Personal Information for purposes of targeted advertising, the sale of Personal Information; or profiling in furtherance of decisions that produce legal or similarly significant effects concerning you;</li>
						<li>To confirm whether BIRGO is processing your Personal Information and to receive access to that Personal Information;</li>
						<li>To correct inaccuracies in your Personal Information, taking into account the nature of the Personal Information and the purposes of the processing of your Information;</li>
						<li>To delete your Personal Information; and</li>
						<li>When exercising your right to access your Personal Information, to obtain the Personal Information in a portable and, to the extent technically feasible, readily usable format that allows you to transmit the data to another entity without hindrance.</li>
					</ol>

					<p>BIRGO will respond to any of the above requests within forty-five days. BIRGO may extend this deadline once by an additional forty-five days when reasonably necessary, taking into account the complexity and number of your requests, so long as we inform you of the extension within the initial forty-five-day response period along with the reasons for the extension. All requests pursuant to the above rights should be submitted to:</p>

					<ul>
						<li><a href="mailto:Colorado_Info-Request@birgo.com" target="_blank">Colorado_Info-Request@birgo.com</a> and Legal Dept, 848 W North Ave, Pittsburgh, PA 15233</li>
					</ul>

					<p>If BIRGO declines to take action regarding your request, BIRGO shall inform you within the deadlines discussed above, along with the justification for that decision. Should you choose to, you may appeal the decision not to take action (or to take limited action) by submitting an appeal within fourteen days of receipt of BIRGO&rsquo;s initial response to the following:</p>

					<ul>
						<li><a href="mailto:Colorado_Info-Request-Appeal@birgo.com" target="_blank">Colorado_Info-Request-Appeal@birgo.com</a> and Legal Dept, 848 W North Ave, Pittsburgh, PA 15233</li>
					</ul>

					<p>Within forty-five days of receipt of your appeal, BIRGO shall inform you in writing of any action taken or not taken, in response to the appeal, including a written explanation of the reasons for the decisions. BIRGO may extend this period by sixty days where reasonably necessary, considering the complexity and number of requests serving as the basis for the appeal. If unsatisfied, you may file a complaint with the Colorado Attorney General at <a href="https://coag.gov/file-complaint/">https://coag.gov/file-complaint/</a>.</p>

					<h2>Utah Residents</h2>

					<p>If you are a Utah resident, you may, upon request, have certain rights with respect to your Personal Information, including the following rights:</p>

					<ol>
						<li>To confirm if we are processing your Personal Information and to access that data;</li>
						<li>To delete Personal Information that you provided to us;</li>
						<li>If technically feasible, to obtain a copy of data that you provided to us in a portable manner; and</li>
						<li>To opt out of the processing of Personal Information for targeted advertising or sale.</li>
					</ol>

					<p>BIRGO will respond to authenticated requests seeking any of the above requests within forty-five days. All requests pursuant to the above rights should be submitted to:</p>

					<ul>
						<li><a href="mailto:Utah_Info-Request@birgo.com" target="_blank">Utah_Info-Request@birgo.com</a> and Legal Dept, 848 W North Ave, Pittsburgh, PA 15233</li>
					</ul>

					<h2>Connecticut Residents</h2>

					<p>If you are a Connecticut resident, you may, upon request, have certain rights with respect to your Personal Information, including the following rights:</p>

					<ol>
						<li>To know what Personal Information we have collected about you, so long as revealing such information would not reveal a trade secret;</li>
						<li>To obtain, where technically feasible, a copy of all the Personal Information that we have acquired about you;</li>
						<li>To correct inaccuracies in your Personal Information, taking into account the nature of the Personal Information and the purposes of the processing of your Information;</li>
						<li>To delete your Personal Information; and</li>
						<li>To opt out of the processing of your data for the purposes of: a) targeted advertising, b) the sale of Personal Information, or c) profiling in furtherance of solely automated decisions that produce legal or similar significant effects on you.</li>
					</ol>

					<p>BIRGO will respond to authenticated requests seeking any of the above requests within forty-five days. All requests pursuant to the above rights should be submitted to:</p>

					<ul>
						<li><a href="mailto:Connecticut_Info-Request@birgo.com" target="_blank">Connecticut_Info-Request@birgo.com</a> and Legal Dept, 848 W North Ave, Pittsburgh, PA 15233</li>
					</ul>

					<h2>Security of your Personal Information</h2>

					<p>We are committed to protecting the information we receive from you.&nbsp; We take appropriate security measures to protect your information against unauthorized access to or unauthorized alteration, disclosure or destruction of data. To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we maintain appropriate physical, electronic, and managerial procedures to safeguard and secure the information and data stored on our system. BIRGO uses SSL/TLS Protocol and SSL/TLS Encryption for this purpose.</p>

					<h2>Links to Other WebSites</h2>

					<p>Our Sites may contain links to, or advertisements about, non-BIRGO websites. Other sites may also reference, advertise, or link to the BIRGO Sites. You may be able to post content to third party Social Networking sites on which we maintain a presence. If you choose to do this, we will provide information to the Social Networking Sites(s), and receive information from them, in accordance with your elections. You acknowledge and agree that you are solely responsible for your use of those websites and that it is your responsibility to review the applicable terms of use and privacy policies. We are not responsible for the availability, accuracy, content, products or services of third-party Social Networking sites.</p>

					<p>While you are using the Sites, you may be linked or directed to other third-party websites that are beyond our control.&nbsp; These third-party websites may have privacy policies and terms of use which differ from ours.&nbsp; Please carefully review these policies. We are not responsible for any actions or policies of such third parties.</p>

					<h2>Retention and Accuracy of Personal Information</h2>

					<p>We do our best to ensure that the Personal Information we hold, and use is accurate. We rely on the individuals we do business with to disclose to us all relevant information and to inform us of any changes. Personal Information is kept only for so long as is required, in accordance with our records retention policy and legal and regulatory requirements. If Personal Information is no longer required, we destroy it or render it anonymous. In any event, all Personal Information collected by us will be deleted within 24 months of your having last interacted with us or the Sites.</p>

					<h2>Data Transfer</h2>

					<p>We may maintain your Personal Information on computers and servers located outside of your state of residence, country, or economic region. However, we only process, store, and collect data, including Personal Information, in the United States.&nbsp;</p>

					<h2>Changes to the Policy</h2>

					<p>From time to time, we may change this Policy and will notify you of material changes by posting the changed or modified Policy on our Sites. Any changes will be effective immediately upon the posting of the revised Policy unless otherwise specified.</p>

					<h2>Questions and Contact Information</h2>

					<p>If you have any questions, wish to access, correct, or amend your Personal Information or unsubscribe, want to register a complaint or simply want more information, you may contact us at: <a href="mailto:legal_info-request@birgo.com">legal_info-request@birgo.com</a></p>

					<hr />
					<p><a href="#_ftnref1" name="_ftn1" title="">[1]</a> These entities may change over time. Please consult this policy for updated lists of entities we are working with to provide services.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader.vue";
export default {
    name: "PrivacyPolicy",
    components: {MobileHeader},
}
</script>

<style lang="scss">
</style>