<template>
	<div class="screen">

		<!-- Mobile Header -->
		<MobileHeader :dark="false" :menu-toggle="true" title="Invest"/>

		<div class="container-fluid">

			<!-- Desktop Screen Title -->
			<h3 class="screen-title mb-3 d-none d-md-block">Invest Funds</h3>

			<div class="row">
				<div class="col-xxl-7 mb-4">

					<!-- Investment Form -->
					<form action="#" method="post" @submit.prevent="submitInvestment" class="investment-box h-100" v-if="!completed">

						<!-- Error -->
						<div class="error" v-if="bank.error">
							<img src="../assets/bank-account.jpg" alt="Bank Account" class="error-icon">
							<h4>Connect Bank Account</h4>
							<p>You need to connect a bank account before investing.</p>
							<a :href="connectBankAccountLink" class="btn btn-primary mt-4">Connect Account</a>
						</div>

                        <!-- Investing Currently Disabled -->
                        <div class="error">
                            <img src="../assets/bank-account.jpg" alt="Bank Account" class="error-icon">
                            <h4>Not Available</h4>
                            <p>We cannot accept new investments at the moment.</p>
                            <router-link to="/" class="btn btn-primary mt-4">Back to Dashboard</router-link>
                        </div>

						<!-- Form -->
<!--						<div :class="{'form-wrapper': true, 'loading': !offer.loaded || !bank.loaded}" v-if="!bank.error">-->
<!--							<div class="form-field mb-4">-->
<!--								<label for="amount" class="form-label">Investment Amount</label>-->
<!--								<CurrencyInput placeholder="Enter amount in USD" v-model="amount" :disabled="bank.error"/>-->
<!--								<p class="small mt-2">Amount must be between ${{ offer.loaded ? offer.minimum.toLocaleString() : 'Loading' }} and ${{ offer.loaded ? offer.maximum.toLocaleString() : 'Loading' }}</p>-->
<!--								<p class="small fw-bolder text-danger mt-2" v-if="amountError">{{ amountError }}</p>-->
<!--							</div>-->

<!--							<div class="form-field mb-4">-->
<!--								<label class="form-label">Payment Method</label>-->
<!--								<div class="form-check check-checkbox mt-2">-->
<!--									<input class="form-check-input" type="radio" name="payment-method" value="ACH" id="ach" v-model="paymentMethod">-->
<!--									<label class="form-check-label" for="ach">-->
<!--										<span>ACH ({{ bank.nickName }})</span>-->
<!--									</label>-->
<!--								</div>-->
<!--								<div class="form-check check-checkbox mt-2">-->
<!--									<input class="form-check-input" type="radio" name="payment-method" value="WIRE" id="wire" v-model="paymentMethod">-->
<!--									<label class="form-check-label" for="wire">-->
<!--										<span>Wire Transfer</span>-->
<!--									</label>-->
<!--								</div>-->
<!--								<div class="form-check check-checkbox mt-2">-->
<!--									<input class="form-check-input" type="radio" name="payment-method" value="CHECK" id="check" v-model="paymentMethod">-->
<!--									<label class="form-check-label" for="check">-->
<!--										<span>Check</span>-->
<!--									</label>-->
<!--								</div>-->
<!--								<div class="form-check check-checkbox mt-2">-->
<!--									<input class="form-check-input" type="radio" name="payment-method" value="IRA" id="ira" v-model="paymentMethod">-->
<!--									<label class="form-check-label" for="ira">-->
<!--										<span>IRA</span>-->
<!--									</label>-->
<!--								</div>-->
<!--							</div>-->

<!--							<div class="form-field mb-4">-->
<!--								<label class="form-label">Agreement</label>-->
<!--								<div class="form-check check-checkbox mt-3 mb-3">-->
<!--									<input class="form-check-input" type="checkbox" name="authorization" value="authorization" id="authorization" v-model="authorization">-->
<!--									<label class="form-check-label" for="authorization">-->
<!--										<span>By checking this box you authorize this transaction and agree to our <router-link to="/legal/tos" target="_blank">Terms of Use</router-link>, <router-link to="/legal/eft-disclosure" target="_blank">EFT Disclosure</router-link> & <router-link to="/legal/investment-disclosure" target="_blank">Investment Disclosure</router-link>.</span>-->
<!--									</label>-->
<!--									<p class="small fw-bolder text-danger mt-2" v-if="authorizationError">Please confirm that you authorize this transaction.</p>-->
<!--								</div>-->
<!--							</div>-->
<!--							<button class="btn btn-primary btn-wide mt-3 me-2" type="submit" :disabled="loading">-->
<!--								<span class="spinner-border" v-if="loading"></span>-->
<!--								<span :class="{'opacity-0': loading}">Continue</span>-->
<!--							</button>-->
<!--							<router-link to="/transactions" class="btn btn-light btn-wide mt-3">-->
<!--								<span>Recent Transactions</span>-->
<!--							</router-link>-->
<!--							<p class="small fw-bolder text-danger mt-4" v-if="error">-->
<!--								<span v-if="error === 'missing_investment_amount'">Please enter the amount you would like to invest.</span>-->
<!--								<span v-if="error === 'amount_is_below_minimum_investment'">The amount you entered is below the required minimum investment amount.</span>-->
<!--								<span v-if="error === 'amount_is_above_maximum_investment'">The amount you entered is above the allowed maximum investment amount.</span>-->
<!--								<span v-if="error === 'shares_is_above_remaining_shares'">The amount you are trying to purchase is more than the remaining shares of the fund.</span>-->
<!--								<span v-if="error === 'error_while_creating_trade'">Sorry, we could not create the trade. Please get in touch with us.</span>-->
<!--								<span v-if="error === 'error_while_saving_investment'">Sorry, we could not save the investment. Please get in touch with us.</span>-->
<!--							</p>-->
<!--						</div>-->

					</form>

					<!-- Success Message -->
					<div class="investment-box h-100" v-if="completed && paymentMethod === 'ACH'">
						<div class="row">
							<div class="col-xxl-8 mx-auto">
								<div class="success">
									<img src="../assets/icons/check.png" alt="Check" class="error-icon">
									<h4>Investment Confirmed!</h4>
									<p>Your order was placed and will likely settle within 7 days. Once complete, your money will be instantly invested in dozens of projects across the country.</p>
									<router-link to="/transactions" class="btn btn-primary mt-4">View Your Transactions</router-link>
								</div>
							</div>
						</div>
					</div>

					<!-- Complete Payment Message -->
					<div class="investment-box h-100" v-if="completed && paymentMethod !== 'ACH'">
						<div class="row">
                            <div id="payment-instructions">
                                <div class="col-xxl-8 mx-auto">
                                    <div class="success">
                                        <img src="../assets/icons/check.png" alt="Check" class="error-icon">
                                        <h4>Complete your investment payment!</h4>
                                        <p>Thank you for your interest in investing <strong>${{ amount.toLocaleString() }}</strong> in Reiturn. To finalize your investment, please make your payment using the following payment details.</p>
                                    </div>
                                </div>
                                <div class="col-xxl-12 mx-auto">
                                    <table class="table payment-instructions">
                                        <tr v-for="(instruction, key) in paymentInstructions" :key="key">
                                            <td style="font-weight: bold;vertical-align: top;padding-bottom: 1rem;padding-right: 0.5rem;">{{ key }}</td>
                                            <td style="vertical-align: top;padding-bottom: 1rem;" v-html="instruction"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xxl-8 mx-auto">
                                <div class="success">
                                    <p class="small opacity-50">We've also sent these payment instructions on your email.</p>
                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                        <button @click.prevent="printInstructions" type="button" class="btn btn-secondary mt-4">Print Details</button>
                                        <router-link to="/transactions" class="btn btn-primary mt-3">View Your Transactions</router-link>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>

				</div>
				<div class="col-xxl-5 mb-4">

					<!-- Auto-investment -->
<!--					<AutoInvestmentConfiguration :offer="offer"></AutoInvestmentConfiguration>-->

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import repository from "@/repository/repository";
import MobileHeader from "@/components/MobileHeader.vue";
import {ONBOARDING_URL} from "@/main";

export default {
    name: "Invest",
    components: {MobileHeader, },
    created() {
        // this.loadOffer();
        // this.loadBankAccount();
    },
    data() {
        return {
            amount: null,
			paymentMethod: "ACH",
            paymentInstructions: [],
            offer: {
                minimum: null,
                maximum: null,
                unitPrice: null,
                loaded: false,
            },
			bank: {
                name: null,
				nickName: null,
				routingNumber: null,
				accountNumber: null,
				type: null,
                loaded: false,
				error: false,
			},
            showErrors: false,
            amountError: false,
			completed: false,
			authorization: false,
            authorizationError: false,
            error: false,
            loading: false,
        }
    },
	methods: {
        loadOffer() {
            repository.get("/offer")
                .then(response => {
                    // this.offer.minimum = response.data.offer.minimumInvestment;
                    this.offer.minimum = 500;
                    this.offer.maximum = response.data.offer.maximumInvestment;
                    this.offer.unitPrice = response.data.offer.unitPrice;
                    this.offer.loaded = true;
                })
		},
        loadBankAccount() {
            this.bank.error = false;
            this.bank.loaded = false;
            repository.get("/bank-account")
                .then(response => {
                    this.bank = response.data.bank;
                    this.bank.error = false;
                    this.bank.loaded = true;
                })
				.catch(() => {
                    this.bank.error = true;
                    this.bank.loaded = true;
				})
		},
        submitInvestment() {
            if(!this.verifyAmount()) return;
            if(!this.verifyAuthorisation()) return;
            this.loading = true;
            this.error = false;
            repository.post("/investments", {
                amount: this.amount,
                payment_method: this.paymentMethod,
			})
				.then((response) => {
                    console.log(response);
                    this.completed = true;
                    if(this.paymentMethod === 'ACH') this.paymentInstructions = [];
                    else this.paymentInstructions = response.data.instructions;
				})
				.catch(error => {
                    console.log(error);
                    this.loading = false;
                    this.error = error.response.data.code;
				})
		},
        verifyAmount() {
            this.amountError = false;
            if(this.amount < this.offer.minimum) {
                this.amountError = "Your investment amount should be at least $" + this.offer.minimum.toLocaleString();
                return false;
            }
            if(this.amount > this.offer.maximum) {
                this.amountError = "Your investment amount cannot be more than $" + this.offer.maximum.toLocaleString();
                return false;
            }
            if(this.amount % this.offer.unitPrice !== 0) {
                this.amount = (Math.floor(this.amount / this.offer.unitPrice) * this.offer.unitPrice);
            }
            return true;
        },
		verifyAuthorisation() {
            if(!this.authorization) {
                this.authorizationError = true;
                return false;
			}
            this.authorizationError = false;
            return true;
		},
        printInstructions() {
            const contentToPrint = document.getElementById("payment-instructions");
            if (contentToPrint) {
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                document.body.appendChild(iframe);

                const printDocument = iframe.contentWindow.document;
                printDocument.open();
                printDocument.write(contentToPrint.outerHTML);
                printDocument.close();

                iframe.contentWindow.focus();
                iframe.contentWindow.print();

                setTimeout(() => {
                    document.body.removeChild(iframe);
                }, 100);
            }
        },
	},
	computed: {
        connectBankAccountLink() {
            return ONBOARDING_URL + "/auth?t=" + this.$store.getters.token;
		},
	},
}
</script>

<style lang="scss" scoped>
.investment-box {
	display: block;
	position: relative;
	padding: 2.5rem;
	background: #fff;
	border-radius: 0.5rem;

	@media (max-width: 767.99px) {
		background: transparent;
		padding: 0;
	}

	// Info boxes
	h4 {
		font-size: 1.25rem;
		color: #16193A;
		font-weight: 700;
	}

	img.main-icon {
		width: 3rem;
		height: 3rem;
	}

	// Error Box
	.error {
		text-align: center;

		@media (max-width: 767.99px) {
			background: #fff;
			width: calc(100% + 3rem);
			margin: -2.5rem -1.5rem 0 -1.5rem;
			padding: 2.5rem 1.5rem;
		}

		img.error-icon {
			width: 10rem;
			height: auto;
		}

		h4 {
			font-weight: bold;
			color: #16193A;
			margin: 2rem 0 1rem 0;
			font-size: 1.5rem;
		}

		p {
			margin: 0;
			color: #16193A;
		}
	}

	// Success Box
	.success {
		text-align: center;

		img.error-icon {
			width: 6rem;
			height: auto;
		}

		h4 {
			font-weight: bold;
			color: #16193A;
			margin: 2rem 0 1rem 0;
			font-size: 1.5rem;
		}

		p {
			margin: 0;
			color: #16193A;
		}
	}

    // Payment instructions
    .table.payment-instructions {
        margin-top: 1rem;

        tr {
            td {
                color: #16193A;
                vertical-align: top;
                border-top: 1px solid #eee;

                &:first-child {
                    width: 35%;
                    font-weight: bold;
                    text-align: right;
                    padding-right: 0.5rem;
                }

                &:last-child {
                    text-align: left;
                }
            }
        }
    }

	// Form Items
	.form-wrapper {

		@media (max-width: 767.99px) {
			.form-field {
				.form-control {
					background: transparent;
					font-size: 1.5rem;
				}
			}

			.btn {
				display: block;
				width: 100%;

				&.btn-light {
					background: #dee5ea;
					border-color: #dee5ea;
				}
			}
		}

		&.loading {
			* {
				pointer-events: none;
				user-select: none;
			}

			.form-field {

				.form-control {
					background: rgba(#16193A, 0.05);
					border: none;
					border-radius: 0.5rem;
					color: transparent;
					transform: translateY(0.25rem);

					&::-webkit-input-placeholder {
						color: transparent;
					}

					&::-moz-placeholder {
						color: transparent;
					}

					&:-ms-input-placeholder {
						color: transparent;
					}

					&:-moz-placeholder {
						color: transparent;
					}
				}

				.form-label {
					background: rgba(#16193A, 0.05);
					border: none;
					border-radius: 0.5rem;
					color: transparent;
					display: inline-block;
				}

				.form-check {
					.form-check-input {
						background: rgba(#16193A, 0.05);
						border: none;
						border-radius: 0.5rem;
						color: transparent;
						box-shadow: none;
					}
					.form-check-label {
						background: rgba(#16193A, 0.05);
						border: none;
						border-radius: 0.5rem;
						color: transparent;

						* {
							opacity: 0;
						}
					}
				}
			}

			.btn {
				background: rgba(#16193A, 0.05);
				border: none;
				border-radius: 0.5rem;
				color: transparent;
				box-shadow: none;
				animation: loading-pulse 0.5s infinite;
			}

			p {
				background: rgba(#16193A, 0.05);
				border: none;
				border-radius: 0.5rem;
				color: transparent;
				box-shadow: none;
			}
		}
	}
}
</style>