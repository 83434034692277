<template>
	<div class="dashboard-wrapper">
		<div class="dashboard-content">
			<router-view/>
		</div>
	</div>
</template>

<script>
export default {
    name: "LegalWrapper",
    data() {
        return {}
    },
}
</script>

<style lang="scss">
.legal-screen {
	padding: 1.5rem 0;
	color: #16193A;
	font-size: 0.9rem;

	@media (max-width: 767.99px) {
		padding-top: 0;
	}

	a {
		color: #F37021;
		font-weight: 600;
	}

	.screen-title {
		font-size: 2rem;
		color: #16193A;
		font-weight: bold;
	}

	h2 {
		color: #16193A;
		font-weight: bold;
		font-size: 1.5rem;
		margin: 2.5rem 0 1rem 0;
	}

	h3 {
		color: #16193A;
		font-weight: bold;
		font-size: 1.25rem;
		margin: 2.5rem 0 1rem 0;
	}

	h4 {
		color: #16193A;
		font-weight: bold;
		font-size: 1.15rem;
		margin: 2.5rem 0 1rem 0;
	}

	h5 {
		color: #16193A;
		font-weight: bold;
		font-size: 1rem;
		margin: 2.5rem 0 1rem 0;
	}

	h6 {
		color: #16193A;
		font-weight: 600;
		font-size: 1rem;
		margin: 2.5rem 0 1rem 0;
	}
}
</style>