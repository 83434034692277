<template>
	<div class="screen">
		<MobileHeader title="Legal Notices" :menu-toggle="true"/>
		<div class="container-fluid">

			<!-- Desktop Screen Title -->
			<h3 class="screen-title mb-3 d-none d-md-block">Legal Notices</h3>

			<!-- Links -->
			<div class="legal-boxes d-flex flex-column flex-md-row mt-3 mb-4 flex-wrap">
				<ActionBox :openModal="openModal" link="/legal/tos" title="Terms of Use" icon="document-alt.svg"/>
				<ActionBox :openModal="openModal" link="/legal/privacy-policy" title="Privacy Policy" icon="document-alt.svg"/>
				<ActionBox :openModal="openModal" link="/legal/eft-disclosure" title="EFT Disclosure" icon="document-alt.svg"/>
				<ActionBox :openModal="openModal" link="/legal/investment-disclosure" title="Investment Disclosure" icon="document-alt.svg"/>
			</div>

			<!-- Popups -->
			<Popup :class="{'active': modal !== null}" size="large" ref="legalPopup">
				<TermsOfUse v-if="modal === '/legal/tos'"/>
				<PrivacyPolicy v-if="modal === '/legal/privacy-policy'"/>
				<EftDisclosure v-if="modal === '/legal/eft-disclosure'"/>
				<InvestmentDisclosure v-if="modal === '/legal/investment-disclosure'"/>
			</Popup>

		</div>
	</div>
</template>

<script>
import ActionBox from "@/components/widgets/ActionBox.vue";
import MobileHeader from "@/components/MobileHeader.vue";
import TermsOfUse from "@/views/legal/TermsOfUse.vue";
import Popup from "@/components/widgets/Popup.vue";
import PrivacyPolicy from "@/views/legal/PrivacyPolicy.vue";
import EftDisclosure from "@/views/legal/EftDisclosure.vue";
import InvestmentDisclosure from "@/views/legal/InvestmentDisclosure.vue";
export default {
    name: "Legal",
    components: {InvestmentDisclosure, EftDisclosure, PrivacyPolicy, Popup, TermsOfUse, MobileHeader, ActionBox},
	data() {
		return {
			modal: null,
		}
	},
	created() {
		let app = this;
		window.addEventListener('popstate', function () {
			app.modal = null;
		});
	},
	methods: {
		openModal(route) {
			if(this.isMobile()) {
				this.$router.push(route);
			} else {
				history.pushState({page: 1}, "", route);
				this.modal = route;
				this.$refs.legalPopup.$el.scrollTop = 0;
			}
		},
		isMobile() {
			return screen.width <= 760;
		},
	},
}
</script>

<style lang="scss">
@media (max-width: 767.99px) {
	.legal-boxes .action-box {
		width: 100%;
		height: auto;
		display: flex;
		align-items: center;
		flex-direction: row;
		padding: 1rem;
		margin-right: 0;
		margin-bottom: 0.5rem;

		img {
			margin-right: 1rem;
		}
	}
}
</style>