<template>
	<div class="account-screen">

        <!-- Desktop Screen Title -->
        <h3 class="screen-title mb-3 d-none d-md-block">Account Verification</h3>

        <div class="row">
            <div class="col-xxl-6 mb-4">
                <div class="kyc-box">
                    <h4>KYC Verification</h4>
                    <p>KYC verification is essential to establish the identity of customers, mitigating fraud, and adhering to regulatory requirements for financial transactions.</p>

                    <!-- Loading -->
                    <div class="spinner-border text-black-50 opacity-25 mb-3 d-block" v-if="!verification.loaded"></div>

                    <!-- Current Statuses -->
                    <div v-if="verification.loaded && !verification.error">
                        <div class="verification-status">
                            <div class="badge bg-success" v-if="verification.kyc === 'Auto Approved' || verification.kyc === 'Manually Approved'">Approved</div>
                            <div class="badge bg-danger" v-if="verification.kyc === 'Disapproved' && !verification.documents">Disapproved</div>
                            <div class="badge bg-secondary" v-if="verification.kyc === 'Disapproved' && verification.documents">In Progress</div>
                            <div class="badge bg-secondary" v-if="verification.kyc === 'Pending'">Pending</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6 mb-4">
                <div class="kyc-box">
                    <h4>AML Verification</h4>
                    <p>AML verification is necessary to prevent money laundering by confirming the identities of individuals and ensuring compliance with regulations.</p>

                    <!-- Loading -->
                    <div class="spinner-border text-black-50 opacity-25 mb-3 d-block" v-if="!verification.loaded"></div>

                    <!-- Current Statuses -->
                    <div v-if="verification.loaded && !verification.error">
                        <div class="verification-status">
                            <div class="badge bg-success" v-if="verification.aml === 'Auto Approved' || verification.aml === 'Manually Approved'">Approved</div>
                            <div class="badge bg-danger" v-if="verification.aml === 'Disapproved' && !verification.documents">Disapproved</div>
                            <div class="badge bg-secondary" v-if="verification.aml === 'Disapproved' && verification.documents">In Progress</div>
                            <div class="badge bg-secondary" v-if="verification.aml === 'Pending'">Pending</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-12 mb-4" v-if="verification.loaded">
                <div class="kyc-box" v-if="!verification.documents_uploaded">

                    <!-- Error -->
                    <div class="error" v-if="verification.error">
                        <p class="text-danger fw-bolder small">We couldn't fetch your account verification details. Please try again later.</p>
                    </div>

                    <!-- Form -->
                    <form action="#" method="post" @submit.prevent="submit" v-if="!verification.error">
                        <h4>Upload Documents</h4>
                        <p>We couldn't verify some of the account details you provided. Please attach the required document below.</p>

                        <div class="form-field mt-4">
                            <label for="documentType" class="form-label">Document Type</label>
                            <select class="form-control" name="documentType" id="documentType" required v-model="type">
                                <option disabled selected value="">Please choose</option>
                                <option value="U.S. Government Issued Photo ID">U.S. Government Issued Photo ID</option>
                                <option value="U.S. Government Issued Passport">U.S. Government Issued Passport</option>
                            </select>
                        </div>

                        <div class="form-field mt-3 pt-3">
                            <label for="documentFile" class="form-label">Attach JPG/PDF</label>
                            <div class="file-upload-wrapper">
                                <input type="file" class="form-control" name="documentFile" id="documentFile" accept="image/jpeg,image/png,application/pdf" required ref="file" @change="uploadFile">
                                <div class="file-item" v-if="file">
                                    <strong>{{ file.name }}</strong>
                                    <span>{{ fileSize }}</span>
                                </div>
                                <label for="documentFile">
                                    <img src="../../assets/icons/document-alt.svg" alt="Document Icon">
                                    <span class="file-types">
											<span class="h6 fw-bolder">Choose Your File</span>
											<span class="small d-block text-black-50">Allowed files: PDF, JPG, PNG</span>
											<span class="small d-block text-black-50">Maximum file size: 10MB</span>
										</span>
                                </label>
                            </div>
                        </div>

                        <div v-if="showErrors">
                            <p class="text-danger fw-bolder mt-4 small" v-if="showErrors === 'file_type_or_size_wrong'">Please choose valid document file and make sure the file is below 10MB.</p>
                            <p class="text-danger fw-bolder mt-4 small" v-if="showErrors === 'document_upload_failed'">We couldn't save your document at this moment. Please try again with a different file.</p>
                        </div>

                        <!-- Form Navigation -->
                        <div class="d-flex flex-column-reverse flex-md-row justify-content-md-end pt-5">
                            <!--			<button class="btn btn-outline-dark me-md-2 mt-2 mt-md-0" type="button" @click.prevent="back" :disabled="loading">Back</button>-->
                            <button class="btn btn-primary btn-wide" type="submit" :disabled="loading">
                                <span class="spinner-border" v-if="loading"></span>
                                <span :class="{'opacity-0': loading}">Upload &amp; Continue</span>
                            </button>
                        </div>
                    </form>

                </div>
                <div class="kyc-box" v-if="verification.documents_uploaded">
                    <div class="row align-items-center">
                        <div class="col-2 col-lg-1">
                            <img src="../../assets/icons/check.png" alt="Check" class="w-100">
                        </div>
                        <div class="col-10">
                            <h4>Your verification documents have been uploaded!</h4>
                            <p class="mb-0">We will update your KYC/AML status after manually verifying the details.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "Verification",
    components: {},
    created() {
    },
    data() {
        return {
            type: null,
            file: null,
            showErrors: false,
            loading: false,
            completed: false,
        }
    },
	methods: {
        submit() {

            // Start loading effect
            this.loading = true;
            this.showErrors = false;

            // Save updates in store
            // this.$store.commit("updateKYC", {
            //     type: this.type,
            //     file: this.file,
            // });

            // Send request to save the current data
            let formData = new FormData();
            formData.append('type', this.type);
            formData.append('file', this.file);
            let headers = { 'Content-Type': 'multipart/form-data' };
            repository.post("/kyc-verification", formData, { headers })
                .then(response => {
                    console.log(response);
                    this.loading = false;
                    this.completed = true;
                    this.$store.commit("confirmVerification");
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                    this.showErrors = error.response.data.code;
                })

        },
        uploadFile() {
            this.file = this.$refs.file.files[0];
        },
    },
    computed: {
        fileSize() {
            if(!this.file || !this.file.size) return "";
            return (this.file.size / 1000000).toFixed(2) + "MB";
        },
        verification() {
            return this.$store.getters.verification;
        },
	},
}
</script>

<style lang="scss">
.file-upload-wrapper {
	display: block;
	position: relative;
	margin-top: 0.5rem;

	.file-item {
		display: flex;
		align-items: center;
		color: #F37021;
		margin-bottom: 0.75rem;
		padding-left: 1.25rem;
		position: relative;

		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 1rem;
			height: 1rem;
			background: url('../../assets/icons/document-alt.svg') center no-repeat;
			background-size: contain;
			content: ' ';
			margin: auto;
		}

		strong {
			font-size: 0.9rem;
		}

		span {
			font-size: 0.8rem;
			color: #16193A;
			opacity: 0.5;
			margin-left: 1rem;
		}
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}

	label {
		display: flex;
		align-items: center;
		position: relative;
		padding: 1.5rem;
		border-radius: 0.5rem;
		background: #f1f6f8;
		border: 1px solid darken(#f1f6f8, 10%);
		transition: 0.15s ease all;
		cursor: pointer;

		&:hover {
			background: darken(#f1f6f8, 2.5%);
		}

		img {
			width: 3rem;
			height: 3rem;
			display: block;
			margin-bottom: 0.5rem;
			flex-shrink: 0;
			flex-grow: 0;
		}

		.h6 {
			color: #16193A;
			margin: 0;
		}

		.file-types {
			display: block;
			padding-left: 1rem;
		}

	}
}

.kyc-box {
	display: block;
	position: relative;
	padding: 2.5rem;
	background: #fff;
	border-radius: 0.5rem;

    @media (max-width: 767.99px) {
        padding: 1.5rem 0;
    }

	h4 {
		font-size: 1.25rem;
		color: #16193A;
		font-weight: 700;
	}

    .verification-status {
        .badge {
            padding: 0.75rem 1.5rem;
            font-size: 1rem;
        }
    }
}
</style>