import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        token: localStorage.getItem("token"),
        adminToken: localStorage.getItem("adminToken"),
        user: {
            name: null,
            credentials: null,
            email: null,
            joined: null,
            loaded: false,
        },
        verification: {
            kyc: null,
            aml: null,
            documents_uploaded: null,
            loaded: false,
            error: false,
        },
        investments: {
            total: null,
            refunded: null,
            entries: [],
            loaded: false,
        },
        autoInvestment: {
            frequency: "Disabled",
            startDay: null,
            amount: null,
            otherAmount: null,
        },
        unsignedAgreements: 0,
        mobileSidebar: false,
    },
    mutations: {
        logoutUser(state) {
            state.token = null;
            localStorage.removeItem("token");
        },
        updateAuth(state, data) {
            state.token = data.token;
            localStorage.setItem("token", data.token);
        },
        updateUser(state, data) {
            state.user.name = data.name;
            state.user.credentials = data.credentials;
            state.user.email = data.email;
            state.user.joined = data.joined;
            state.user.loaded = true;
            state.verification.kyc = data.kyc_status;
            state.verification.aml = data.aml_status;
            state.verification.documents_uploaded = data.kyc_document_uploaded;
            state.verification.loaded = true;
            window.Intercom('update', {"name": data.name, "email": data.email, "user_id": data.id});
        },
        updateInvestments(state, data) {
            state.investments.total = data.investment;
            state.investments.refunded = data.refund;
            state.investments.loaded = true;
        },
        updateAutoInvestment(state, data) {
            state.autoInvestment.frequency = data.auto_investment_period;
            state.autoInvestment.startDay = data.auto_investment_start_day;
            state.autoInvestment.amount = (data.auto_investment_amount !== 100 && data.auto_investment_amount !== 250 && data.auto_investment_amount !== 500 && data.auto_investment_amount !== 1000 && data.auto_investment_amount !== 1500 ? 'Other' : data.auto_investment_amount);
            state.autoInvestment.otherAmount = (state.autoInvestment.amount === 'Other' ? data.auto_investment_amount : null);
        },
        updateUnsignedAgreements(state, value) {
            state.unsignedAgreements = value;
        },
        updateAutoInvestmentConfiguration(state, data) {
            state.autoInvestment.frequency = data.frequency;
            state.autoInvestment.startDay = data.startDay;
            state.autoInvestment.amount = data.amount;
            state.autoInvestment.otherAmount = data.otherAmount;
        },
        showMobileSidebar(state) {
            state.mobileSidebar = true;
        },
        hideMobileSidebar(state) {
            state.mobileSidebar = false;
        },
        updateAdmin(state, data) {
            state.adminToken = data.adminToken;
            localStorage.setItem("adminToken", data.adminToken);
        },
        logoutAdmin(state) {
            state.adminToken = null;
            localStorage.removeItem("adminToken");
        },
        verificationLoadingError(state) {
            state.verification.error = true;
            state.verification.loaded = true;
        },
        confirmVerification(state) {
            state.verification.documents_uploaded = true;
            state.verification.kyc = "Pending";
            state.verification.aml = "Pending";
        },
    },
    getters: {
        adminToken: state => state.adminToken,
        token: state => state.token,
        user: state => state.user,
        investments: state => state.investments,
        autoInvestment: state => state.autoInvestment,
        mobileSidebar: state => state.mobileSidebar,
        verification: state => state.verification,
        unsignedAgreements: state => state.unsignedAgreements,
    },
})