<template>
	<div class="legal-wrapper">

		<!-- Mobile Header -->
		<MobileHeader title="Investment Disclosure" :back-link="true"/>

		<div class="container">
			<div class="legal-screen">
				<div class="legal-content">

					<!-- Desktop Screen Title -->
					<h1 class="screen-title mb-3 d-none d-md-block">Investment Disclosure</h1>

					<p>The information contained within this website does not constitute an offer to sell, nor the solicitation of an offer to buy, any security and shall not constitute any offer, solicitation or sale in any jurisdiction in which such offer, solicitation, purchase or sale is unlawful. Birgo Reiturn Fund LLC is offering securities through the use of an Offering Statement that has been qualified by the Securities and Exchange Commission under Tier II of Regulation A. A copy of the Offering Circular that forms a part of the Offering Statement may be obtained from our website: <a href="./offering-circular" target="_blank">Reiturn Offering Circular</a>. In addition, our Offering Circular and other offering documents may be accessed through EDGAR, the public database maintained by the SEC, at <a href="https://www.sec.gov" target="_blank">www.sec.gov</a>. You should read our offering documents in their entirety in order to understand fully all of the implications of our offering, and you should invest only after careful review of all the offering documents, Including the “Risk Factors” section of the Offering Circular, for a discussion of the risks and uncertainties that we believe are material to our business, operating results, prospects and financial condition. The information contained herein is subject to the terms and conditions set forth in the offering documents.</p>

					<p>An investor in Birgo Reiturn Fund LLC may invest no more than 10% of the greater of their annual income (including the income of their spouse) or their net worth (together with their spouse), excluding the value of the person’s primary residence and any loans secured by the residence (up to the value of the residence). Different rules apply to Accredited Investors and non-natural persons. Before representing that your investment does not exceed applicable thresholds, we encourage you to review Rule 251(d)(2)(i)(c) of Regulation A. For general information on investing, we encourage you to refer to <a href="https://www.investor.gov" target="_blank">www.investor.gov</a>.</p>

					<p>Our offering documents and this website contain forward-looking statements and information relating to, among other things, Birgo Realty LLC and Birgo Reiturn Fund LLC, their business plans, strategies and objectives, and their industry. These forward-looking statements include our plans and objectives for future operations, including expectations relating to future growth and availability of funds, and are based on current assumptions that involve numerous risks and uncertainties. Our assumptions relating to these statements involve judgments with respect to, among other things, future economic, competitive and market conditions and future business decisions, all of which are difficult or impossible to accurately predict and many of which are beyond our control. Although we believe the assumptions underlying the forward-looking statements, and the forward-looking statements themselves, are reasonable, any of the assumptions could be inaccurate and, therefore, there can be no assurance that these forward-looking statements will prove to be accurate and our actual results, performance and achievements may be materially different from that expressed or implied by these forward-looking statements. When used In The offering documents, words such as “estimate,” “project,” “believe,” “anticipate,” “intend,” “expect” and similar expressions are intended to identify forward-looking statements. These forward-looking statements reflect Management’s current views, and investors are cautioned that these statements speak only as of the date on which they are made. Birgo Realty LLC and Reiturn Fund LLC do not undertake any obligation to revise or update these forward-looking statements to reflect events or circumstances after such date, or to reflect the occurrence of unanticipated events.</p>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader.vue";
export default {
    name: "InvestmentDisclosure",
    components: {MobileHeader},
}
</script>

<style lang="scss">
</style>