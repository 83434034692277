<template>
	<div class="loading-effect py-5 text-center">
		<div class="spinner-border text-black-50 opacity-25"></div>
	</div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "AdminAuth",
    props: [ 'userToken', 'adminToken', ],
	created() {
        if(!this.userToken || !this.adminToken) {
            this.$router.replace("/");
		}
        this.authenticate();
    },
    methods: {
        authenticate() {
            // Verify admin token
            repository.get("/admin-validation", {
                headers: {
                    'Authorization': 'Bearer ' + this.adminToken,
                }
            })
                .then(() => {
                    // If successful, verify user token
                    repository.get("/me", {
                        headers: {
                            'Authorization': 'Bearer ' + this.userToken,
                        }
                    })
                        .then(response => {
                            // If successful, authenticate user via admin
							this.$store.commit("updateAdmin", {
                                adminToken: this.adminToken,
							});
                            this.$store.commit("updateAuth", {
                                token: this.userToken,
                            });
                            this.$store.commit("updateUser", response.data.user);
                            this.$store.commit("updateInvestments", {
                                investment: response.data.investment,
                                refund: response.data.total_refund,
                            });
                            this.$store.commit("updateAutoInvestment", response.data.user);
                            this.$router.replace("/");
                        })
                        .catch(() => {
                            // If user token is invalid. sign out immediately
                            this.$store.commit("logoutUser");
                            this.$store.commit("logoutAdmin");
                            this.$router.replace("/login");
                        })
                })
                .catch(() => {
                    // If admin token is invalid, sign out immediately
                    this.$store.commit("logoutUser");
                    this.$store.commit("logoutAdmin");
                    this.$router.replace("/login");
                })
		},
	},
}
</script>

<style lang="scss">
</style>