<template>
	<div :class="{'screen article-screen': true, 'article-loading': !loaded}">

		<!-- Mobile Header -->
		<MobileHeader :dark="false" :back-link="true" :title="title ? title : 'Please wait'"/>

		<div class="container-fluid">

			<!-- Body -->
			<div class="row">
				<div class="col-xxl-8 mx-auto">

					<!-- Main Image -->
					<div class="article-thumbnail">
						<img :src="image" :alt="title" v-if="loaded">
					</div>

					<!-- Title -->
					<h1 class="article-title">{{ title ? title : 'Please wait' }}</h1>

					<!-- Topics -->
					<ul class="article-topics">
						<li v-for="(topic, index) in topics" :key="index">{{ topic }}</li>
					</ul>

					<!-- Body -->
					<div class="article-body" v-html="body"></div>

				</div>
			</div>

		</div>

	</div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader.vue";
import repository from "@/repository/repository";

export default {
    name: "InsightsArticle",
    components: {MobileHeader},
    data() {
        return {
            title: null,
			image: null,
			description: null,
			author: null,
			date: null,
			body: null,
			topics: [],
			loaded: false,
        }
    },
	created() {
        this.loadArticle(this.$route.params.id);
    },
    methods: {
        loadArticle(id) {
            this.loaded = false;
            repository.get("/news/" + id)
				.then(response => {
                    this.title = response.data.title;
                    this.image = response.data.image;
                    this.description = response.data.description;
                    this.author = response.data.author;
                    this.date = response.data.date;
                    this.body = response.data.body;
                    this.topics = response.data.topics;
                    this.loaded = true;
				})
		},
	},
	watch: {
        '$route.params.id': function (id) {
            this.loadArticle(id);
		}
	},
}
</script>

<style lang="scss">
.article-screen {

	.article-thumbnail {
		max-width: 100%;
		width: 100%;
		height: 25rem;
		display: block;
		object-fit: cover;
		margin-bottom: 1.5rem;
		border-radius: 0.5rem;
		background: rgba(#16193A, 0.1);
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			height: 25rem;
			object-fit: cover;
		}

		@media (max-width: 767px) {
			max-width: calc(100% + 3rem);
			width: calc(100% + 3rem);
			height: 15rem;
			margin: -2.5rem -1.5rem 2.5rem -1.5rem;
			border-radius: 0;

			img {
				height: 15rem;
			}
		}
	}

	.article-topics {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		list-style: none;
		margin: 1rem 0;
		padding: 0;

		li {
			display: block;
			padding: 0.25rem 0.5rem;
			font-size: 0.9rem;
			font-weight: 600;
			color: #16193A;
			background: rgba(#16193A, 0.1);
			border-radius: 0.75rem;
			margin: 0 0.25rem 0.25rem 0;
		}
	}

	.article-title {
		font-size: 2.5rem;
		font-weight: bold;
		color: #16193A;
		margin: 2.5rem 0 1rem 0;
	}

	.article-body {

		* {
			color: #16193A;
		}

		a, a * {
			color: #F37021;
			font-weight: 600;
		}

		img {
			max-width: 100%;
			border-radius: 0.5rem;
			box-shadow: rgba(#16193A, 0.1) 0 0.1rem 0.2rem;
		}

		h1 {
			font-size: 3rem;
			font-weight: bold;
			color: #16193A;
			display: none;

			@media (max-width: 767px) {
				font-size: 2.5rem;
			}
		}

		h2, h3, h4, h5, h6 {
			font-weight: bold;
			color: #16193A;
		}
	}

	&.article-loading {

		.article-title {
			border-radius: 0.5rem;
			background: rgba(#16193A, 0.1);
			color: transparent;
		}

		.article-topics {
			border-radius: 0.5rem;
			background: rgba(#16193A, 0.05);
			color: transparent;
			min-height: 2rem;
		}

		.article-body {
			border-radius: 0.5rem;
			background: rgba(#16193A, 0.05);
			color: transparent;
			min-height: 10rem;
		}
	}
}
</style>