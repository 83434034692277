<template>
	<input type="text" class="form-control" :placeholder="placeholder" ref="inputRef"/>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
    name: "CurrencyInput",
	props: {
        'placeholder': String,
		'value': Number,
    },
	setup() {
        const { inputRef } = useCurrencyInput({
            currency: 'USD',
            hideCurrencySymbolOnFocus: false,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: false,
            valueAsInteger: true,
            precision: 0,
			allowNegative: false,
        });
        return {inputRef}
	},
}
</script>

<style scoped>

</style>