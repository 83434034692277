<template>
    <div :class="{'account-value-box': true, 'loading': !summary.loaded}">
        <h5>Your Account Value</h5>
        <div class="row">
            <div class="col-md-6 col-xxl-12">
                <div :class="{'account-value': true, 'loading': !summary.loaded}">
                    <span class="spinner-border opacity-25" v-if="!summary.loaded"></span>
                    <strong>${{ summary.loaded ? totalAccountValue.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
                </div>
                <p :class="{'small': true, 'opacity-50': summary.loaded, 'opacity-0': !summary.loaded}">The current account value does not account for your pending and upcoming investments. Once your investments are funded, they will be displayed here.</p>
                <div class="d-none d-md-block d-xxl-none">
                    <router-link to="/invest" class="btn btn-primary d-inline-flex align-items-center mb-2 me-md-2">
                        <img src="@/assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
                        <span>Invest Now</span>
                    </router-link>
                    <router-link to="/calculator" class="btn btn-secondary d-inline-flex align-items-center mb-2">
                        <img src="@/assets/icons/calculator.svg" alt="Calculator" class="btn-icon icon-force-white">
                        <span>Growth Calculator</span>
                    </router-link>
                </div>
            </div>
            <div class="col-md-6 col-xxl-12">
                <div :class="{'investment-breakdown': true, 'loading': !summary.loaded}">
                    <div class="breakdown-item">
                        <span>Total Invested</span>
                        <strong>${{ summary.loaded ? summary.cleared.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
                    </div>
                    <div class="breakdown-item" v-if="summary.manually_refunded > 0">
                        <span>Returned Investments</span>
                        <strong class="text-danger">-${{ summary.loaded ? summary.manually_refunded.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
                    </div>
                    <div class="breakdown-item">
                        <span>Total Returns</span>
                        <strong :class="{'text-success': totalReturns > 0}">{{ totalReturns > 0 ? '+' : '' }}${{ totalReturns.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</strong>
                    </div>
                    <div class="breakdown-item">
                        <span>Reinvested</span>
                        <strong>${{ summary.loaded ? summary.reinvested.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
                    </div>
                    <div class="breakdown-item">
                        <span>Distributed</span>
                        <strong>${{ summary.loaded ? summary.distributed.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-md-none d-xxl-block">
            <router-link to="/invest" class="btn btn-primary d-flex justify-content-center align-items-center mt-4">
                <img src="@/assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
                <span>Invest Now</span>
            </router-link>
            <router-link to="/calculator" class="btn btn-secondary d-flex justify-content-center align-items-center mt-2">
                <img src="@/assets/icons/calculator.svg" alt="Calculator" class="btn-icon icon-force-white">
                <span>Growth Calculator</span>
            </router-link>
        </div>
    </div>
</template>

<script>

export default {
	name: "PerformanceAccountValue",
    props: ['summary'],
    computed: {
        totalAccountValue() {
            return (this.summary.cleared + this.summary.reinvested) - this.summary.manually_refunded;
        },
        totalReturns() {
            if(!this.summary.loaded) return '0.00';
            return this.summary.reinvested + this.summary.distributed;
        },
    },
}
</script>

<style lang="scss" scoped>
.account-value-box {
    display: block;
    padding: 2.5rem;
    background: #fff;
    border-radius: 0.5rem;
    position: sticky;
    top: 1.5rem;

    @media (max-width: 767.99px) {
        border-radius: 0;
        padding: 1rem 1.5rem 2.5rem 1.5rem;
        width: calc(100% + 3rem);
        margin: -2.5rem -1.5rem 0 -1.5rem;
    }

    h5 {
        font-size: 1rem;
        font-weight: 600;
        color: #16193A;
    }

    .account-value {
        position: relative;

        strong {
            font-size: 3rem;
            font-weight: 600;
            color: #16193A;

            @media (min-width: 1400px) and (max-width: 1600px) {
                font-size: 2rem;
                font-weight: bold;
            }
        }

        &.loading {
            pointer-events: none;

            .spinner-border {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
            }

            strong {
                color: transparent;
            }
        }
    }

    .investment-breakdown {

        .breakdown-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #16193A;
            margin-top: 0.75rem;
            padding-top: 0.75rem;
            border-top: 1px solid #eee;
        }

        &.loading {
            .breakdown-item {
                border-top-color: transparent;
                border-radius: 0.5rem;
                background: rgba(22, 25, 58, 0.05);
                color: transparent;
                pointer-events: none;

                .text-success {
                    color: transparent !important;
                }
            }
        }
    }

    &.loading {
        user-select: none;

        .btn {
            background: rgba(22, 25, 58, 0.025);
            color: transparent;
            pointer-events: none;
            border-color: transparent;

            img {
                opacity: 0;
            }
        }
    }

}
</style>