import Vue from 'vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import App from './App.vue'
import {store} from "@/store";
import {router} from "@/router";
import VueCompositionAPI from '@vue/composition-api'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

export const ONBOARDING_URL = process.env.VUE_APP_ONBOARDING_URL;
export const PORTAL_URL = process.env.VUE_APP_PORTAL_URL;

Vue.use(VueCompositionAPI);

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_G_TAG,
  },
  includes: [
    {
      id: process.env.VUE_APP_ANALYTICS_TAG,
    }
  ],
});

new Vue({
  render: h => h(App),
  store: store,
  router,
}).$mount('#app')