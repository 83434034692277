<template>
	<div class="legal-wrapper">

		<!-- Mobile Header -->
		<MobileHeader title="Terms of Use" :back-link="true"/>

		<div class="container">
			<div class="legal-screen">
				<div class="legal-content">
					<!-- Desktop Screen Title -->
					<h1 class="screen-title mb-3 d-none d-md-block">Terms of Use</h1>
					<h2>Websites Terms of Use</h2>

					<p>These Terms of Use (&ldquo;Terms&rdquo;) govern your use of any Birgo Realty (&ldquo;Birgo&rdquo;, &ldquo;us&rdquo; &ldquo;we&rdquo;, and &ldquo;Company&rdquo;) websites (currently <a href="http://www.birgo.com">www.birgo.com</a> and <a href="http://www.reiturn.com">www.reiturn.com</a>) (the &ldquo;Sites&rdquo;). By accessing or using the Sites, you affirm that you have read, understood, and agree to these Terms. If you do not agree to these Terms, or are unable to comply with them, please do not use the Sites.</p>

					<p>Regardless of the device you use to access the Sites, your use of the Sites is subject to your compliance with these Terms, as well as any other written agreement between us and you. You also agree to comply with any additional rules, conditions, guidelines, or terms or conditions of use specific to the products or services provided by us or made available through the Sites.</p>

					<p>We may, in our sole discretion and in addition to any other remedies available to us, with or without cause or notice, withdraw, suspend, or discontinue at any time your use of the Sites or any of its functions, features, materials, or Content (defined below).</p>

					<p>We may update these Terms from time to time. Please check the Terms each time you access or use the Sites. If we make material changes to the Terms, we will post the revised Terms on the Sites noting the revised effective date. Your continued access to or use of the Sites after the effective date constitutes your acceptance of the new Terms.</p>

					<p>Your Personal Information (as that term is defined in our Privacy Policy) will be used and protected in accordance with our Privacy Policy [https://www.birgo.com/privacy-policy or https://www.reiturn.com/privacy-policy].</p>

					<h2>License and User Conduct</h2>

					<p>Birgo grants you a personal, revocable, non-transferable, non-exclusive limited license to access and use the Sites only for your personal purposes and strictly in accordance with these Terms. All rights not expressly granted in these Terms are reserved for Birgo.</p>

					<p>Your use of the Sites pursuant to these Terms is limited to (1) receiving information about our company, Sites, products, services, and business activities; (2) accessing the Sites; and (3) sign up for our newsletters or blogs through the Sites. You may access, download, and print Sites materials only as necessary to transact business with Birgo and receive information available on the Sites and, in doing so, you must retain any and all notices, trademarks, and other markings found on Sites materials.</p>

					<p>You agree that you will comply with these Terms and that you will not engage in conduct or communications that: (a) are obscene, fraudulent, indecent, defamatory, abusive harassing, or threatening to others, or negatively impacts others&rsquo; ability to use the Sites; (b) contain any viruses, malware, worms, time bombs, cancelbots, or other disabling devices, or other harmful components intended to, or that may, damage, detrimentally interfere with, surreptitiously intercept, or expropriate any system, data, or personal information; (c) advocate or encourage any illegal activity; (d) infringe on the copyright, patent, trademark, trade secret, right of publicity, or other intellectual property or proprietary right of any third party; (e) violate the privacy of individuals, including, but not limited to other users of the Sites; (f) engage in, assist, or encourage any conduct, activities, or communications that affect the operation, access, or usability of the Sites for any other authorized user; or (g) violate any applicable local, state, national, or international law(s).</p>

					<p>You also agree that you will not: (h) attempt to gain unauthorized access to any portion or feature of the Sites or our systems, networks, or servers by hacking, password &ldquo;mining&rdquo; or any other illegitimate means: (i) access, acquire, copy, monitor, circumvent, or create derivative works from any portion of our Sites, systems, networks, or servers to obtain or attempt to obtain any Content (defined below), materials, documents, or information through any means not purposely made available through the Sites; or (j) sell, share, provide access to, license, or distribute any such Content, materials, documents, or information. We reserve the right to bar any such activities or uses, in our sole discretion.</p>

					<p>Except where expressly permitted, you may not rent, lease, lend, sell, redistribute, or license the Content or the Sites or access to the same. You may not copy, adapt, distribute, attempt to derive the source code of, modify, or create derivative works of the Sites, any updates, or any part thereof except as expressly permitted herein.</p>

					<p>We may make improvements, changes, or amendments to the information, services, products, and other materials on the Sites, or terminate the Sites at any time in our sole discretion.</p>

					<p>The Sites that may be accessed from, displayed on, or linked to from your mobile device or PC is not available in all languages or in all countries. We make no representations that the Sites is appropriate or available for use in any particular location. To the extent you choose to access the Sites, you do so at your own initiative and are responsible for compliance with any applicable laws, including, but not limited to, applicable local laws. We reserve the right to change, suspend, remove, or disable access to the Sites at anytime without notice. In no event will we be liable for the removal or disabling of access to the Sites, whether temporary or permanent. We may also impose limits on the use of or access to the Sites, or portions thereof, in any case, for any reason, and without notice or liability.</p>

					<p>You must be 18 or older to access or utilize the Sites.</p>

					<h2>Information You Provide</h2>

					<p>You agree that all information you provide to us will be true, accurate, current, and complete. You represent and warrant that you have the legal right to provide it, and that it does not violate any third party&rsquo;s intellectual property or privacy rights. Any information or materials you provide us via the Sites or otherwise, including feedback, responses to questions, comments, documents, photographs, suggestions, or the like will be deemed to be non-confidential and owned exclusively by Birgo. We will have no obligation of any kind with respect to such information. By uploading, sending, posting, or otherwise providing any information or material, you grant Birgo an unrestricted, irrevocable, worldwide, non-exclusive license to use, reproduce, display, perform, modify, transmit and distribute it. You agree that Birgo is free to use any ideas, concepts, know-how or techniques that you provide us for any purpose in its sole discretion, including for use in creating derivative works.</p>

					<p>You agree that we may collect and use technical data and related information, including but not limited to, technical information about the device you use to access the Sites. We may, in our legitimate interest in maintaining the Sites, also collect information about the system and application software you use as well as information about device peripherals you employ. And we may collect information about your activities on the Sites, like the duration and frequency of your usage sessions, information regarding your browsing history, whether you are a first-time or a returning user, links you clicked on while on the Sites, and your flow and navigation path through the Sites. This information is gathered periodically and facilitates the development of software updates, the provision of product support, and with the delivery of other services and products to you (if any). We may use this information, as long as its in a form that does not personally identify you, to improve our products or to provide services, Content or technologies to you. More information on the collection and use of this information, as well as other information we may collect from you through the Sites can be found in our Privacy Policy [https://www.birgo.com/privacy-policy or https://www.reiturn.com/privacy-policy].</p>

					<h2>Third Party Sites and Information</h2>

					<p>Our Sites may have links to other websites or it may refer to information, documents, software, materials, and/or services provided by other parties. We have no control over these websites or resources, nor do we sponsor or endorse them by implication. You agree that Birgo is not responsible or liable for any content, advertising, or other materials available through these third-party websites and resources, and that we are not responsible or liable, directly or indirectly, for any damage or loss caused to you by your use of or reliance on any content, goods, or services available through a third-party websites or internet resource.</p>

					<p>If you link to our Sites, you agree, pursuant to these Terms, to remove and/or disable such link should we so demand.</p>

					<h2>Indemnification</h2>

					<p>You agree to defend, indemnify, and hold Birgo and its affiliates, partners, agents, and subsidiaries, harmless from all liabilities, claims, and expenses, including attorneys&rsquo; fees that arise from your use or misuse of the Sites or the Content, or by your conduct that would constitute a breach of any of these Terms by you. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event, you will cooperate with us in asserting any available defenses.</p>

					<h2>Intellectual Property</h2>

					<p>The entire content of the Sites, including, but not limited to, text, graphics, data, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork, other original works, and computer code, and the design, structure, selection, coordination, expression, &ldquo;look and feel&rdquo; and arrangement of such content (collectively, &ldquo;Content&rdquo;) is protected by U.S. and international copyright, trademark, and other intellectual property laws and is owned, controlled, or licensed by or to Birgo. Your use of the Sites does not grant you any rights to the Sites or any of the Content.</p>

					<p>The Sites and its Content, except for those aspects which may be in the public domain, are protected from unauthorized copying and dissemination by U.S. and international laws and conventions, including those relating to copyright, trademark, trade dress, unfair competition, and intellectual property.</p>

					<p>All trademarks, service marks, logos, taglines, copyrightable works, and trade dress depicted on the Sites are owned, controlled or licensed by Birgo, licensed to Birgo, or are the property of their respective owners. You agree not to reproduce, imitate, alter, display, distribute, or use such intellectual property, in whole or in part, in any manner, without the prior written permission of Birgo.</p>

					<p>Subject to your compliance with these Terms, Birgo grants you a limited, non-exclusive license to access and use portions of the Sites, provided that your access and use is lawful and non-commercial and that you do not: reproduce, distribute, or use Content without proper authorization; change or delate any proprietary notices from downloaded or printed materials; copy or post Content on any networked computer or device; broadcast it in any media, including social media; or make any representations or warranties relating to such Content.</p>

					<p>Other than this limited permission, you may not copy, reproduce, republish, upload, post, publicly display, encode, translate, transmit, or distribute, in any way (including &ldquo;mirroring&rdquo;) to any other computer, server, device, websites, medium or commercial enterprise, any part of the Sites or any Content without our express prior written consent.</p>

					<p>Nothing on the Sites should be construed as granting, by implication, estoppel, or otherwise, any license or right to use the Sites or its Content except as permitted or consented to by these Terms.</p>

					<h2>Digital Millennium Copyright Act Notice</h2>

					<p>Birgo respects the intellectual property rights of others and users of the Sites are expected to do the same. We will investigate reports of alleged infringement and will take appropriate action to remove or disable access to any material found likely to be infringing.</p>

					<p>If you believe our Sites (or any portion of the Sites) infringes upon your copyrights, please provide the following information:</p>

					<ul>
						<li>A physical or electronic signature of the person authorized to act on behalf of the owner of an exclusive copyright that has allegedly been infringed;&nbsp;</li>
						<li>A description of the copyrighted work that you believe has been infringed;</li>
						<li>The specific location on the Sites of this allegedly infringing material;</li>
						<li>Your address, telephone number, and email address and any other pertinent information sufficient to allow Birgo to contact you about the allegation;</li>
						<li>A statement by you that you have a good faith belief that the disputed use is not authorized by copyright owners, its agent, or the law; and</li>
						<li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or are authorized to act on the copyright owner&rsquo;s behalf.</li>
					</ul>

					<p>Notices of claimed copyright infringement should be directed to:</p>

					<p>By mail: Birgo, CLAIM OF COPYRIGHT INFRINGEMENT, Legal Dept, 848 W. North Avenue, Pittsburgh, PA 15233</p>

					<p>By email: dmca@birgo.com<a href="#_msocom_1" id="_anchor_1" name="_msoanchor_1">[WW1]</a>&nbsp;</p>

					<h2>No Warranties</h2>

					<p>THE SITES AND ITS CONTENT ARE FOR GENERAL INFORMATION ONLY AND ARE PROVIDED &ldquo;AS IS&rdquo; WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND.</p>

					<p>WE DO NOT WARRANT THAT YOUR USE OF THE SITES WILL BE UNINTERRUPTED OR WITHOUT PROBLEMS OR ERRORS, THAT DEFECTS IN OR ON THE SITES WILL BE APPARENT OR CORRECTED, OR THAT THE SITES OR THE SERVERS THAT MAKE THE SITES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME THE ENTIRE RISK OF ANY RELIANCE ON THE SITES OR ITS CONTENT AND OF ANY ACCESS TO OR USE OF THE SITES AND ITS CONTENT.</p>

					<p>WE DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS REGARDING THE SITES AND ITS CONTENT, WHETHER EXPRESS OR IMPLIED, WRITTEN OR NOT, INCLUDING WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY, REASONABLE CARE, SECURITY, QUALITY, TIMELINESS, AVAILABILITY, COMPLETENESS, RELIABILITY, ACCURACY, AND/OR FITNESS FOR A PARTICULAR PURPOSE (WHETHER OR NOT WE ARE IN FACT AWARE OF ANY SUCH PURPOSE).</p>

					<p>WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF TITLE AND/OR NON-INFRINGEMENT WITH RESPECT TO THE SITES AND ITS CONTENT.</p>

					<h2>Limitation Of Liability</h2>

					<p>UNDER NO CIRCUMSTANCES SHALL BIRGO, ITS AFFILIATES, ITS SUBSIDIARIES, ITS RELATED COMPANIES, OR ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, SPONSORS, OR AGENTS BE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES ARISIN GOUT OF OR RELATING IN ANY WAY TO THE SITES, OR INFORMATION CONTAINED WITHIN THE SITES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILTY OF SUCH DAMAGES. THIS LIMITATION APPLIES WHETHER THE DAMAGES ARE BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE.</p>

					<p>YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITES IS TO STOP USING THE SITES. NOTWITHSTANDING THESE LIMITATIONS, ANY LIABILITY UNDER THESE TERMS OF USE SHALL BE LIMITED TO THE COST INCURRED BY YOUR USE OF THE SITES, IF ANY, OR $500, WHICHEVER IS LESS.</p>

					<p>SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY IN WHOLE OR IN PART TO YOU.</p>

					<p>Any claim under these Terms must be brought within one (1) year after the cause of action arises or such claim or cause of action is barred. Any claim brought by you is subject to the Limitation of Liability set forth above.</p>

					<h2>Arbitration Agreement</h2>

					<p>In the unlikely event there is a dispute between you and us related to our Sites or these Terms of Service, this Section provides for how that dispute will be resolved.</p>

					<p>PLEASE READ THIS SECTION CAREFULLY. IT PROVIDES THAT ANY DISPUTE RELATING TO OUR SITES OR THESE TERMS OF SERVICE SHALL BE RESOLVED BY BINDING ARBITRATION. IN ARBITRATION, NEITHER PARTY WILL HAVE THE RIGHT TO LITIGATE DISPUTES IN COURT, OR TO HAVE A JURY TRIAL ON THE DISPUTES.</p>

					<p>ANY DISPUTE SHALL BE ARBITRATED ON AN INDIVIDUAL BASIS. THIS MEANS THAT ALL PARTIES TO THE ARBITRATION ARE PROHIBITED FROM JOINING OR CONSOLIDATING DISPUTES IN ARBITRATION BY OR AGAINST OTHERS AND ARE PROHIBITED FROM ARBITRATING ANY DISPUTES AS A REPRESENTATIVE OR MEMBER OF A CLASS. PRE-HEARING DISCOVERY RIGHTS AND POST-HEARING APPEAL RIGHTS WILL BE LIMITED.</p>

					<p>Any dispute, claim or controversy (each a &ldquo;Dispute&rdquo;) arising out of or relating to our Sites or these Terms of Service and involving you and us will be resolved exclusively by binding arbitration. You acknowledge and agree that you (and us too) are waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding. However, you (and us too) retain the right to injunctive relief in a court of competent jurisdiction.</p>

					<p>If you intend to seek arbitration, you must send us a written notice (&ldquo;Notice of Dispute&rdquo;) via courier or certified or registered mail. The Notice of Dispute must be sent to: Birgo Realty, Attn: Legal Department, 848 W. North Avenue, Pittsburgh, PA 15233. The Notice of Dispute shall describe the nature and basis of the dispute and the specific relief sought. If the parties cannot reach an agreement to resolve the dispute within thirty (30) days after the Notice of Dispute is received, either party may commence arbitration.</p>

					<p>The arbitration will be administered by JAMS pursuant to its Comprehensive Arbitration Rules and Procedures then in effect, except as modified by the terms of this arbitration provision. The arbitration will take place before one arbitrator, who shall be mutually selected by the parties. If the parties cannot agree on an arbitrator, the arbitrator will be appointed according to JAMS procedures.</p>

					<p>Each party will be responsible for its own attorney fees and costs, unless awarded by the arbitrator under applicable law. The party that initiates the arbitration will pay the filing fee (unless otherwise dictated by JAMS due to a finding of hardship). We will advance all other administration, case management, and arbitrator fees associated with the arbitration, through payment directly to JAMS. At the end of the arbitration, the fees paid by us may be allocated between you and us at the direction of the arbitrator in compliance with JAMS procedures.</p>

					<p>All issues are for the arbitrator to decide, including issues relating to the scope and enforceability of this arbitration provision. Any decision of the arbitrator shall be final. Judgment on the award may be entered in any court having jurisdiction. This clause shall not preclude you or us from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction. Your and our liability is limited as described in these Terms of Service, to the fullest extent permitted by applicable law. The arbitrator cannot award punitive or exemplary damages (except as may be required by statute), any pre-award interest, or incidental, indirect or consequential damages, including damages for lost profits, or harm suffered by third parties.</p>

					<p>If any clause within this arbitration provision is found to be illegal or unenforceable, that clause will be severed from this arbitration provision, and the remaining terms will be given full force and effect.</p>

					<h2>Governing Law and Jurisdiction</h2>

					<p>These Terms and the resolution of any dispute related to these Terms, the Sites, or the Content, will be governed by and construed according to the laws of Pennsylvania, without regard for its conflicts of laws principles. Any action to enforce these Terms or a matter or dispute arising out of the Sites or the Content will be held in and subject to the exclusive jurisdiction and venue of the federal and state courts located in Pittsburgh, PA or the Western District of Pennsylvania. The parties consent to personal jurisdiction in the state courts located in Pittsburg, PA and the federal courts located in Western District of Pennsylvania.</p>

					<p>Failure by Birgo to insist upon strict enforcement of any provision of these Terms will not be construed as a waiver of any provision or right held by Birgo.</p>

					<p>If any of these Terms are deemed invalid, void, or unenforceable, that term will be deemed severable and will not affect the validity and enforceability of any remaining term.</p>

					<p>You may not assign or transfer your rights or obligations under the Terms, by operation of law or otherwise, without Birgo&rsquo;s prior written consent. Any attempt by you to assign or transfer you rights or obligations under these Terms, without such consent, will be null and void and of no effect. Birgo may assign or transfer its rights and obligations under these Terms, at its sole discretion, without restriction. Subject to the foregoing, the rights and obligations of the parties under these Terms, where properly assigned, shall bind and inure to the benefit of the parties, their successors, and permitted assigns.</p>

					<p>&nbsp;</p>

					<p>Last modified: July 12, 2022.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader.vue";
export default {
    name: "TermsOfUse",
    components: {MobileHeader},
}
</script>

<style lang="scss">
</style>