<template>
	<div class="screen">

		<!-- Mobile Header -->
		<MobileHeader :dark="false" :menu-toggle="true" title="Growth Calculator"/>

		<div class="container-fluid">

			<!-- Desktop Screen Title -->
			<h3 class="screen-title mb-3 d-none d-md-block">Growth Calculator</h3>

			<form @submit.prevent="calculateGrowth" :class="{'calculator-box': true, 'loading': !loaded}">
				<div class="row">
					<div class="col-xl-6 col-xxl-7 order-xl-2">
						<h5>Hypothetical Account Value</h5>
						<div class="calculator-value"><strong>${{ total.toLocaleString() }}</strong> at age {{ targetAge }}</div>
						<div class="calculator-breakdown">
							<div class="breakdown-item">
								<span>Invested</span>
								<strong>${{ invested.toLocaleString() }}</strong>
							</div>
							<div class="breakdown-item">
								<span>Returns</span>
								<strong>${{ returns.toLocaleString() }}</strong>
							</div>
						</div>
						<div class="calculator-yearly">
							<div :class="{'year-item': true, 'selected': targetAge === item.age}" v-for="(item, index) in totalYearly" :key="index" @click.prevent="setTargetAge(item.age)">
								<div class="year-info">
									<span>{{ item.age }} years old</span>
									<strong>${{ item.total.toLocaleString() }}</strong>
									<i>${{ item.invested.toLocaleString() }}</i>
								</div>
								<div class="year-invested" :style="{height: calculateInvestedPercentage(item, totalYearly) + '%'}"></div>
								<div class="year-total" :style="{height: calculateTotalPercentage(item, totalYearly) + '%'}"></div>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-xxl-5 order-xl-1 mt-5 mt-xl-0">
						<div class="calculator-sidebar">
							<div class="form-field mb-4">
								<label for="initial" class="form-label">Initial Investment</label>
								<CurrencyInput id="initial" placeholder="Enter amount" v-model="initialInvestment"/>
							</div>
							<div class="form-field mb-4">
								<label for="monthly" class="form-label">Monthly Investment</label>
								<CurrencyInput id="monthly" placeholder="Enter amount" v-model="monthlyInvestment"/>
							</div>
							<div class="calculator-details mb-4">
								<h4>How is this calculated?</h4>
								<div class="details-item">
									<span>Interest Rate Estimation</span>
									<strong>{{ interestRate }}% per year</strong>
								</div>
								<p class="calculator-disclaimer">The chart is based on an average annual rate of return of {{ interestRate }}%. However, there is no guarantee that the illustrated projection will be achieved, or that the selected monthly investment will achieve this rate of return. Hypothetical growth of investment presented for illustrative purposes only.</p>
							</div>
							<router-link to="/invest" class="btn btn-primary d-block w-100">Set up auto-investment</router-link>
						</div>
					</div>
				</div>
			</form>

		</div>
	</div>
</template>

<script>
import repository from "@/repository/repository";
import MobileHeader from "@/components/MobileHeader.vue";
import CurrencyInput from "@/components/widgets/CurrencyInput.vue";

export default {
    name: "Calculator",
	components: {CurrencyInput, MobileHeader},
	data() {
		return {
			initialInvestment: 25000,
			monthlyInvestment: 500,
			currentAge: 18,
			targetAge: 60,
			maxAge: 70,
			interestRate: 9,
			loaded: false,
		}
    },
	created() {
		this.loadPartyDetails();
	},
	methods: {
		loadPartyDetails() {
			repository.get("/me/party")
				.then(response => {
					this.currentAge = response.data.age;
					if(this.currentAge > 45) {
						this.maxAge = 85;
						this.targetAge = 70;
					}
					this.loaded = true;
				})
		},
		calculateGrowth() {
		},
		calculateInvestedPercentage(item, items) {
			return ((100 / items[items.length - 1].total) * item.invested);
		},
		calculateTotalPercentage(item, items) {
			return ((100 / items[items.length - 1].total) * item.total);
		},
		setTargetAge(age) {
			this.targetAge = age;
		},
	},
	computed: {
		total() {
			let total = this.initialInvestment;
			let rate = this.interestRate / 100;
			let monthsPeriod = (this.targetAge - this.currentAge) * 12;
			for(let i = 1;i <= monthsPeriod;i++) {
				total += this.monthlyInvestment;
				total += total * (rate / 12);
			}
			return Math.round(total);
		},
		invested() {
			return (this.monthlyInvestment * ((this.targetAge - this.currentAge) * 12)) + this.initialInvestment;
		},
		returns() {
			return Math.round(this.total - this.invested);
		},
		totalYearly() {
			let years = [];
			let total = this.initialInvestment;
			let invested = this.initialInvestment;
			let rate = this.interestRate / 100;
			for(let age = this.currentAge + 1;age <= this.maxAge;age++) {
				for(let i = 1;i <= 12;i++) {
					invested += this.monthlyInvestment;
					total += this.monthlyInvestment;
					total += total * (rate / 12);
				}
				years.push({
					age: age,
					total: Math.round(total),
					invested: Math.round(invested),
				});
			}
			return years;
		},
	},
}
</script>

<style lang="scss">
.calculator-box {

	.calculator-sidebar {
		position: relative;
		padding: 2.5rem;
		background: #16193A;
		border-radius: 0.5rem;

		@media (max-width: 767px) {
			width: calc(100% + 3rem);
			margin: 0 -1.5rem;
			border-radius: 0;
			padding: 2.5rem 1.5rem;
		}

		.form-field {
			.form-label {
				color: rgba(#fff, 0.45);
				font-weight: 600;
				font-size: 1rem;
			}

			.form-control {
				background: none;
				border-bottom: none;
				font-size: 2rem;
				line-height: 1;
				padding: 0;
				color: #fff;
				transition: 0.15s ease all;

				&::-webkit-input-placeholder {
					color: rgba(#fff, 0.15);
				}
				&::-moz-placeholder {
					color: rgba(#fff, 0.15);
				}
				&:-ms-input-placeholder {
					color: rgba(#fff, 0.15);
				}
				&:-moz-placeholder {
					color: rgba(#fff, 0.15);
				}

				&:focus {
					background: rgba(#fff, 0.05);
				}
			}
		}

		.calculator-details {
			padding: 2.5rem 0 0 0;
			margin-top: 2.5rem;
			border-top: 1px solid rgba(#fff, 0.1);

			h4 {
				font-weight: bold;
				font-size: 1.25rem;
				color: #fff;
			}

			.details-item {
				display: block;
				margin: 1rem 0;

				span {
					display: block;
					font-size: 0.9rem;
					color: rgba(#fff, 0.45);
				}

				strong {
					display: block;
					color: #fff;
					font-size: 1rem;
				}
			}

			p {
				font-size: 0.8rem;
				color: rgba(#fff, 0.25);
				line-height: 1.4;
			}
		}
	}

	h5 {
		font-weight: 600;
		color: rgba(#16193A, 0.75);
		margin: 0;
		font-size: 0.9rem;
	}

	.calculator-value {
		display: inline-block;
		margin: 0.5rem 0;
		color: #16193A;
		font-size: 2.5rem;
		font-weight: 400;

		@media (max-width: 767px) {
			font-size: 1.75rem;
		}

		strong {
			font-weight: bold;
		}
	}

	.calculator-breakdown {
		display: flex;

		.breakdown-item {
			display: block;
			margin-right: 1.5rem;

			span {
				display: block;
				color: rgba(#16193A, 0.75);
				font-size: 0.9rem;
			}

			strong {
				display: block;
				color: #16193A;
			}
		}
	}

	.calculator-yearly {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-top: -5rem;
		height: calc(100% - 3.68rem);

		@media (max-width: 1199.99px) {
			height: 15rem;
		}

		@media (max-width: 767px) {
			margin-top: -2rem;
			height: 15rem;
		}

		&:not(:hover) {
			.year-item.selected {
				background: rgba(#16193A, 0.025);

				.year-total {
					background: rgba(#16193A, 0.15);
				}

				.year-info {
					opacity: 1;
					visibility: visible;
					transform: translateX(0);
				}
			}
		}

		.year-item {
			width: 100%;
			margin: 0 3px;
			min-height: 100%;
			border-radius: 2rem;
			position: relative;
			cursor: pointer;
			transition: 0.15s ease all;

			@media (min-width: 1200px) and (max-width: 1399.99px) {
				margin: 0 2px;
			}

			@media (max-width: 767px) {
				margin: 0 1px;
			}

			&:hover {
				background: rgba(#16193A, 0.025);

				.year-total {
					background: rgba(#16193A, 0.15);
				}

				.year-info {
					opacity: 1;
					visibility: visible;
					transform: translateX(0);
				}
			}

			&.selected {
				.year-total {
					&:before {
						position: absolute;
						top: -0.75rem;
						left: 0;
						right: 0;
						margin: auto;
						width: 0.5rem;
						height: 0.5rem;
						border-radius: 50%;
						content: ' ';
						background: #F37021;

						@media (max-width: 767px) {
							top: -0.5rem;
							width: 0.3rem;
							height: 0.3rem;
						}
					}
				}
			}

			.year-total {
				background: rgba(#16193A, 0.075);
				border-radius: 2rem;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				z-index: 0;
				transition: 0.15s ease all;
			}

			.year-invested {
				background: #F37021;
				border-radius: 2rem;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				z-index: 1;
				transition: 0.15s ease all;
			}

			.year-info {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 150%;
				margin: auto;
				width: 10rem;
				height: 8.5rem;
				background: #fff;
				box-shadow: rgba(#16193A, 0.15) 0 0.1rem 0.25rem;
				padding: 0.5rem;
				opacity: 0;
				visibility: hidden;
				transition: 0.15s ease all;
				border-radius: 0.5rem;
				z-index: 2;
				transform: translateX(-0.5rem);

				span {
					display: block;
					color: #16193A;
					font-size: 0.9rem;
					font-weight: bold;
				}

				strong {
					display: block;
					color: #F37021;
					font-size: 1.25rem;
					line-height: 1.2;
					margin: 0.5rem 0;

					&:before {
						display: block;
						color: rgba(#16193A, 0.5);
						font-size: 0.8rem;
						content: 'Total:';
						font-weight: 400;
					}
				}

				i {
					font-weight: bold;
					color: #16193A;
					font-size: 0.9rem;
					font-style: normal;
					line-height: 1.2;

					&:before {
						display: block;
						color: rgba(#16193A, 0.5);
						font-size: 0.8rem;
						content: 'Total Invested:';
						font-weight: 400;
					}
				}
			}
		}
	}

	&.loading {

		* {
			user-select: none;
			pointer-events: none;
		}

		.calculator-value {
			color: transparent;
			background: rgba(#161931, 0.1);
			border-radius: 0.5rem;
			animation: loading-pulse 0.5s infinite;
		}

		.calculator-breakdown {
			.breakdown-item {
				color: transparent;
				background: rgba(#161931, 0.1);
				border-radius: 0.5rem;
				animation: loading-pulse 0.5s infinite;

				&:nth-child(2) {
					transform: translateX(-1rem);
				}

				strong, span {
					color: transparent;
				}
			}
		}

		.calculator-yearly {
			.year-item {
				color: transparent;
				background: transparent !important;
				user-select: none;
				pointer-events: none;

				.year-total,
				.year-invested,
				.year-info {
					opacity: 0 !important;
				}
			}
		}
	}
}
</style>