<template>
	<div class="invest-now auto-investment-reminder">
        <img src="@/assets/auto-investing.jpg" alt="Auto-Investing">
        <div v-if="autoInvestment.frequency === 'Disabled'">
            <h4>Set-up Auto Investing</h4>
            <p>Put your hard earned money to work by investing in multifamily properties on a regular basis.</p>
            <router-link to="/invest" class="btn btn-secondary d-inline-flex align-items-center mt-4">
                <img src="@/assets/icons/refresh.svg" alt="Invest" class="btn-icon icon-force-white">
                <span>Setup Auto Investing</span>
            </router-link>
        </div>
        <div v-if="autoInvestment.frequency !== 'Disabled'">
            <h4>Upcoming Investments</h4>
            <p>Your upcoming automatic investment of <strong>${{ autoInvestment.amount }}</strong> is scheduled for processing on <strong>{{ nextInvestmentDate }}</strong>. Thank you!</p>
            <router-link to="/invest" class="btn btn-secondary d-inline-flex align-items-center mt-4 disabled" disabled="disabled">
                <img src="@/assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
                <span>Invest Now</span>
            </router-link>
        </div>
	</div>
</template>

<script>
import {getNextDate} from "@/helper";

export default {
    name: "AutoInvestmentReminder",
    computed: {
        autoInvestment() {
            return this.$store.getters.autoInvestment;
        },
        nextInvestmentDate() {
            if(this.autoInvestment.frequency === 'Disabled') return null;
            return getNextDate(this.autoInvestment.startDay);
        },
    },
}
</script>

<style lang="scss" scoped>
.invest-now.auto-investment-reminder {

    @media (max-width: 767.99px) {
        border-top: 1px solid #eee;
        padding: 2.5rem 1.5rem;
    }

    h4 {
        margin-top: 0;
    }

    > img {
        flex-shrink: 0;
        margin-right: 2.5rem;
        width: 6rem;
    }

    @media (max-width: 767.99px) {
        margin-bottom: 0;
        padding-bottom: 2.5rem;
        height: auto !important;
    }
}
</style>