<template>
	<div class="action-box-wrapper">

		<!-- Inner Link -->
		<router-link :to="disabled ? '/' : link" :class="{'action-box': true, 'coming-soon': disabled}" v-if="!external && !openModal">
			<img :src="require('@/assets/icons/' + icon)" :alt="title">
			<strong>{{ title }}</strong>
		</router-link>

		<!-- External Link -->
		<a :href="disabled ? '/' : link" :class="{'action-box': true, 'coming-soon': disabled}" v-if="external && !openModal">
			<img :src="require('@/assets/icons/' + icon)" :alt="title">
			<strong>{{ title }}</strong>
		</a>

		<!-- In Modal -->
		<a @click.prevent="triggerModal" :href="disabled ? '/' : link" :class="{'action-box': true, 'coming-soon': disabled}" v-if="openModal">
			<img :src="require('@/assets/icons/' + icon)" :alt="title">
			<strong>{{ title }}</strong>
		</a>
	</div>
</template>

<script>
export default {
    name: "ActionBox",
	props: ['title', 'link', 'icon', 'disabled', 'external', 'openModal'],
	methods: {
		triggerModal() {
			if(this.openModal) {
				this.openModal(this.link);
			}
		}
	},
}
</script>

<style lang="scss">
.action-box {
	border-radius: 0.5rem;
	background: #fff;
	padding: 1.5rem;
	width: 12rem;
	height: 12rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	position: relative;
	display: flex;
	flex-direction: column;
	text-decoration: none;
	color: #16193A;
	transition: 0.15s ease all;
	flex-shrink: 0;

	&:hover {
		color: #F37021;
		box-shadow: rgba(10,10,10,0.1) 0 0.2rem 0.5rem;
	}

	&:active {
		transform-origin: center;
		transform: scale(0.975);
	}

	img {
		width: 3rem;
		height: 3rem;
		display: block;
		margin-bottom: auto;
	}

	strong {
		display: block;
		line-height: 1.2;
	}

	&.coming-soon {
		position: relative;

		&:after {
			font-weight: 600;
			font-size: 0.7rem;
			content: 'Coming Soon';
			color: #999;
			margin-top: 0.5rem;
		}
	}
}
</style>