<template>
	<div class="portfolio-overview">

        <!-- Portfolio Box -->
        <div class="portfolio-box">
            <div class="row align-items-center">
                <div class="col-lg-5 me-auto">
                    <h2>Our Portfolio</h2>
                    <h3>Invest in Real Estate That Does Good in the World.</h3>
                    <p>By measuring our impact, we make informed decisions, focusing our resources on the highest priorities.</p>
                    <router-link to="/portfolio" class="btn btn-primary">View All Properties</router-link>
                </div>
                <div class="col-lg-7 mt-4 mt-lg-0">
                    <div class="properties-wrapper" v-if="!properties.loaded">
                        <div class="property-column">
                            <PropertyBox :loading="true"/>
                        </div>
                        <div class="property-column">
                            <PropertyBox :loading="true"/>
                        </div>
                        <div class="property-column">
                            <PropertyBox :loading="true"/>
                        </div>
                    </div>
                    <div class="properties-wrapper" v-if="properties.loaded">
                        <div class="property-column" v-for="(property, key) in selectedProperties" :key="key">
                            <PropertyBox :property="property" :home="true" :openPopup="openPropertyPopup"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Property Popup -->
        <Popup :class="{'active': modal !== null}" size="large" ref="propertyPopup">
            <PropertyDetails :propertyId="modal"/>
        </Popup>

	</div>
</template>

<script>
import repository from "@/repository/repository";
import Popup from "@/components/widgets/Popup.vue";
import PropertyDetails from "@/components/widgets/PropertyDetails.vue";
import PropertyBox from "@/components/widgets/PropertyBox.vue";

export default {
    name: "PortfolioOverview",
    components: {PropertyBox, PropertyDetails, Popup},
    data() {
        return {
            properties: {
                items: [],
                loaded: false,
            },
            modal: null,
        }
    },
    created() {
        // Load properties
        this.loadProperties();

        // Close modal on click
        let app = this;
        window.addEventListener('popstate', function () {
            app.modal = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    methods: {
        loadProperties() {
            this.properties.loaded = false;
            repository.get("/properties")
                .then(response => {
                    this.properties.items = response.data.properties;
                    this.properties.loaded = true;
                });
        },
        openPropertyPopup(propertyId) {
            if(this.isMobile()) {
                this.$router.push('/portfolio/' + propertyId);
            } else {
                history.pushState({page: 1}, "", '/portfolio/' + propertyId);
                document.documentElement.style.overflow = "hidden";
                this.modal = propertyId;
                this.$refs.propertyPopup.$el.scrollTop = 0;
            }
        },
        isMobile() {
            return screen.width <= 760;
        },
    },
    computed: {
        selectedProperties() {
            return this.properties.items.slice(0, 3);
        }
    },
}
</script>

<style lang="scss" scoped>
.portfolio-box {
    padding: 2.5rem 0 2.5rem 4rem;
    background: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;

    @media (max-width: 767.99px) {
        padding: 2.5rem 1.5rem;
        width: calc(100% + 3rem);
        margin: 0 -1.5rem;
    }

    @media (min-width: 768px) and (max-width: 991.99px) {
        padding: 2.5rem;
    }

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 20%;
        height: 100%;
        background: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 1));
        content: ' ';
        z-index: 1;

        @media (max-width: 767.99px) {
            display: none;
        }

        @media (min-width: 768px) and (max-width: 991.99px) {
            display: none;
        }
    }

    h2 {
        font-size: 1rem;
        font-weight: bold;
        color: #F37021;
    }

    h3 {
        font-size: 2rem;
        font-weight: bold;
        color: #16193A;
    }

    .properties-wrapper {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        position: relative;

        @media (max-width: 767.99px) {
            &:after {
                position: absolute;
                top: 0;
                right: -1.5rem;
                bottom: 0;
                width: 30%;
                height: 100%;
                background: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 1));
                content: ' ';
                z-index: 1;
            }
        }

        @media (min-width: 768px) and (max-width: 991.99px) {
            &:after {
                position: absolute;
                top: 0;
                right: -2.5rem;
                bottom: 0;
                width: 30%;
                height: 100%;
                background: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 1));
                content: ' ';
                z-index: 1;
            }
        }

        .property-column {
            width: 250px;
            padding-left: 1rem;
            flex-shrink: 0;

            @media (max-width: 767.99px) {
                padding-left: 0;
                padding-right: 1rem;
                width: 200px;
            }

            @media (min-width: 768px) and (max-width: 991.99px) {
                padding-left: 0;
                padding-right: 1rem;
                width: 200px;
            }
        }
    }
}
</style>