<template>
	<div class="screen">

		<!-- Mobile Header -->
		<MobileHeader :dark="false" :menu-toggle="true" title="Your Transactions"/>

		<div class="container-fluid">

			<!-- Desktop Screen Title -->
			<h3 class="screen-title mb-3 d-none d-md-block">Your Transactions</h3>

			<div class="row">
				<div class="col-xxl-8 mb-4">

					<!-- Transactions -->
					<div class="transactions-box h-100">
						<div class="row">
                            <div class="col-6 col-lg-3 mb-4">
                                <div :class="{'transactions-summary': true, 'loading': !investments.loaded}">
                                    <span>Account Value <Explanation title="Your current account value."/></span>
                                    <strong class="text-success">${{ investments.loaded ? accountValue.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3 mb-4">
                                <div :class="{'transactions-summary': true, 'loading': !investments.loaded}">
                                    <span>Funded <Explanation title="Funds that you have invested, and have been received in our escrow account."/></span>
                                    <strong>${{ investments.loaded ? investments.cleared.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3 mb-4" v-if="investments.manually_refunded > 0">
                                <div :class="{'transactions-summary': true, 'loading': !investments.loaded}">
                                    <span>Returned Investments <Explanation title="Funds that have been manually refunded to your bank account by our team."/></span>
                                    <strong class="text-danger">${{ investments.loaded ? investments.manually_refunded.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
                                </div>
                            </div>
							<div class="col-6 col-lg-3 mb-4">
								<div :class="{'transactions-summary': true, 'loading': !investments.loaded}">
									<span>Pending Funds <Explanation title="Funds that you have invested, but they have not yet been received."/></span>
									<strong>${{ investments.loaded ? investments.pending.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
								</div>
							</div>
							<div class="col-6 col-lg-3 mb-4">
								<div :class="{'transactions-summary': true, 'loading': !investments.loaded}">
									<span>Reinvested <Explanation title="Funds that you've earned and which have been reinvested."/></span>
									<strong>${{ investments.loaded ? investments.reinvested.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
								</div>
							</div>
							<div class="col-6 col-lg-3 mb-4">
								<div :class="{'transactions-summary': true, 'loading': !investments.loaded}">
									<span>Distributed <Explanation title="Funds that you have earned and that have been distributed to your bank account."/></span>
									<strong>${{ investments.loaded ? investments.distributed.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
								</div>
							</div>
							<div class="col-6 col-lg-3 mb-4" v-if="investments.loaded && investments.cancelled > 0">
								<div :class="{'transactions-summary': true, 'loading': !investments.loaded}">
									<span>Cancelled <Explanation title="Funds that you requested to be refunded, but the request is still pending."/></span>
									<strong>${{ investments.loaded ? investments.cancelled.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
								</div>
							</div>
							<div class="col-6 col-lg-3 mb-4" v-if="investments.loaded && investments.refunded > 0">
								<div :class="{'transactions-summary': true, 'loading': !investments.loaded}">
									<span>Refunded <Explanation title="Funds that have been refunded to your account."/></span>
									<strong>${{ investments.loaded ? investments.refunded.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
								</div>
							</div>
						</div>

                        <!-- Investments -->
                        <div class="investments-wrapper">
                            <hr class="mt-2">

                            <h5>Investments</h5>

                            <!-- Loading -->
                            <div class="spinner-border text-black-50 opacity-25" v-if="!transactions.loaded"></div>

                            <!-- Error -->
                            <div class="transactions-error" v-if="transactions.loaded && transactions.error">
                                <img src="../assets/icons/cancel.png" alt="Error" class="error-icon">
                                <p>We couldn't load your transactions. Please try again by refreshing the page.</p>
                            </div>

                            <!-- Transactions List -->
                            <div v-if="transactions.loaded && !transactions.error">
                                <div v-if="transactions.items.length === 0">
                                    <p>Once you invest, you will see all of your investments & transactions here.</p>
                                </div>
                                <div v-for="(transaction, key) in transactions.items" :key="key" class="transaction-item">
                                    <div class="transaction-amount">
                                        <strong>${{ transaction.amount.toLocaleString() }}</strong>
                                        <span>{{ transaction.payment_method }}</span>
                                        <AutoInvestmentExplanation v-if="transaction.auto_investment === 1" class="ms-1"></AutoInvestmentExplanation>
                                    </div>
                                    <div class="transaction-date">
                                        <span class="transaction-date-day">{{ transaction.date.day }}</span>
                                        <span class="transaction-date-month">{{ transaction.date.month }}</span>
                                        <span class="transaction-date-year d-none d-md-inline">{{ transaction.date.year }}</span>
                                    </div>
                                    <div class="transaction-status">
                                        <!-- Pending -->
                                        <div class="badge bg-secondary" v-if="transaction.status === 'CREATED'">Pending</div>

                                        <!-- Funded & Completed -->
                                        <div v-if="transaction.status === 'FUNDED' || transaction.status === 'SETTLED'" class="d-flex justify-content-center flex-column flex-md-row">
                                            <div class="badge bg-primary" v-if="!transaction.docu_sign">Funded</div>
                                            <div class="badge bg-info" v-if="transaction.docu_sign">Completed</div>
                                            <a :href="signAgreementDocumentLink(transaction)" target="_blank" class="btn btn-link btn-link-dark ms-md-3" v-if="!transaction.docu_sign">Sign</a>
                                        </div>

                                        <!-- Cancelled -->
                                        <div class="badge bg-danger" v-if="transaction.status === 'UNWINDPENDING' || transaction.status === 'CANCELED' || transaction.status === 'CANCELLED'">Cancelled</div>

                                        <!-- Refunded -->
                                        <div class="badge bg-danger" v-if="transaction.status === 'UNWINDSETTLED'">Refunded</div>

                                        <!-- Cancel -->
                                        <button @click.prevent="cancelTransaction(transaction, key)" class="btn btn-link btn-link-dark ms-md-3" v-if="transaction.status === 'CREATED'" :disabled="transaction.cancelling">
                                            <span class="spinner-border spinner-border-sm text-black-50 opacity-50" v-if="transaction.cancelling"></span>
                                            <span v-if="!transaction.cancelling">Cancel</span>
                                        </button>

                                    </div>
                                </div>
                                <div class="transactions-error" v-if="transactions.cancellationError">
                                    <img src="../assets/icons/cancel.png" alt="Error" class="error-icon">
                                    <p>We couldn't cancel your investment. Please try again.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Manually Refunded -->
                        <div class="distributions-wrapper" v-if="refunds.loaded && refunds.items.length > 0">
                            <hr class="mt-5">

                            <h5>Returned Investments</h5>

                            <!-- Refunds List -->
                            <div v-for="(refund, key) in refunds.items" :key="key" class="refund-item">
                                <div class="refund-amount">
                                    <strong>${{ refund.amount.toLocaleString() }}</strong>
                                </div>
                                <div class="refund-date">
                                    <span class="refund-date-day">{{ refund.date.day }}</span>
                                    <span class="refund-date-month">{{ refund.date.month }}</span>
                                    <span class="refund-date-year d-none d-md-inline">{{ refund.date.year }}</span>
                                </div>
                                <div class="refund-status">
                                    <div class="badge bg-danger">Refunded</div>
                                </div>
                            </div>
                        </div>

                        <!-- Distributions -->
                        <div class="distributions-wrapper" v-if="distributions.loaded && distributions.items.length > 0">
                            <hr class="mt-5">

                            <h5>Distributions & Reinvestments</h5>

                            <!-- Distributions List -->
                            <div v-for="(distribution, key) in distributions.items" :key="key" class="distribution-item">
                                <div class="distribution-amount">
                                    <strong>${{ distribution.amount.toLocaleString() }}</strong>
                                </div>
                                <div class="distribution-period">
                                    <span class="distribution-year">{{ distribution.distribution_period.year }}</span>
                                    <span class="distribution-quarter">{{ distribution.distribution_period.quarter }}</span>
                                </div>
                                <div class="distribution-type">
                                    <!-- Distributed -->
                                    <div class="badge bg-secondary" v-if="distribution.type === 'distributed'">Distributed</div>

                                    <!-- Reinvested -->
                                    <div class="badge bg-success" v-if="distribution.type === 'reinvested'">Reinvested</div>
                                </div>
                            </div>
                        </div>

<!--						<hr>-->

						<!-- Chart -->
<!--						<h5>Investments in the last 12 months</h5>-->
					</div>

				</div>
				<div class="col-xxl-4 mb-4">

					<!-- Invest Now -->
					<div class="invest-now">
						<img src="../assets/finish.jpg" alt="">
						<h4>Invest your preferred amount</h4>
						<p>Become a property owner for a low minimum of $5,000. Receive immediate access to property and fund performance.</p>
						<router-link to="/invest" class="btn btn-primary d-inline-flex align-items-center mt-4">
							<img src="../assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
							<span>Invest Today</span>
						</router-link>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import repository, {API_URL} from "@/repository/repository";
import Explanation from "@/components/widgets/Explanation.vue";
import Vue from "vue";
import MobileHeader from "@/components/MobileHeader.vue";
import AutoInvestmentExplanation from "@/components/widgets/AutoInvestmentExplanation.vue";

export default {
    name: "Transactions",
    components: {AutoInvestmentExplanation, MobileHeader, Explanation},
    data() {
        return {
            investments: {
                total: null,
                cleared: null,
                pending: null,
                cancelled: null,
                refunded: null,
                manually_refunded: null,
                reinvested: null,
                distributed: null,
                loaded: false,
                error: false,
            },
            transactions: {
                items: [],
                loaded: false,
                error: false,
                cancellationError: false,
			},
            distributions: {
                items: [],
                loaded: false,
                error: false,
            },
            refunds: {
                items: [],
                loaded: false,
                error: false,
            },
        }
    },
	created() {
        this.loadInvestments();
        this.loadTransactions();
    },
    methods: {
        loadInvestments() {
            repository.get("/investments/summary")
                .then(response => {
                    this.investments = response.data.summary;
                    this.investments.error = false;
                    this.investments.loaded = true;
                })
                .catch(() => {
                    this.investments.error = true;
                    this.investments.loaded = true;
                });
        },
        loadTransactions() {
            repository.get("/investments")
				.then(response => {
                    this.transactions.items = response.data.investments;
                    this.transactions.items.forEach(_item => _item.cancelling = false);
                    this.transactions.loaded = true;
                    this.transactions.error = false;
                    this.$store.commit("updateUnsignedAgreements", this.transactions.items.filter(item => item.status === 'CREATED' || item.status === 'FUNDED').filter(item => item.docu_sign === 0).length);

                    this.distributions.items = response.data.distributions;
                    this.distributions.loaded = true;
                    this.distributions.error = false;

                    this.refunds.items = response.data.refunds;
                    this.refunds.loaded = true;
                    this.refunds.error = false;
				})
				.catch(() => {
                    this.transactions.loaded = true;
                    this.transactions.error = true;
                    this.distributions.loaded = true;
                    this.distributions.error = true;
				})
		},
        cancelTransaction(transaction, index) {
            if(!confirm("Are you sure? This will cancel your investment & stop the fund movement.")) return;
            this.transactions.cancellationError = false;
            transaction.cancelling = true;
            Vue.set(this.transactions.items, index, transaction);
            repository.delete("/investments/" + transaction.id)
				.then(response => {
                    console.log(response);
                    transaction.false = true;
                    transaction.status = 'CANCELED';
                    Vue.set(this.transactions.items, index, transaction);
                    this.$store.commit("updateUnsignedAgreements", this.transactions.items.filter(item => item.status === 'CREATED' || item.status === 'FUNDED').filter(item => item.docu_sign === 0).length);
                    this.loadInvestments();
				})
				.catch(() => {
                    transaction.false = true;
                    Vue.set(this.transactions.items, index, transaction);
                    this.transactions.cancellationError = true;
				})
		},
        signAgreementDocumentLink(transaction) {
            return API_URL + "/investments/" + transaction.id + "/sign?token=" + this.$store.getters.token;
		},
	},
    computed: {
        accountValue() {
            return this.investments.cleared - this.investments.manually_refunded;
        }
    },
}
</script>

<style lang="scss">
.invest-now {
	display: block;
	padding: 2.5rem;
	background-color: #fff;
	border-radius: 0.5rem;
	position: relative;
	text-align: center;

	@media (max-width: 767.99px) {
		padding: 2.5rem 1.5rem 5rem 1.5rem;
		width: calc(100% + 3rem);
		margin: 0 -1.5rem -3.75rem -1.5rem;
		border-radius: 0;
		text-align: left;
	}

	img {
		width: 10rem;

		@media (max-width: 767.99px) {
			width: 7.5rem;
		}
	}

	h4 {
		font-weight: bold;
		color: #16193A;
		margin: 2rem 0 1rem 0;
	}

	p {
		color: #16193A;
		margin: 0;
	}
}
.transactions-box {
	display: block;
	position: relative;
	padding: 2.5rem;
	background: #fff;
	border-radius: 0.5rem;

	@media (max-width: 767.99px) {
		background: transparent;
		border-radius: 0;
		padding: 0;
	}

	hr {
		margin: 2rem 0;
		border-color: #ddd;
		opacity: 1;
	}

	h5 {
		font-size: 1rem;
		color: rgba(22, 25, 58, 1);
		font-weight: 700;
	}

	.transaction-item {
		display: flex;
		position: relative;
		transition: 0.15s ease all;
		border-radius: 0.5rem;
		background: #fafbfc;
		padding: 1rem;
		margin-top: 0.5rem;
		align-items: center;

		@media (max-width: 767.99px) {
			padding: 0.5rem 0;
			margin: 0;
			background: transparent;
		}

		.transaction-amount {
			margin-right: 1.5rem;
			width: 30%;
			flex-shrink: 0;

            strong {
                color: #F37021;
                font-weight: 700;
            }

            span {
                font-size: 0.9rem;
                color: #16193A;
                opacity: 0.5;
                margin-left: 0.25rem;
            }

			@media (min-width: 768px) and (max-width: 1700px) {
				width: 30%;
				margin-bottom: 0.5rem;
				margin-right: 0;
			}

			@media (max-width: 767.99px) {
				margin-right: 0;
				width: 40%;
			}

			&:before {
				content: 'Amount Invested:';
				display: block;
				font-size: 0.7rem;
				color: #16193A;
				font-weight: 400;

				@media (max-width: 767.99px) {
					display: none;
				}
			}
		}

		.transaction-date {
			display: block;
			color: #16193A;
			font-weight: 700;
			margin-right: 1.5rem;
			width: 25%;
			flex-shrink: 0;

			@media (min-width: 768px) and (max-width: 1700px) {
				width: 33%;
				margin-right: 0;
				margin-bottom: 0.5rem;
			}

			@media (max-width: 767.99px) {
				font-weight: 700;
                margin-right: 0;
                width: 30%;
                font-size: 0.9rem;
			}

			&:before {
				content: 'Date:';
				display: block;
				font-size: 0.7rem;
				color: #16193A;
				font-weight: 400;

				@media (max-width: 767.99px) {
					display: none;
				}
			}

			.transaction-date-day {
				margin-right: 0.25rem;
			}

			.transaction-date-month {
				margin-right: 0.25rem;
			}
		}

		.transaction-status {
			margin-left: auto;

			@media (max-width: 767.99px) {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: flex-end;
				margin-left: 0;
				width: 40%;

				.btn.btn-link {
					font-size: 0.8rem !important;
                    margin-left: 0.5rem;

                    @media (max-width: 767.99px) {
                        display: none;
                    }
				}
			}
		}
	}

    .refund-item {
        display: flex;
        position: relative;
        transition: 0.15s ease all;
        border-radius: 0.5rem;
        background: #fafbfc;
        padding: 1rem;
        margin-top: 0.5rem;
        align-items: center;

        @media (max-width: 767.99px) {
            padding: 0.5rem 0;
            margin: 0;
            background: transparent;
        }

        .refund-amount {
            margin-right: 1.5rem;
            width: 30%;
            flex-shrink: 0;

            strong {
                color: #F37021;
                font-weight: 700;
            }

            span {
                font-size: 0.9rem;
                color: #16193A;
                opacity: 0.5;
                margin-left: 0.25rem;
            }

            @media (min-width: 768px) and (max-width: 1700px) {
                width: 30%;
                margin-bottom: 0.5rem;
                margin-right: 0;
            }

            @media (max-width: 767.99px) {
                margin-right: 0;
                width: 40%;
            }

            &:before {
                content: 'Amount Refunded:';
                display: block;
                font-size: 0.7rem;
                color: #16193A;
                font-weight: 400;

                @media (max-width: 767.99px) {
                    display: none;
                }
            }
        }

        .refund-date {
            display: block;
            color: #16193A;
            font-weight: 700;
            margin-right: 1.5rem;
            width: 25%;
            flex-shrink: 0;

            @media (min-width: 768px) and (max-width: 1700px) {
                width: 33%;
                margin-right: 0;
                margin-bottom: 0.5rem;
            }

            @media (max-width: 767.99px) {
                font-weight: 700;
                margin-right: 0;
                width: 30%;
                font-size: 0.9rem;
            }

            &:before {
                content: 'Date:';
                display: block;
                font-size: 0.7rem;
                color: #16193A;
                font-weight: 400;

                @media (max-width: 767.99px) {
                    display: none;
                }
            }

            .refund-date-day {
                margin-right: 0.25rem;
            }

            .refund-date-month {
                margin-right: 0.25rem;
            }
        }

        .refund-status {
            margin-left: auto;

            @media (max-width: 767.99px) {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;
                margin-left: 0;
                width: 40%;
            }
        }
    }

	.distribution-item {
		display: flex;
		position: relative;
		transition: 0.15s ease all;
		border-radius: 0.5rem;
		background: #fafbfc;
		padding: 1rem;
		margin-top: 0.5rem;
		align-items: center;

		@media (max-width: 767.99px) {
			padding: 0.5rem 0;
			margin: 0;
			background: transparent;
		}

		.distribution-amount {
			margin-right: 1.5rem;
			width: 30%;
			flex-shrink: 0;

            strong {
                color: #F37021;
                font-weight: 700;
            }

            span {
                font-size: 0.9rem;
                color: #16193A;
                opacity: 0.5;
                margin-left: 0.25rem;
            }

			@media (min-width: 768px) and (max-width: 1700px) {
				width: 30%;
				margin-bottom: 0.5rem;
				margin-right: 0;
			}

			@media (max-width: 767.99px) {
				margin-right: 0;
				width: 40%;
			}

			&:before {
				content: 'Amount:';
				display: block;
				font-size: 0.7rem;
				color: #16193A;
				font-weight: 400;

				@media (max-width: 767.99px) {
					display: none;
				}
			}
		}

		.distribution-period {
			display: block;
			color: #16193A;
			font-weight: 700;
			margin-right: 1.5rem;
			width: 25%;
			flex-shrink: 0;

			@media (min-width: 768px) and (max-width: 1700px) {
				width: 33%;
				margin-right: 0;
				margin-bottom: 0.5rem;
			}

			@media (max-width: 767.99px) {
				font-weight: 700;
                margin-right: 0;
                width: 30%;
                font-size: 0.9rem;
			}

			&:before {
				content: 'Period:';
				display: block;
				font-size: 0.7rem;
				color: #16193A;
				font-weight: 400;

				@media (max-width: 767.99px) {
					display: none;
				}
			}

			.distribution-year {
				margin-right: 0.25rem;
			}
		}

		.distribution-type {
			margin-left: auto;

			@media (max-width: 767.99px) {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: flex-end;
				margin-left: 0;
				width: 40%;

				.btn.btn-link {
					font-size: 0.8rem !important;
                    margin-left: 0.5rem;

                    @media (max-width: 767.99px) {
                        display: none;
                    }
				}
			}
		}
	}

	.transactions-error {
		display: flex;
		align-items: center;

		img.error-icon {
			width: 2rem;
			height: 2rem;
			margin-right: 0.5rem;
		}

		p {
			margin: 0;
			color: #e81e1e;
			font-weight: bold;
		}
	}

	.transactions-summary {
		display: block;
		font-size: 0.9rem;
		color: #16193A;
		transition: 0.15s ease all;

		strong {
			font-size: 1.5rem;
			display: block;
			transition: 0.15s ease all;
		}

		span {
			display: block;
			transition: 0.15s ease all;
		}

		.explanation {
			margin-left: 0.25rem;
			width: 0.9rem;
			opacity: 0.5;
			margin-bottom: 0.15rem;

			&:hover {
				opacity: 1;
			}
		}

		&.loading {
			background: #f2f3f4;
			border-radius: 0.5rem;
			animation: pulse-dark 1s infinite;

			.explanation {
				user-select: none;
				opacity: 0;
			}

			strong {
				user-select: none;
				color: transparent !important;
			}

			span {
				user-select: none;
				color: transparent;
			}
		}
	}
}
</style>