<template>
    <form action="#" method="post" @submit.prevent="saveChanges" class="account-screen">

        <!-- Desktop Screen Title -->
        <h3 class="screen-title mb-3 d-none d-md-block">Bank Account</h3>

        <div class="account-dashboard-box mb-4">
            <div class="spinner-border" v-if="!loaded"></div>
            <div v-if="loaded">
                <div class="form-field">
                    <label for="accountName" class="form-label">Account Name</label>
                    <input type="text" class="form-control" name="accountName" id="accountName" placeholder="Account Name as it appears on your paper checks" required v-model="accountName">
                </div>
                <div class="form-field mt-3 pt-3">
                    <label for="bankName" class="form-label">Bank</label>
                    <input type="text" class="form-control" name="bankName" id="bankName" placeholder="Name of bank as it appears on your paper checks" required v-model="name">
                </div>
                <div class="form-field mt-3 pt-3">
                    <label for="accountType" class="form-label">Account Type</label>
                    <select class="form-control" name="accountType" id="accountType" required v-model="type">
                        <option>Checking</option>
                        <option>Savings</option>
                    </select>
                </div>
                <div class="form-field mt-3 pt-3">
                    <label for="routingNumber" class="form-label">Routing Number</label>
                    <input type="text" class="form-control" name="routingNumber" id="routingNumber" placeholder="Enter 9 digit routing number" required v-model="routingNumber">
                </div>
                <img src="../../assets/bank-account-explanation.jpg" alt="Bank Account Explanation" class="img-fluid d-block mx-auto w-75 mt-5">
                <div class="form-field mt-3 pt-3">
                    <label for="accountNumber" class="form-label">Account Number</label>
                    <input type="text" class="form-control" name="accountNumber" id="accountNumber" placeholder="Enter account number" required v-model="accountNumber">
                </div>

                <p class="text-danger fw-bolder small mt-4" v-if="error">{{ error }}</p>
            </div>
        </div>

        <div class="account-screen-actions">
            <button type="submit" :class="{'btn btn-primary': true, 'disabled': saving || !loaded, 'changed': changed}" :disabled="saving || !loaded || !changed">
                <span class="spinner-border" v-if="saving"></span>
                <span :class="{'opacity-0': saving}">Save Changes</span>
            </button>
        </div>

    </form>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "BankAccount",
    data() {
        return {
            loaded: false,
            error: false,
            changed: false,
            saving: false,
            accountName: null,
            name: null,
            type: null,
            routingNumber: null,
            accountNumber: null,
        }
    },
    created() {
        this.loadDetails();
        let propertiesToWatch = Object.keys(this.$data).filter(prop => prop !== 'changed');
        propertiesToWatch.forEach(prop => {
            this.$watch(prop, () => {
                this.changed = true;
            }, { deep: true });
        });
    },
    methods: {
        loadDetails() {
            this.loaded = false;
            this.error = false;

            repository.get("/bank-account")
                .then(response => {
                    let data = response.data.bank;
                    this.accountName = data.name;
                    this.name = data.nickName ? data.nickName.replace(data.name + " - ", "") : null;
                    this.type = data.type;
                    this.routingNumber = data.routingNumber;
                    this.accountNumber = data.accountNumber;

                    // Finish loading
                    this.loaded = true;
                    this.$nextTick(() => {
                        this.changed = false;
                    });
                })
                .catch(() => {
                    this.loaded = true;
                    this.error = true;
                })
        },
        saveChanges() {
            // Start saving effects
            this.error = false;
            this.saving = true;

            // Prepare payload
            let payload = {
                accountName: this.accountName,
                name: this.name,
                type: this.type,
                routingNumber: this.routingNumber,
                accountNumber: this.accountNumber,
            };

            // Submit to server
            repository.post("/bank-account", payload)
                .then(() => {
                    this.saving = false;
                    this.$nextTick(() => {
                        this.changed = false;
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.saving = false;
                    this.error = error.response.data.message;
                })
        },
    },
}
</script>

<style lang="scss">
</style>