<template>
	<div class="screen">

        <!-- Mobile Header -->
        <MobileHeader :dark="false" :menu-toggle="true" title="Performance"/>

        <div class="container-fluid">

            <!-- Desktop Screen Title -->
            <h3 class="screen-title mb-3 d-none d-md-block">Performance</h3>

            <div class="row">
                <div class="col-xxl-4 order-xxl-2 mb-4">
                    <!-- Account Value -->
                    <PerformanceAccountValue :summary="summary"></PerformanceAccountValue>
                </div>
                <div class="col-xxl-8 order-xxl-1">
                    <!-- Account Growth -->
                    <PerformanceAccountGrowthChart :transactions="transactions"></PerformanceAccountGrowthChart>

                    <!-- Investments Chart -->
                    <PerformanceInvestmentsChart :transactions="transactions"></PerformanceInvestmentsChart>

                    <!-- Distributions & Reinvestments -->
                    <PerformanceDistributionsChart :transactions="transactions"></PerformanceDistributionsChart>
                </div>
            </div>

        </div>

	</div>
</template>

<script>
import repository from "@/repository/repository";
import MobileHeader from "@/components/MobileHeader.vue";
import PerformanceAccountGrowthChart from "@/components/elements/PerformanceAccountGrowthChart.vue";
import PerformanceAccountValue from "@/components/elements/PerformanceAccountValue.vue";
import PerformanceInvestmentsChart from "@/components/elements/PerformanceInvestmentsChart.vue";
import PerformanceDistributionsChart from "@/components/elements/PerformanceDistributionsChart.vue";

export default {
    name: "Performance",
    components: {
        PerformanceDistributionsChart,
        PerformanceInvestmentsChart, PerformanceAccountGrowthChart, PerformanceAccountValue, MobileHeader},
    data() {
        return {
            summary: {
                total: null,
                cleared: null,
                pending: null,
                cancelled: null,
                refunded: null,
                manually_refunded: null,
                reinvested: null,
                distributed: null,
                loaded: false,
                error: false,
            },
            transactions: {
                investments: [],
                distributions: [],
                loaded: false,
                error: false,
            },
        }
    },
    created() {
        this.loadInvestmentsSummary();
        this.loadTransactions();
    },
    methods: {
        loadInvestmentsSummary() {
            repository.get("/investments/summary")
                .then(response => {
                    this.summary = response.data.summary;
                    this.summary.error = false;
                    this.summary.loaded = true;
                })
                .catch(() => {
                    this.summary.error = true;
                    this.summary.loaded = true;
                });
        },
        loadTransactions() {
            repository.get("/investments")
                .then(response => {
                    this.transactions.investments = response.data.investments;
                    this.transactions.distributions = response.data.distributions;
                    this.transactions.error = false;
                    this.transactions.loaded = true;
                })
                .catch(() => {
                    this.transactions.error = true;
                    this.transactions.loaded = true;
                });
        },
    },
}
</script>