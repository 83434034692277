<template>
    <div class="account-investments-box mb-4">
        <!-- Header -->
        <div class="row align-items-center">
            <div class="col-md-6">
                <h4>Your Investments</h4>
            </div>
            <div class="col-md-3 ms-md-auto" v-if="transactions.loaded && fundedInvestments.length > 0">
                <select name="months" id="months" class="form-control" aria-label="Months" v-model="months">
                    <option value="3">Last 3 months</option>
                    <option value="6">Last 6 months</option>
                    <option value="12">Last year</option>
                    <option value="24">Last 2 years</option>
                </select>
            </div>
        </div>

        <!-- Chart -->
        <VueApexCharts type="line" height="200" :options="areaChartOptions" :series="areaSeries" v-if="transactions.loaded && fundedInvestments.length > 0"></VueApexCharts>

        <!-- Loading -->
        <div class="area-loading text-center" v-if="!transactions.loaded">
            <div class="spinner-border d-block mx-auto opacity-25"></div>
        </div>

        <!-- No Distributions -->
        <div class="box-error" v-if="transactions.loaded && fundedInvestments.length === 0">
            <img src="@/assets/auto-investing.jpg" alt="">
            <h5>You haven't completed any investments</h5>
            <p>As of now, your investment account does not contain any funded investments. Please note that the process of transferring funds from your bank may take up to 5 working days to complete. Rest assured, once your investments are in place, you'll be able to track their progress and potential returns right here.</p>
            <router-link to="/invest" class="btn btn-secondary d-inline-flex align-items-center mt-3">
                <img src="@/assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
                <span>Invest Now</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "PerformanceInvestmentsChart",
    props: ['transactions'],
    components: { VueApexCharts },
    data() {
        return {
            months: 12,
        }
    },
    computed: {
        fundedInvestments() {
            if(!this.transactions.loaded) return [];
            return this.transactions.investments.filter(investment => investment.payment_status === 'funded');
        },
        investmentsPerMonth() {
            // Calculate total invested per month
            let totalInvestedPerMonth = {};
            for (let investment of this.fundedInvestments) {
                const investmentDate = new Date(investment.created_at);
                const monthYear = `${investmentDate.toLocaleString('default', { month: 'long' })} ${investmentDate.getFullYear()}`;

                if(!totalInvestedPerMonth[monthYear]) {
                    totalInvestedPerMonth[monthYear] = 0;
                }

                totalInvestedPerMonth[monthYear] += investment.amount;
            }

            // Generate the result array for the last X months, including months with no investments
            const results = [];
            const currentDate = new Date();
            let month = currentDate.getMonth();
            let year = currentDate.getFullYear();
            for (let i = 0; i < this.months; i++) {
                let newDate = new Date();
                newDate.setFullYear(year);
                newDate.setMonth(month);

                const monthYear = `${newDate.toLocaleString('default', { month: 'long' })} ${year}`;
                if (totalInvestedPerMonth[monthYear]) {
                    results.unshift({
                        month: monthYear,
                        total: totalInvestedPerMonth[monthYear],
                    });
                } else {
                    results.unshift({
                        month: monthYear,
                        total: 0,
                    });
                }

                month--;
                if (month < 0) {
                    month = 11;
                    year--;
                }
            }

            return results;
        },
        highestInvestment() {
            if(this.investmentsPerMonth.length === 0) return 1;
            let maximumValue = 0;
            this.investmentsPerMonth.forEach(entry => {
                if(entry.total > maximumValue) maximumValue = entry.total;
            })
            return Math.max(maximumValue) * 1.2;
        },
        areaSeries() {
            // Verify loaded
            if(!this.transactions.loaded) return [];

            // Get daily growth for account
            let values = [];
            this.investmentsPerMonth.forEach(entry => {
                values.push(entry.total);
            });

            // Push series
            let series = [];
            series.push({
                name: 'Investment',
                color: '#16193A',
                type: 'line',
                data: values,
            });
            return series;
        },
        areaCategories() {
            if (!this.transactions.loaded) return [];
            if (this.investmentsPerMonth.length === 0) return [];

            // Get daily growth for account
            let values = [];
            this.investmentsPerMonth.forEach(entry => {
                values.push(entry.month);
            });

            return values;
        },
        areaChartOptions() {
            return {
                chart: {
                    height: 300,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                },
                fill: {
                    type: 'gradient',
                },
                stroke: {
                    curve: 'smooth',
                    width: 3,
                },
                grid: {
                    borderColor: "#eee",
                },
                tooltip: {
                    enabled: true,
                    marker: {
                        show: true,
                    },
                    x: {
                        format: 'dd/MM/yy'
                    },
                    fixed: {
                        enabled: true,
                        position: 'bottomRight',
                        offsetX: -20,
                        offsetY: -20,
                    },
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return `
        <div class="account-growth-chart-tooltip">
          <span>${w.globals.categoryLabels[dataPointIndex]}</span>
          <strong>$${series[seriesIndex][dataPointIndex].toLocaleString()}</strong>
        </div>
      `;
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    labels: {
                        colors: "#aaa",
                    }
                },
                xaxis: {
                    type: 'date',
                    categories: this.areaCategories,
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    forceNiceScale: true,
                    tickAmount: 5,
                    min: 0,
                    max: this.highestInvestment,
                    labels: {
                        formatter: function (value) {
                            if (value >= 500000) {
                                return "$" + (value / 1000000).toFixed(1) + "M";
                            }
                            if (value >= 1000) {
                                return "$" + (value / 1000).toFixed(0) + "K";
                            }
                            return "$" + value;
                        },
                    },
                },
            };
        }
    },
}
</script>

<style lang="scss" scoped>
.account-investments-box {
    display: block;
    padding: 2.5rem 2.5rem 1.5rem 2.5rem;
    background: #fff;
    border-radius: 0.5rem;
    position: relative;

    @media (max-width: 767.99px) {
        border-radius: 0;
        padding: 0 1.5rem 2.5rem 1.5rem;
        width: calc(100% + 3rem);
        margin: -1.5rem -1.5rem 0 -1.5rem;
    }

    h4 {
        font-size: 1.25rem;
        color: #16193A;
        font-weight: 700;
    }

    .form-control {
        box-shadow: none;
        border: none;
        outline: none;
        background: rgba(22, 25, 58, 0.025);
        color: #16193A;
        font-weight: 600;
        cursor: pointer;

        @media (max-width: 767.99px) {
            margin: 0.75rem 0 1.5rem 0;
            padding: 1rem;
            background: rgba(22, 25, 58, 0.05);
        }
    }

    .area-loading {
        height: 215px;
        padding: 2.5rem 0;
    }

    .box-error {
        padding: 2.5rem 0;

        > img {
            width: 8rem;
        }

        h5 {
            font-weight: bold;
            color: #16193A;
            margin: 2.5rem 0 1rem 0;
        }
    }
}
</style>