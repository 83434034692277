<template>
    <div class="account-distributions-box">
        <!-- Header -->
        <div class="row align-items-center">
            <div class="col-md-6">
                <h4>Distributions &amp; Reinvestments</h4>
            </div>
            <div class="col-md-3 ms-md-auto" v-if="transactions.loaded && distributions.length > 0">
                <select name="months" id="months" class="form-control" aria-label="Months" v-model="months">
                    <option value="6">Last 6 months</option>
                    <option value="12">Last year</option>
                    <option value="24">Last 2 years</option>
                    <option value="60">Last 5 years</option>
                </select>
            </div>
        </div>

        <!-- Chart -->
        <VueApexCharts type="bar" height="400" :options="areaChartOptions" :series="areaSeries" v-if="transactions.loaded && distributions.length > 0"></VueApexCharts>

        <!-- Loading -->
        <div class="area-loading text-center" v-if="!transactions.loaded">
            <div class="spinner-border d-block mx-auto opacity-25"></div>
        </div>

        <!-- No Distributions -->
        <div class="box-error" v-if="transactions.loaded && distributions.length === 0">
            <img src="@/assets/finish.jpg" alt="">
            <h5>You haven't received any distributions or reinvestments yet</h5>
            <p>Currently, your investment account has not yielded any distributions or reinvestments. We encourage you to stay tuned, as your earnings will be showcased here once they are realized.</p>
            <router-link to="/invest" class="btn btn-primary d-inline-flex align-items-center mt-3">
                <img src="@/assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
                <span>Invest Now</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "PerformanceDistributionsChart",
    props: ['transactions'],
    components: { VueApexCharts },
    data() {
        return {
            months: 12,
        }
    },
    computed: {
        distributions() {
            if(!this.transactions.loaded) return [];
            return this.transactions.distributions;
        },
        distributionsPerMonth() {
            // Calculate total invested per month
            let totalDistributionsPerMonth = {};
            let totalReinvestedPerMonth = {};
            for (let distribution of this.distributions) {
                const investmentDate = new Date(distribution.distribution_period.payable_date);
                const monthYear = `${investmentDate.toLocaleString('default', { month: 'long' })} ${investmentDate.getFullYear()}`;

                if(!totalDistributionsPerMonth[monthYear]) {
                    totalDistributionsPerMonth[monthYear] = 0;
                }

                if(!totalReinvestedPerMonth[monthYear]) {
                    totalReinvestedPerMonth[monthYear] = 0;
                }

                if(distribution.type === 'distributed') {
                    totalDistributionsPerMonth[monthYear] += distribution.amount;
                } else {
                    totalReinvestedPerMonth[monthYear] += distribution.amount;
                }
            }

            // Generate the result array for the last 6 months, including months with no investments
            const results = [];
            const currentDate = new Date();
            for (let i = 0; i < this.months; i++) {
                let month = currentDate.getMonth() - i;
                let year = currentDate.getFullYear();

                if (month < 0) {
                    month = month + 12;
                    year = year - 1;
                }
                let newDate = new Date();
                newDate.setFullYear(year);
                newDate.setMonth(month);

                const monthYear = `${newDate.toLocaleString('default', { month: 'long' })} ${newDate.getFullYear()}`;
                results.unshift({
                    month: monthYear,
                    distributed: totalDistributionsPerMonth[monthYear] ?? 0,
                    reinvested: totalReinvestedPerMonth[monthYear] ?? 0,
                });
            }

            return results;
        },
        highestAmount() {
            if(this.distributionsPerMonth.length === 0) return 1;
            let maximumValue = 0;
            this.distributionsPerMonth.forEach(entry => {
                if(entry.reinvested > maximumValue) maximumValue = entry.reinvested;
                if(entry.distributed > maximumValue) maximumValue = entry.distributed;
            })
            return Math.max(maximumValue) * 1.2;
        },
        areaSeries() {
            // Verify loaded
            if(!this.transactions.loaded) return [];

            // Get daily growth for account
            let reinvestments = [];
            let distributions = [];
            this.distributionsPerMonth.forEach(entry => {
                reinvestments.push(entry.reinvested);
                distributions.push(entry.distributed);
            });

            // Push series
            let series = [];
            series.push({
                name: 'Reinvestments',
                color: '#16193A',
                type: 'bar',
                data: reinvestments,
            });
            series.push({
                name: 'Distributions',
                color: '#F37021',
                type: 'bar',
                data: distributions,
            });
            return series;
        },
        areaCategories() {
            if (!this.transactions.loaded) return [];
            if (this.distributionsPerMonth.length === 0) return [];

            // Get daily growth for account
            let values = [];
            this.distributionsPerMonth.forEach(entry => {
                values.push(entry.month);
            });

            return values;
        },
        areaChartOptions() {
            return {
                chart: {
                    height: 300,
                    type: 'bar',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                },
                fill: {
                    type: 'fill',
                },
                stroke: {
                    curve: 'smooth',
                    width: 0,
                },
                grid: {
                    borderColor: "#eee",
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false,
                    marker: {
                        show: true,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    labels: {
                        colors: "#aaa",
                    }
                },
                xaxis: {
                    type: 'date',
                    categories: this.areaCategories,
                    labels: {
                        show: true,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    forceNiceScale: true,
                    tickAmount: 5,
                    min: 0,
                    max: this.highestAmount,
                    labels: {
                        formatter: function (value) {
                            return "$" + value.toLocaleString();
                        },
                    },
                },
            };
        }
    },
}
</script>

<style lang="scss" scoped>
.account-distributions-box {
    display: block;
    padding: 2.5rem 2.5rem 1.5rem 2.5rem;
    background: #fff;
    border-radius: 0.5rem;
    position: relative;

    @media (max-width: 767.99px) {
        border-radius: 0;
        padding: 0 1.5rem 2.5rem 1.5rem;
        width: calc(100% + 3rem);
        margin: -1.5rem -1.5rem -1.5rem -1.5rem;
    }

    h4 {
        font-size: 1.25rem;
        color: #16193A;
        font-weight: 700;
    }

    .form-control {
        box-shadow: none;
        border: none;
        outline: none;
        background: rgba(22, 25, 58, 0.025);
        color: #16193A;
        font-weight: 600;
        cursor: pointer;

        @media (max-width: 767.99px) {
            margin: 0.75rem 0 1.5rem 0;
            padding: 1rem;
            background: rgba(22, 25, 58, 0.05);
        }
    }

    .area-loading {
        height: 415px;
        padding: 2.5rem 0;
    }

    .box-error {
        padding: 2.5rem 0;

        > img {
            width: 8rem;
        }

        h5 {
            font-weight: bold;
            color: #16193A;
            margin: 2.5rem 0 1rem 0;
        }
    }
}
</style>

<style lang="scss">
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: none !important;
    font-weight: bold !important;
    font-family: "Red Hat Display", sans-serif !important;
    font-size: 1rem !important;
    color: #16193A !important;
    padding: 1rem 1rem 0 1rem !important;
    border-bottom: none !important;
}
.apexcharts-tooltip-series-group {

    & + .apexcharts-tooltip-series-group {
        margin-top: -1rem;
    }

    .apexcharts-tooltip-y-group {
        font-family: "Red Hat Display", sans-serif !important;
        font-size: 0.9rem !important;
        font-weight: 600 !important;
        color: #16193A !important;
    }

    padding: 0 1rem 1rem 1rem !important;
}
</style>