<template>
	<div class="screen impact-screen">

		<!-- Mobile Header -->
		<MobileHeader :menu-toggle="true" title="Impact"/>

		<div class="container-fluid">

            <!-- Desktop Screen Title -->
            <h3 class="screen-title mb-3 d-none d-md-block">Impact</h3>

			<!-- Our Impact -->
            <div class="impact-wrapper">
                <div class="row">
                    <div class="col-xxl-5">
                        <div class="impact-info">
                            <h1>Our Environment</h1>
                            <p>Birgo has a strong commitment to a high standard of <strong>social, financial</strong>, and <strong>environmental stewardship</strong>.</p>
                            <p>Our impact framework connects our actions to a defined set of outcomes and identifies major elements of change that we believe are key to improving lives through real estate.</p>
                            <router-link to="/invest" class="btn btn-primary btn-wide mt-4">Invest Now</router-link>
                        </div>
                    </div>
                    <div class="col-xxl-7 mt-4 mt-xxl-0">
                        <div class="row gx-0">
                            <div class="col-md-6" v-if="!loaded">
                                <ImpactBox :loading="true"></ImpactBox>
                            </div>
                            <div class="col-md-6" v-if="!loaded">
                                <ImpactBox :loading="true"></ImpactBox>
                            </div>
                            <div class="col-md-6" v-if="!loaded">
                                <ImpactBox :loading="true"></ImpactBox>
                            </div>
                            <div class="col-md-6" v-for="(impactItem, key) in items" :key="key">
                                <ImpactBox :impact="impactItem"></ImpactBox>
                            </div>
                        </div>
                    </div>
                </div>
			</div>

            <!-- Your Impact -->
            <div :class="{'your-impact': true, 'loading': !loaded}">
                <div class="row">
                    <div class="col-xl-5">
                        <h3>Your investment of</h3>
                        <h2>${{ totalInvested }}</h2>
                        <h3>has yielded the following outcomes</h3>
                        <p>By measuring our impact, we can reflect and make informed decisions, focusing our resources on the highest priorities to maximize our impact.</p>
                        <router-link to="/invest" class="btn btn-transparent-light btn-wide mt-4">Setup auto-investment</router-link>
                    </div>
                    <div class="col-xl-6 ms-xl-auto mt-5 mt-xl-0">
                        <div class="impact-list" v-if="loaded">
                            <div class="impact-item" v-for="(impactItem, key) in items" :key="key">
                                <img :src="impactItem.icon" :alt="impactItem.title">
                                <strong>{{ impactItem ? (impactItem.prefix ? impactItem.prefix : '') + calculateImpact(impactItem.value) : '0,00' }}</strong>
                                <span>{{ impactItem.title }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


		</div>
	</div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader.vue";
import repository from "@/repository/repository";
import ImpactBox from "@/components/widgets/ImpactBox.vue";

export default {
    name: "Impact",
	components: {ImpactBox, MobileHeader},
	data() {
        return {
            items: [],
            total: 0,
            loaded: false,
        }
    },
	created() {
		this.loadImpactFeatures();
	},
	methods: {
        loadImpactFeatures() {
			this.loaded = false;
			repository.get("/impact")
				.then(response => {
					this.items = response.data.impact;
					this.total = response.data.total_invested;
					this.loaded = true;
				});
		},
        calculateImpact(value) {
            return (value * (this.investmentPercentage / 100)).toFixed(2);
        }
	},
    computed: {
        totalInvested() {
            return this.total.toLocaleString();
        },
        investmentPercentage() {
            // TODO: Put this $270MM in some environment variable
            return (100 / 270000000) * this.total;
        },
    },
}
</script>

<style lang="scss">
.impact-wrapper {
    background: #fff;
    border-radius: 0.5rem;
    padding: 2.5rem 2.5rem 2.5rem 0;

    @media (max-width: 1399px) {
        padding: 2.5rem;
    }

    @media (max-width: 767px) {
        padding: 1.5rem 1.5rem 2.5rem 1.5rem;
        width: calc(100% + 3rem);
        margin: -2.5rem -1.5rem 0 -1.5rem;
        border-radius: 0;
    }

    .impact-info {
        padding: 2rem 4rem;

        @media (max-width: 1399px) {
            padding: 0;
        }

        h1 {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
            color: #16193A;
        }

        p {
            color: rgba(#16193A, 0.5);

            strong {
                color: #16193A;
            }
        }
    }

    .col-md-6:nth-child(2n) {
    }
}

.your-impact {
    background: #16193A;
    padding: 4rem;
    margin-top: 1.5rem;
    border-radius: 0.5rem;

    @media (max-width: 767px) {
        padding: 4rem 1.5rem;
        width: calc(100% + 3rem);
        margin: 0 -1.5rem 0 -1.5rem;
        border-radius: 0;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: 400;
        color: rgba(#fff, 0.5);
    }

    h2 {
        font-size: 3.5rem;
        font-weight: bold;
        color: #fff;
    }

    p {
        font-size: 0.8rem;
        color: rgba(#fff, 0.25);
        margin: 1rem 0 0 0;
        line-height: 1.4;
    }

    .impact-list {
        padding-left: 1.5rem;
        border-left: 1px solid rgba(#fff, 0.1);

        @media (max-width: 1199.99px) {
            padding-left: 0;
            border-left: none;
        }

        .impact-item {
            display: flex;
            align-items: center;

            & + .impact-item {
                margin-top: 0.5rem;
                padding-top: 0.5rem;
                border-top: 1px solid rgba(#fff, 0.05);
            }

            img {
                width: 2rem;
                height: 2rem;
                filter: brightness(0) invert(1);
            }

            strong {
                margin: 0 auto 0 0.5rem;
                font-weight: 700;
                color: #fff;
                font-size: 1rem;
            }

            span {
                font-weight: 400;
                color: #fff;
                padding-left: 1rem;
            }
        }
    }

    &.loading {
        h3, h2, p, .impact-item, strong, span, .btn {
            color: transparent;
            border-radius: 0.5rem;
            background: rgba(#fff, 0.1);
        }
    }
}
</style>