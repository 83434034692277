<template>
	<div class="dashboard-sidebar">
		<h4 class="d-none d-md-block">Main</h4>
		<ul class="nav">
			<li class="nav-item">
				<router-link to="/" class="nav-link">
					<img src="./../assets/icons/home.svg" alt="Home">
					<span>Home</span>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/invest" class="nav-link">
					<img src="./../assets/icons/dollar.svg" alt="Dollar">
					<span>Invest</span>
				</router-link>
			</li>
            <li class="nav-item">
                <router-link to="/performance" class="nav-link">
                    <img src="./../assets/icons/performance.svg" alt="Performance">
                    <span>Performance</span>
                </router-link>
            </li>
			<li class="nav-item">
				<router-link to="/transactions" class="nav-link">
					<img src="./../assets/icons/wallet.svg" alt="Wallet">
					<span>Transactions</span>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/documents" class="nav-link">
					<img src="./../assets/icons/document-alt.svg" alt="Document">
					<span>Documents</span>
                    <i v-if="unsignedAgreements > 0">{{ unsignedAgreements }}</i>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/portfolio" class="nav-link">
					<img src="./../assets/icons/pie-chart.svg" alt="Pie Chart">
					<span>Portfolio</span>
				</router-link>
			</li>
		</ul>
		<hr>
		<h4 class="d-none d-md-block">Investor Resources</h4>
		<ul class="nav">
			<li class="nav-item">
				<router-link to="/impact" class="nav-link">
					<img src="./../assets/icons/earth.svg" alt="Impact">
					<span>Impact</span>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/calculator" class="nav-link">
					<img src="./../assets/icons/calculator.svg" alt="Calculator">
					<span>Calculator</span>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/insights" class="nav-link">
					<img src="./../assets/icons/insights.svg" alt="Insights">
					<span>Insights</span>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/faq" class="nav-link">
					<img src="./../assets/icons/users.svg" alt="Users">
					<span>FAQ</span>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/legal" class="nav-link">
					<img src="./../assets/icons/info.svg" alt="Info">
					<span>Legal Notices</span>
				</router-link>
			</li>
		</ul>
		<p class="copyright">&copy; 2023 Reiturn All Rights Reserved</p>
	</div>
</template>

<script>
export default {
	name: "DesktopSidebar",
    computed: {
        unsignedAgreements() {
            return this.$store.getters.unsignedAgreements;
        },
    },
}
</script>

<style lang="scss" scoped>
.dashboard-sidebar {
	background: #fff;
	box-shadow: rgba(10,10,10,0.1) -0.5rem 0 1rem;
	width: 20rem;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	flex-grow: 0;

	@media (max-width: 767.99px) {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 99;
		width: 100%;
		height: 3.75rem;
		box-shadow: none;
		border-top: 1px solid #ddd;
	}

	h4 {
		font-size: 0.9rem;
		color: rgba(#16193A, 0.5);
		padding: 1.5rem 1.5rem 0 1.5rem;
		margin-top: 0;
		margin-bottom: -1rem;
	}

	hr {
		width: calc(100% - 3rem);
		margin: 0.5rem auto;
		opacity: 0.1;
	}

	.nav {
		flex-direction: column;
		padding: 1.5rem 0.5rem;

		@media (max-width: 767.99px) {
			padding: 0.3rem 0.5rem;
			flex-direction: row;
			justify-content: center;
		}

		.nav-item {
			margin-top: 0.1rem;

			@media (max-width: 767.99px) {
				width: 25%;
				margin-top: 0;
			}

			.nav-link {
				font-weight: 700;
				display: flex;
				align-items: center;
				font-size: 1rem;
				color: #16193a;
				transition: 0.15s ease all;
				border-radius: 0.35rem;
				padding: 0.75rem 1rem;
				user-select: none;
                position: relative;

				@media (max-width: 767.99px) {
					flex-direction: column;
					justify-content: center;
					font-size: 0.75rem;
					font-weight: 400;
					padding: 0.25rem 0.5rem;
				}

				&:hover,
				&.router-link-exact-active {
					background: #f2f3f4;
				}

				&:active {
					background: darken(#f2f3f4, 10%);
				}

				img {
					width: 1.5rem;
					height: 1.5rem;
				}

				span {
					margin-left: 0.5rem;

					@media (max-width: 767.99px) {
						margin-left: 0;
					}
				}

                i {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: 1rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    border-radius: 1rem;
                    font-weight: 600;
                    font-size: 0.9rem;
                    font-style: normal;
                    text-transform: uppercase;
                    background: #F37021;
                    color: #fff;
                }

				&.coming-soon {
					position: relative;
					pointer-events: none;
					user-select: none;

					&:after {
						position: absolute;
						top: 0;
						bottom: 0;
						margin: auto;
						right: 1rem;
						width: 2.5rem;
						height: 1rem;
						text-align: center;
						border-radius: 0.5rem;
						padding: 0.1rem;
						font-weight: 600;
						font-size: 0.5rem;
						text-transform: uppercase;
						content: 'SOON';
						background: #F37021;
						color: #fff;
					}

					img, span {
						opacity: 0.25;
					}
				}
			}

		}
	}

	p.copyright {
		padding: 1.5rem;
		font-size: 0.8rem;
		color: #999;
		margin: auto 0 0 0;
	}
}
</style>