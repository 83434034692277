<template>
    <form action="#" method="post" @submit.prevent="saveChanges" class="account-screen">

        <!-- Desktop Screen Title -->
        <h3 class="screen-title mb-3">Personal Details</h3>
        <div class="account-dashboard-box">
            <div class="spinner-border" v-if="!loaded"></div>
            <div class="row" v-if="loaded">
                <div class="col-6">
                    <div class="form-field">
                        <label for="firstName" class="form-label">First Name</label>
                        <input type="text" class="form-control" name="firstName" id="firstName" v-model="personal.firstName" placeholder="Enter your first name" required>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-field">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input type="text" class="form-control" name="lastName" id="lastName" v-model="personal.lastName" placeholder="Enter your last name" required>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-field mt-3 pt-3">
                        <label for="phone" class="form-label">Phone</label>
                        <input type="text" class="form-control" name="phone" id="phone" v-model="personal.phone" placeholder="201-555-0123" required>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-field mt-3 pt-3">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" class="form-control" name="email" id="email" v-model="personal.email" placeholder="Your email address here" disabled>
                    </div>
                </div>
            </div>
        </div>

        <!-- Account Details -->
        <h3 class="screen-title mt-3 pt-3 mb-3">Account Details</h3>
        <div class="account-dashboard-box">
            <div class="spinner-border" v-if="!loaded"></div>
            <div v-if="loaded">

                <!-- Accredited Investor -->
                <div class="form-field">
                    <h4 class="form-label mb-3">Accredited Investor</h4>
                    <div class="d-flex flex-wrap">
                        <div class="form-check button-checkbox">
                            <input type="radio" class="form-check-input" id="accreditedInvestorNo" name="accreditedInvestor" v-model="basic.accreditedInvestor" value="Not Accredited">
                            <label for="accreditedInvestorNo" class="form-check-label">No</label>
                        </div>
                        <div class="form-check button-checkbox ms-2">
                            <input type="radio" class="form-check-input" id="accreditedInvestorYes" name="accreditedInvestor" v-model="basic.accreditedInvestor" value="Accredited" checked>
                            <label for="accreditedInvestorYes" class="form-check-label">Yes</label>
                        </div>
                    </div>
                </div>

                <!-- Account Type -->
                <div class="form-field">
                    <h4 class="form-label mt-4 mt-md-4 pt-2">Account Type</h4>
                </div>
                <div class="form-check-large d-flex flex-wrap mt-3">
                    <div class="form-check button-checkbox me-2">
                        <input type="radio" class="form-check-input" id="accountTypeIndividual" name="accountType" value="Individual" v-model="basic.accountType">
                        <label for="accountTypeIndividual" class="form-check-label">Individual</label>
                    </div>
                    <div class="form-check button-checkbox">
                        <input type="radio" class="form-check-input" id="accountTypeOther" name="accountType" value="Other" v-model="basic.accountType">
                        <label for="accountTypeOther" class="form-check-label">Other account</label>
                    </div>
                </div>
                <div class="form-field mt-md-4 pt-3" id="otherAccountTypeField" v-if="basic.accountType && basic.accountType === 'Other'">
                    <label for="otherAccountType" class="form-label">Other Account Type</label>
                    <select class="form-control" name="otherAccountType" id="otherAccountType" v-model="basic.otherAccountType" required>
                        <option>Entity</option>
                        <option>TIC</option>
                        <option>JTWROS</option>
                        <option>IRA</option>
                        <option>SepIRA</option>
                        <option>ROTH</option>
                        <option>Joint</option>
                    </select>
                </div>
                <p class="text-danger fw-bolder small mt-3" v-if="hasSecondaryParty">We cannot accept investments from {{ basic.otherAccountType }} ownerships at the moment</p>

                <!-- If Entity Type -->
                <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityTypeField" v-if="basic.accountType && basic.accountType === 'Other' && basic.otherAccountType && basic.otherAccountType === 'Entity'">
                    <label for="otherAccountTypeEntityType" class="form-label">Entity Type</label>
                    <select class="form-control" name="otherAccountTypeEntityType" id="otherAccountTypeEntityType" v-model="basic.entity.type" required>
                        <option>Revocable Trust</option>
                        <option>Irrevocable Trust</option>
                        <option>Limited Partnership</option>
                        <option>LLC</option>
                        <option>Corporation</option>
                    </select>
                </div>

                <!-- Entity Details for Entity -->
                <div class="row" v-if="basic.accountType && basic.accountType === 'Other' && basic.otherAccountType && basic.otherAccountType === 'Entity'">
                    <div class="col-xl-6">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityNameField">
                            <label for="otherAccountTypeEntityName" class="form-label">Entity Name <Explanation title="This is the legal name of the investor in which name the Reiturn Units will be issued and owned. This name must match exactly the state records or other legal formation document."/></label>
                            <input type="text" class="form-control" name="otherAccountTypeEntityName" id="otherAccountTypeEntityName" v-model="basic.entity.name" required>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityEINField">
                            <label for="otherAccountTypeEntityEIN" class="form-label">Employer Identification Number</label>
                            <input type="text" class="form-control" name="otherAccountTypeEntityEIN" id="otherAccountTypeEntityEIN" v-model="basic.entity.ein" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityAddress1Field">
                            <label for="otherAccountTypeEntityAddress1" class="form-label">Entity Address 1</label>
                            <input type="text" class="form-control" name="otherAccountTypeEntityAddress1" id="otherAccountTypeEntityAddress1" v-model="basic.entity.address1" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityAddress2Field">
                            <label for="otherAccountTypeEntityAddress2" class="form-label">Entity Address 2</label>
                            <input type="text" class="form-control" name="otherAccountTypeEntityAddress2" id="otherAccountTypeEntityAddress2" v-model="basic.entity.address2">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityCityField">
                            <label for="otherAccountTypeEntityCity" class="form-label">Entity City</label>
                            <input type="text" class="form-control" name="otherAccountTypeEntityCity" id="otherAccountTypeEntityCity" v-model="basic.entity.city" required>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityStateField">
                            <label for="otherAccountTypeEntityState" class="form-label">Entity State</label>
                            <select class="form-control" name="otherAccountTypeEntityState" id="otherAccountTypeEntityState" v-model="basic.entity.state" required>
                                <option selected disabled>Please select</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityZipField" v-if="basic.accountType && basic.accountType === 'Other' && basic.otherAccountType && basic.otherAccountType === 'Entity'">
                            <label for="otherAccountTypeEntityZip" class="form-label">Entity Zip</label>
                            <input type="text" class="form-control" name="otherAccountTypeEntityZip" id="otherAccountTypeEntityZip" v-model="basic.entity.zip" required>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityEmailField" v-if="basic.accountType && basic.accountType === 'Other' && basic.otherAccountType && basic.otherAccountType === 'Entity'">
                            <label for="otherAccountTypeEntityEmail" class="form-label">Entity Email Address</label>
                            <input type="email" class="form-control" name="otherAccountTypeEntityEmail" id="otherAccountTypeEntityEmail" v-model="basic.entity.email" required>
                        </div>
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEntityPhoneField" v-if="basic.accountType && basic.accountType === 'Other' && basic.otherAccountType && basic.otherAccountType === 'Entity'">
                            <label for="otherAccountTypeEntityPhone" class="form-label">Entity Phone</label>
                            <input type="text" class="form-control" name="otherAccountTypeEntityPhone" id="otherAccountTypeEntityPhone" v-model="basic.entity.phone">
                        </div>
                    </div>
                </div>

                <!-- Name & Tax ID for ROTH/IRA/or SepIRA -->
                <div class="row" v-if="basic.accountType && basic.accountType === 'Other' && basic.otherAccountType && (basic.otherAccountType === 'IRA' || basic.otherAccountType === 'SepIRA' || basic.otherAccountType === 'ROTH')">
                    <div class="col-xl-6">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeNameField">
                            <label for="otherAccountTypeName" class="form-label">{{ basic.otherAccountType }} Name <Explanation title="This is the legal name of the investor in which name the Reiturn Units will be issued and owned. This name must match exactly the state records or other legal formation document."/></label>
                            <input type="text" class="form-control" name="otherAccountTypeName" id="otherAccountTypeName" v-model="basic.entity.name" required>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="form-field mt-md-4 pt-3" id="otherAccountTypeEINField">
                            <label for="otherAccountTypeEIN" class="form-label">{{ basic.otherAccountType }} Identification Number</label>
                            <input type="text" class="form-control" name="otherAccountTypeEIN" id="otherAccountTypeEIN" v-model="basic.entity.ein">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contact Address -->
        <h3 class="screen-title mt-3 pt-3 mb-3">Contact Address</h3>
        <div class="account-dashboard-box">
            <div class="spinner-border" v-if="!loaded"></div>
            <div v-if="loaded">
                <div class="form-field">
                    <label for="address1" class="form-label">Address Line 1</label>
                    <input type="text" class="form-control" name="address1" id="address1" placeholder="Enter your full address" required v-model="contact.address1">
                </div>
                <div class="form-field mt-3 pt-3">
                    <label for="address2" class="form-label">Address Line 2</label>
                    <input type="text" class="form-control" name="address2" id="address2" placeholder="Enter if applicable" v-model="contact.address2">
                </div>
                <div class="row">
                    <div class="col-md-6 mt-3 pt-3">
                        <div class="form-field">
                            <label for="city" class="form-label">City</label>
                            <input type="text" class="form-control" name="city" id="city" placeholder="Enter city name" required v-model="contact.city">
                        </div>
                    </div>
                    <div class="col-md-6 mt-3 pt-3">
                        <div class="form-field">
                            <label for="state" class="form-label">State</label>
                            <select class="form-control" name="state" id="state" required v-model="contact.state">
                                <option disabled selected value="">Select state</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3 pt-3">
                        <div class="form-field">
                            <label for="zip" class="form-label">ZIP Code</label>
                            <input type="text" class="form-control" name="zip" id="zip" placeholder="Enter zip code" required v-model="contact.zip">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tax Details -->
        <h3 class="screen-title mt-3 pt-3 mb-3">Tax Details</h3>
        <div class="account-dashboard-box mb-4">
            <div class="spinner-border" v-if="!loaded"></div>
            <div v-if="loaded">
                <div class="form-field">
                    <label for="ssn" class="form-label">Social Security Number</label>
                    <input type="text" class="form-control" name="ssn" id="ssn" placeholder="Enter 9 digit SSN" v-model="contact.ssn" required ref="ssnInput">
                    <p class="text-danger fw-bolder mt-2 small" v-if="contact.showErrors && contact.showErrors === 'invalid_ssn'">Please enter valid Social Security Number.</p>
                </div>
                <BirthdayField v-model="contact.birthday" class="mt-3 pt-3" ref="birthdayInput"></BirthdayField>
                <p class="text-danger fw-bolder mt-2 small" v-if="contact.showErrors && contact.showErrors === 'too_young'">You must be older than 18 years to proceed forward.</p>
            </div>
        </div>

        <div class="account-screen-actions">
            <button type="submit" :class="{'btn btn-primary': true, 'disabled': saving || !loaded, 'changed': changed}" :disabled="saving || !loaded || !changed">
                <span class="spinner-border" v-if="saving"></span>
                <span :class="{'opacity-0': saving}">Save Changes</span>
            </button>
        </div>

    </form>
</template>

<script>
import repository from "@/repository/repository";
import Explanation from "@/components/widgets/Explanation.vue";
import BirthdayField from "@/components/widgets/BirthdayField.vue";

export default {
    name: "Personal",
    components: {BirthdayField, Explanation},
    data() {
        return {
            loaded: false,
            error: false,
            changed: false,
            saving: false,
            personal: {
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
            },
            basic: {
                accreditedInvestor: "Not Accredited",
                accountType: "Individual",
                otherAccountType: "Entity",
                otherAccountRelation: "Owner",
                entity: {
                    type: "LLC",
                    name: "",
                    ein: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zip: "",
                    email: "",
                    phone: "",
                },
            },
            contact: {
                address1: null,
                address2: null,
                city: null,
                state: "",
                zip: null,
                ssn: null,
                birthday: null,
                showErrors: false,
            },
        }
    },
    created() {
        this.loadDetails();
        let propertiesToWatch = Object.keys(this.$data).filter(prop => prop !== 'changed');
        propertiesToWatch.forEach(prop => {
            this.$watch(prop, () => {
                this.changed = true;
            }, { deep: true });
        });
    },
    methods: {
        loadDetails() {
            this.loaded = false;
            this.error = false;

            repository.get("/me/personal")
                .then(response => {
                    let data = response.data.user;

                    // Personal
                    this.personal.firstName = data.party.firstName;
                    this.personal.lastName = data.party.lastName;
                    this.personal.phone = data.phone;
                    this.personal.email = data.email;
                    
                    // Basic
                    this.basic.accreditedInvestor = data.account.accreditedStatus;
                    this.basic.country = data.account.country;
                    this.basic.accountType = data.account.accountType !== 'Individual' ? 'Other' : 'Individual';
                    this.basic.otherAccountType = (data.account.accountType === 'Individual' ? null : data.account.accountType);
                    
                    // Contact
                    this.contact.address1 = data.account.address1;
                    this.contact.address2 = data.account.address2;
                    this.contact.city = data.account.city;
                    this.contact.state = data.account.state;
                    this.contact.zip = data.account.zip;
                    this.contact.ssn = data.account.taxID;
                    this.contact.birthday = data.party.birthday;
                    
                    // If entity, set Entity details
                    if(data.entity_id && data.entity) {
                        this.basic.entity.type = data.entity.entityType;
                        this.basic.entity.name = data.entity.entityName;
                        this.basic.entity.ein = data.entity.ein;
                        this.basic.entity.address1 = data.entity.address1;
                        this.basic.entity.address2 = data.entity.address2;
                        this.basic.entity.city = data.entity.city;
                        this.basic.entity.state = data.entity.state;
                        this.basic.entity.zip = data.entity.zip;
                        this.basic.entity.email = data.entity.emailAddress;
                        this.basic.entity.phone = data.entity.phone;
                    }
                    if(data.account && ['IRA', 'SepIRA', 'ROTH'].includes(data.account.accountType)) {
                        this.basic.entity.name = data.account.accountName;
                        this.basic.entity.ein = data.account.taxID;
                    }
                    if(data.link_id && data.link) {
                        this.basic.otherAccountRelation = data.link.linkType;
                    }

                    // Finish loading
                    this.loaded = true;
                    this.$nextTick(() => {
                        this.changed = false;
                    });
                })
                .catch(() => {
                    this.loaded = true;
                    this.error = true;
                })
        },
        saveChanges() {
            // Verify data
            if(!this.checkSSN()) {
                this.contact.showErrors = 'invalid_ssn';
                this.$refs.ssnInput.focus();
                return false;
            }
            if(!this.checkBirthday()) {
                this.contact.showErrors = 'too_young';
                this.$refs.birthdayInput.focus();
                return false;
            }

            this.contact.showErrors = false;
            this.error = false;
            this.saving = true;

            // Prepare payload
            let payload = {
                firstName: this.personal.firstName,
                lastName: this.personal.lastName,
                socialSecurityNumber: this.contact.ssn,
                birthday: this.contact.birthday,
                country: 'USA',
                address1: this.contact.address1,
                address2: this.contact.address2,
                city: this.contact.city,
                state: this.contact.state,
                zip: this.contact.zip,
                emailAddress: this.personal.email,
                phone: this.personal.phone,
                accountType: (this.basic.accountType === 'Individual' ? 'Individual' : this.basic.otherAccountType),
                entityType: this.basic.entity.type,
                linkType: this.basic.otherAccountRelation,
                accreditedStatus: this.basic.accreditedInvestor,
                entityName: this.basic.entity.name,
                ein: this.basic.entity.ein,
                entityAddress1: this.basic.entity.address1,
                entityAddress2: this.basic.entity.address2,
                entityCity: this.basic.entity.city,
                entityState: this.basic.entity.state,
                entityZip: this.basic.entity.zip,
                entityEmailAddress: this.basic.entity.email,
                entityPhone: this.basic.entity.phone,
            };

            // Save changes
            repository.post("/me/personal", payload)
                .then(response => {
                    console.log(response);
                    this.$store.commit("updateUser", response.data.user);
                    this.saving = false;
                    this.error = false;
                    this.$nextTick(() => {
                        this.changed = false;
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.saving = false;
                    this.error = true;
                })
        },
        checkSSN() {
            if(!this.contact.ssn) return false;
            if(/^[0-9]{9}$/.test(this.contact.ssn)) return true;
            if(/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/.test(this.contact.ssn)) return true;
            return false;
        },
        checkBirthday() {
            return this._calculateAge(new Date(this.contact.birthday)) >= 18;
        },
        _calculateAge(birthday) {
            let ageDifMs = Date.now() - birthday.getTime();
            let ageDate = new Date(ageDifMs);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        },
    },
    computed: {
        hasSecondaryParty() {
            return this.basic.accountType && this.basic.accountType === 'Other' && this.basic.otherAccountType && (this.basic.otherAccountType === 'TIC' || this.basic.otherAccountType === 'JTWROS' || this.basic.otherAccountType === 'Joint');
        },
        isEntityTrustee() {
            return this.basic.otherAccountType === 'Entity' && (this.basic.entity.type === 'Revocable Trust' || this.basic.entity.type === 'Irrevocable Trust')
        },
    },
    watch: {
        'entity.type'(newValue) {
            if(newValue === 'Revocable Trust' || newValue === 'Irrevocable Trust') {
                this.basic.otherAccountRelation = 'Trustee';
            }
        },
    },
}
</script>

<style lang="scss">
</style>