<template>
	<div id="app">
		<Header/>
		<router-view v-if="loaded"></router-view>
		<div class="loading-effect py-5 text-center" v-if="!loaded">
			<div class="spinner-border text-black-50 opacity-25"></div>
		</div>
	</div>
</template>

<script>
import Header from "@/components/Header";
import repository from "@/repository/repository";

export default {
    name: 'App',
    components: {
        Header,
    },
	data() {
        return {
            loaded: false,
		}
	},
	created() {
        // Check if there is a user already authenticated
        if(this.$store.getters.token) {
            // Verify if user is already signed in
            repository.get("/me")
                .then(response => {
                    this.$store.commit("updateUser", response.data.user);
                    this.$store.commit("updateInvestments", {
                        investment: response.data.investment,
                        refund: response.data.total_refund,
                    });
                    this.$store.commit("updateAutoInvestment", response.data.user);
                    this.$store.commit("updateUnsignedAgreements", response.data.unsigned_agreements);
                    this.loaded = true;
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit("logoutUser");
                    this.$router.replace("/login");
                    this.loaded = true;
                })
		} else {
            this.loaded = true;
        }

        // Initiate Intercom
        this.initiateIntercom();
    },
    methods: {
        initiateIntercom() {
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "xf5uxpiq"
            });
        }
    },
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&display=swap");

html, body {
	font-family: "Red Hat Display", sans-serif;
	overflow-anchor: none;

	@media (min-width: 768px) and (max-width: 991.99px) {
		font-size: 12px;
	}

	@media (min-width: 992px) and (max-width: 1199.99px) {
		font-size: 14px;
	}

	@media (max-width: 767.99px) {
		font-size: 14px;
	}
}

.intercom-lightweight-app-launcher {
    @media (max-width: 767.99px) {
        bottom: 60px !important;
        right: 15px !important;
    }
}

.container-fluid {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.order-md-6 {
	order: 6 !important;
}

.order-md-7 {
	order: 7 !important;
}

.order-md-8 {
	order: 8 !important;
}

.dropdown-menu {
	border-radius: 0.5rem;
	border: none;
	box-shadow: rgba(10,10,10,0.075) 0 0.25rem 1rem;
	padding: 0.75rem 0.5rem;

	.dropdown-item {
		font-weight: 700;
		font-size: 0.9rem;
		padding: 0.75rem 1rem;
		border-radius: 0.35rem;
		transition: 0.15s ease all;
		margin-top: 0.1rem;
		display: flex;
		align-items: center;
		color: #16193A;

		img {
			width: 1.25rem;
			height: 1.25rem;
		}

		span {
			margin-left: 0.5rem;
		}

		&:active {
			background: darken(#f2f3f4, 10%);
		}
	}
}

.form-field {
	.form-label {
		font-weight: bold;
		font-size: 1.15rem;
		margin: 0;
		display: block;
		user-select: none;
        color: #16193A;

		@media (max-width: 767px) {
			font-size: 1rem;
		}
	}
	.form-control {
		border: none;
		border-bottom: 1px solid #ddd;
		border-radius: 0;
		padding: 0.25rem 0 0.75rem 0;
		font-size: 1.15rem;
		box-shadow: none;
		&:focus {
			box-shadow: none;
			border-bottom: 1px solid #F37021;
		}

        &[disabled] {
            background: none;
            color: rgba(#16193A, 0.25);
            pointer-events: none;
            user-select: none;
        }

		@media (max-width: 767px) {
			font-size: 1rem;
		}
	}
}

.form-check {
	&.check-checkbox {
		position: relative;
		padding-left: 2.5rem;

		.form-check-input {
			border-width: 4px;
			width: 1.25rem;
			height: 1.25rem;
			box-shadow: #dcddde 0 0 0 0.15rem;
			border-color: #fff;
			transition: 0.15s ease all;
			float: none;
			position: absolute;
			top: 0.15rem;
			left: 0.15rem;
			margin: 0;
            cursor: pointer;

			&:checked {
				box-shadow: #F37021 0 0 0 0.15rem;
				background: #F37021;
				border-color: #fff;
			}
		}

		.form-check-label {
			padding: 0;
			margin: 0;
			display: block;
			cursor: pointer;

			&.label-lg {
				color: #16193A;
				font-size: 1.15rem;

				@media (max-width: 767px) {
					font-size: 1rem;
				}
			}

			strong {
				display: block;
				font-size: 1.15rem;
				font-weight: bold;
				color: #16193A;

				@media (max-width: 767px) {
					font-size: 1rem;
				}
			}

			a {
				color: #F37021;
				font-weight: 600;
			}
		}
	}
	&.button-checkbox {
		position: relative;
		padding: 0;
		margin: 0;

		.form-check-input {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;

			&:checked ~ .form-check-label {
				background: #fdf0e8;
				border-color: #fdf0e8;
				color: #F37021;
			}
		}

		.form-check-label {
			padding: 0.75rem 5rem;
			border-radius: 0.5rem;
			background: #fff;
			border: 1px solid #ddd;
			font-size: 1.15rem;
			font-weight: 500;
			cursor: pointer;
			transition: 0.15s ease all;
			user-select: none;

			&:hover {
				background: #fafbfc;
				border-color: #fafbfc;
			}

			@media (min-width: 768px) and (max-width: 991px) {
				padding: 0.5rem 2.5rem;
			}

			@media (max-width: 767px) {
				font-size: 1rem;
				padding: 0.5rem 2.5rem;
			}
		}
	}

	&.rounded-checkbox {
		.form-check-label {
			border-radius: 3rem;
		}
	}

    &.smaller-checkbox {
        .form-check-label {
            padding: 0.5rem 1.5rem;
        }
    }
}

.btn {
	border-width: 2px !important;
    position: relative;

    .spinner-border {
        width: 1rem;
        height: 1rem;
        border-width: 2px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        margin: auto;
    }

	@media (max-width: 767.99px) {
		font-weight: 700;
		font-size: 1rem !important;
	}

	&.btn-primary {
		padding: 0.75rem 2rem;
		font-size: 1.15rem;
		border-radius: 0.3rem;
		background: #F37021;
		border-color: #F37021;
		color: #fff;
		&:disabled {
			background: #F37021;
			border-color: #F37021;
		}
		&.btn-wide {
			padding: 0.75rem 4rem;
		}
		&:hover,
		&:focus {
			background: #144069;
			border-color: #144069;
		}
		&:active {
			background: #1c5a94;
			border-color: #1c5a94;
		}
	}
	&.btn-secondary {
		padding: 0.75rem 2rem;
		font-size: 1.15rem;
		border-radius: 0.3rem;
		background: #16193A;
		border-color: #16193A;
		color: #fff;
		&:disabled {
			background: lighten(#16193A, 10%);
			border-color: lighten(#16193A, 10%);
		}
		&.btn-wide {
			padding: 0.75rem 4rem;
		}
		&:hover,
		&:focus {
			background: lighten(#16193A, 10%);
			border-color: lighten(#16193A, 10%);
		}
		&:active {
			background: lighten(#16193A, 15%);
			border-color: lighten(#16193A, 15%);
		}
	}
	&.btn-link {
		font-size: 0.9rem;
		padding: 0;
		text-decoration: none;
		color: #F37021;
		font-weight: 600;
		box-shadow: none;
		&:focus,
		&:active {
			box-shadow: none !important;
		}
		&.btn-link-dark {
			color: #16193A;
			opacity: 0.5;
			transition: 0.15s ease all;
			&:hover {
				opacity: 1;
			}
			&:after {
				border-color: #16193A;
			}
		}
		&:after {
			position: relative;
			width: 0.4rem;
			height: 0.4rem;
			border-right: 2px solid #F37021;
			border-bottom: 2px solid #F37021;
			transform-origin: center;
			transform: rotate(-45deg);
			display: inline-block;
			margin-left: 0.45rem;
			margin-bottom: 0.1rem;
			content: '';
		}
	}
	&.btn-light {
		padding: 0.75rem 2rem;
		font-size: 1.15rem;
		border-radius: 0.3rem;
		background: #f2f3f4;
		border-color: #f2f3f4;
		color: #333;
	}
	&.btn-transparent-light {
		padding: 0.75rem 2rem;
		font-size: 1.15rem;
		border-radius: 0.3rem;
		background: rgba(255,255,255,0.15);
		border-color: transparent;
		color: #fff;
        &.btn-wide {
            padding: 0.75rem 4rem;
        }
		&:hover,
		&:focus {
			background: rgba(255,255,255,0.2);
		}
		&:active {
			background: rgba(255,255,255,0.25);
		}
	}
	&.btn-outline-dark {
		padding: 0.75rem 2rem;
		font-size: 1.15rem;
		border-radius: 0.3rem;
		background: #fff;
		border-color: #e1e2e3;
		color: #333;
		&:disabled {
			background: #fff;
			border-color: #e1e2e3;
			color: #333;
			opacity: 0.5;
		}
		&:hover,
		&:focus {
			background: #fafbfc;
			border-color: #fafbfc;
			color: #333;
		}
		&:active {
			background: #ddd;
			border-color: #ddd;
			color: #333;
		}
	}
	&:focus,
	&:active {
		box-shadow: rgba(10,10,10,0.1) 0 0.1rem 0.1rem !important;
	}
	&:active {
		transform-origin: center;
		transform: scale(0.975);
	}
	&.btn-sm {
		padding: 0.5rem 1rem;
		font-size: 1rem;
	}
	.btn-icon {
		width: 1.25rem;
		height: 1.25rem;
		margin: 0 0.35rem 0 -0.35rem;
		&.icon-force-white {
			filter: brightness(0) invert(1);
		}
	}
}

.text-brand {
	color: #F37021 !important;
}

.table {
	thead {
		tr th {
			border-bottom: none;
			font-size: 0.9rem;
			color: #16193A;
			padding: 1rem;
		}
	}
	tr td {
		font-size: 0.9rem;
		padding: 1rem;
		border: none;
	}
	&.table-striped {
		tbody {
			tr:nth-child(2n + 1) {

				td {
					box-shadow: none;
					background-color: #fafbfc;

					&:nth-child(1) {
						border-radius: 0.75rem 0 0 0.75rem;
					}

					&:last-child {
						border-radius: 0 0.75rem 0.75rem 0;
					}
				}
			}
		}
	}
}

.badge {
	font-weight: bold;
	padding: 0.45rem 1rem;
	border-radius: 2rem;

	&.bg-secondary {
		background: #999 !important;
	}

	&.bg-primary {
		background: #2F8E52 !important;
	}

	&.bg-danger {
		background: #e81e1e !important;
	}

	&.bg-info {
		background: #16193A !important;
		color: #fff !important;
	}
}

@keyframes pulse {
	0% {
		background-color: rgba(255,255,255,0.1);
	}
	50% {
		background-color: rgba(255,255,255,0.25);
	}
	100% {
		background-color: rgba(255,255,255,0.1);
	}
}

@keyframes pulse-dark {
	0% {
		background-color: #f2f3f4;
	}
	50% {
		background-color: darken(#f2f3f4, 5%);
	}
	100% {
		background-color: #f2f3f4;
	}
}

@keyframes pulse-light {
	0% {
		background-color: rgba(#16193A, 2.5%);
	}
	50% {
		background-color: rgba(#16193A, 5%);
	}
	100% {
		background-color: rgba(#16193A, 2.5%);
	}
}
</style>
