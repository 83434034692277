<template>
	<div class="header-wrapper">

		<!-- Header when not authenticated -->
		<header class="page-header text-md-center" v-if="!isAuthenticated">
			<div class="container">
				<router-link to="/"><img src="./../assets/logo.svg" alt="Reiturn by Birgo" class="logo ms-0 ms-md-auto"></router-link>
			</div>
		</header>

		<!-- Desktop header when authenticated -->
		<header class="page-header d-none d-md-block" v-if="isAuthenticated">

            <!-- Verification -->
            <AccountVerificationStatus/>

            <!-- Subscription Agreement Notification -->
            <SubscriptionAgreementsNotification/>

			<!-- When authenticated via admin account -->
			<div class="admin-authenticated" v-if="isAuthenticatedViaAdminPanel">
				<div class="container-fluid">
					<strong>Authenticated as an Administrator</strong>
					<router-link to="/sign-out">Exit Access</router-link>
				</div>
			</div>

			<div class="container-fluid">
				<router-link to="/" class="navbar-brand">
					<img src="./../assets/logo.svg" alt="Reiturn by Birgo" class="logo">
					<span>Investor Portal</span>
				</router-link>
				<div class="dropdown account-dropdown ms-auto">
					<a href="#" class="dropdown-toggle btn btn-light" data-bs-toggle="dropdown">
						<span class="spinner-border spinner-border-sm text-black-50 opacity-25" v-if="!user.loaded"></span>
						<span v-if="user.loaded">{{ user.credentials }}</span>
					</a>
					<div class="dropdown-menu">
						<div class="account-details">
							<span class="spinner-border spinner-border-sm text-black-50 opacity-25" v-if="!user.loaded"></span>
							<div v-if="user.loaded">
<!--								<div class="account-avatar">{{ user.credentials }}</div>-->
								<div class="account-name">{{ user.name }}</div>
								<div class="account-joined">Joined {{ user.joined }}</div>
								<div class="account-investments text-brand">${{ totalInvestments }}</div>
							</div>
							<router-link to="/invest" class="btn btn-primary btn-sm d-block w-100 mt-3 mb-2">
								<img src="./../assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
								<span>Invest Now</span>
							</router-link>
						</div>
						<router-link to="/account" class="dropdown-item">
							<img src="./../assets/icons/settings.svg" alt="Settings">
							<span>Settings</span>
						</router-link>
						<router-link to="/account/verification" class="dropdown-item">
							<img src="./../assets/icons/shield-check.svg" alt="Verification">
							<span>Verification</span>
						</router-link>
						<router-link to="/sign-out" class="dropdown-item">
							<img src="./../assets/icons/logout.svg" alt="Logout">
							<span>Sign Out</span>
						</router-link>
					</div>
				</div>
			</div>
		</header>

        <!-- Mobile Header -->
        <div class="mobile-header-wrapper d-md-none">

            <!-- Verification -->
            <AccountVerificationStatus/>

            <!-- Subscription Agreement Notification -->
            <SubscriptionAgreementsNotification/>

        </div>

		<!-- Mobile sidebar when authenticated -->
		<MobileSidebar/>

	</div>
</template>

<script>
import MobileSidebar from "@/components/MobileSidebar";
import AccountVerificationStatus from "@/components/elements/AccountVerificationStatus.vue";
import SubscriptionAgreementsNotification from "@/components/elements/SubscriptionAgreementsNotification.vue";
export default {
    name: "Header",
	components: {SubscriptionAgreementsNotification, AccountVerificationStatus, MobileSidebar},
	computed: {
        isAuthenticated() {
            return this.$store.getters.token !== null;
		},
        isAuthenticatedViaAdminPanel() {
            return this.$store.getters.adminToken;
		},
		user() {
            return this.$store.getters.user;
		},
		totalInvestments() {
            if(!this.$store.getters.investments.loaded) return "N/A";
            return (this.$store.getters.investments.total - this.$store.getters.investments.refunded).toLocaleString();
		},
    },
}
</script>

<style lang="scss">
.page-header {
	background: #fff;
	border-bottom: 1px solid #ddd;
	padding: 1.5rem 0;
	position: relative;
	z-index: 99;

	@media (max-width: 767.99px) {
		border-bottom: none;
	}

	.admin-authenticated {
		width: 100%;
		background: #F37021;
		color: rgba(255,255,255,1);
		margin: -1.5rem 0 1.5rem 0;
		padding: 0.5rem 0;

		.container-fluid {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		strong {
			font-weight: 400;
		}

		a {
			color: #fff;
			font-weight: 700;
			text-decoration: none;
		}
	}

	.logo {
		height: 2.5rem;
	}

	.container-fluid {
		display: flex;
		align-items: center;
	}

	.navbar-brand {
		display: flex;
		align-items: center;
		flex-grow: 0;

		span {
			margin-left: 1rem;
			padding-left: 1rem;
			border-left: 2px solid #ddd;
			font-weight: 600;
			color: #16193a;
			text-transform: uppercase;
			font-size: 0.8rem;
		}
	}

	.account-dropdown {
		position: relative;

		.dropdown-toggle {
			width: 3rem;
			height: 3rem;
			margin: -0.25rem 0;
			padding: 0;
			text-align: center;
			line-height: 3rem;
			font-weight: bold;
			color: #16193A;
			box-shadow: none;
			border: none;
			border-radius: 50%;
			font-size: 1rem;

			&:after {
				display: none;
			}
		}

		.dropdown-menu {
			min-width: 15rem;

			.account-details {
				margin-bottom: 0.75rem;
				padding-bottom: 0.75rem;
				border-bottom: 2px solid #eee;
				padding-left: 1rem;
				padding-right: 1rem;

				.account-avatar {
					display: block;
					width: 3rem;
					height: 3rem;
					border-radius: 50%;
					text-align: center;
					line-height: 3rem;
					font-weight: bold;
					color: #16193A;
					background: #f2f3f4;
				}

				.account-name {
					font-weight: 700;
					font-size: 1rem;
					color: #16193A;
					margin: 0.5rem 0 0 0;
				}

				.account-joined {
					font-size: 0.8rem;
					color: #999;
				}

				.account-investments {
					font-weight: bold;
					color: #F37021;
					font-size: 0.8rem;
				}
			}
		}
	}

	.nav-link {
		font-weight: 700;
		display: flex;
		align-items: center;
		font-size: 1rem;
		color: #16193a;
		transition: 0.15s ease all;
		border-radius: 0.35rem;
		padding: 0.5rem 1rem;

		@media (min-width: 768px) and (max-width: 991.99px) {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}

		&:hover {
			background: #f2f3f4;
		}

		&:active {
			background: darken(#f2f3f4, 10%);
		}

		img {
			width: 1.5rem;
			height: 1.5rem;
		}

		span {
			margin-left: 0.25rem;
		}

		&.coming-soon {
			position: relative;
			pointer-events: none;
			user-select: none;

			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: 2.5rem;
				height: 1rem;
				text-align: center;
				border-radius: 0.5rem;
				padding: 0.1rem;
				font-weight: 600;
				font-size: 0.5rem;
				text-transform: uppercase;
				content: 'SOON';
				background: #F37021;
				color: #fff;
			}

			img, span {
				opacity: 0.25;
			}
		}
	}
}
</style>