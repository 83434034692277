<template>
	<div class="step">

		<!-- Enter Email -->
		<div class="login-wrapper" v-if="!sent">
			<header class="sub-header">
				<div class="container">
					<div class="row">
						<div class="col-md-7 mx-auto">
							<h1>Reset Password</h1>
							<p>Enter your account email to continue.</p>
						</div>
					</div>
				</div>
			</header>
			<div class="container">
				<div class="row">
					<form action="#" method="post" @submit.prevent="getResetCode" class="col-md-7 mx-auto py-4 py-md-5">
						<!-- Form Steps -->
						<div class="form-field">
							<label for="email" class="form-label">Email</label>
							<input type="email" class="form-control" name="email" id="email" v-model="email" placeholder="Your email address" required>
						</div>

						<div class="password-errors mt-3" v-if="error">
							<p class="text-danger fw-bolder small m-0" v-if="error === 'missing_data'">Please enter a valid email.</p>
							<p class="text-danger fw-bolder small m-0" v-if="error === 'invalid_credentials'">Invalid email. Please try again.</p>
						</div>

						<!-- Form Navigation -->
						<div class="d-flex flex-column flex-md-row mt-3 pt-3">
							<button class="btn btn-primary" type="submit" :disabled="loading">
								<span :class="{'opacity-0': loading}">Reset Password</span>
								<span class="spinner-border" v-if="loading"></span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Confirm Code -->
		<div class="login-wrapper" v-if="sent && !confirmed">
			<header class="sub-header">
				<div class="container">
					<div class="row">
						<div class="col-md-7 mx-auto">
							<h1>Confirm Request</h1>
							<p>We have sent one-time 6-digit code to your email. Please enter it below to continue.</p>
						</div>
					</div>
				</div>
			</header>
			<div class="container">
				<div class="row">
					<form action="#" method="post" @submit.prevent="confirmResetCode" class="col-md-7 mx-auto py-4 py-md-5">
						<!-- Form Steps -->
						<div class="form-field">
							<label for="code" class="form-label">Confirmation Code</label>
							<input type="text" class="form-control" name="code" id="code" v-model="code" placeholder="6-digit confirmation code" required>
						</div>

						<div class="password-errors mt-3" v-if="error">
							<p class="text-danger fw-bolder small m-0" v-if="error === 'missing_data'">Please provide the confirmation code to continue.</p>
							<p class="text-danger fw-bolder small m-0" v-if="error === 'invalid_code'">Invalid code. Please try again.</p>
						</div>

						<!-- Form Navigation -->
						<div class="d-flex flex-column flex-md-row mt-3 pt-3">
							<button class="btn btn-primary" type="submit" :disabled="loading">
								<span :class="{'opacity-0': loading}">Confirm Request</span>
								<span class="spinner-border" v-if="loading"></span>
							</button>
						</div>

						<!-- No Account -->
						<div class="no-account mt-4">
							<p>Didn't receive the code? <a href="#" @click.prevent="getResetCode" :class="{'disabled': !allowCodeResend}">Resend Code</a></p>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- New Password -->
		<div class="login-wrapper" v-if="sent && confirmed && !completed">
			<header class="sub-header">
				<div class="container">
					<div class="row">
						<div class="col-md-7 mx-auto">
							<h1>New Password</h1>
							<p>Please enter your new password.</p>
						</div>
					</div>
				</div>
			</header>
			<div class="container">
				<div class="row">
					<form action="#" method="post" @submit.prevent="submit" class="col-md-7 mx-auto py-4 py-md-5">
						<!-- Form Steps -->
						<div class="form-field mb-4">
							<label for="password" class="form-label">New Password</label>
							<div class="password-field">
								<input :type="(showPassword ? 'text' : 'password')" class="form-control" name="password" id="password" v-model="password" placeholder="Choose a strong password" required>
								<button type="button" @click="showPassword = !showPassword" tabindex="-1">
									<img src="../../assets/icons/eye.png" alt="Eye">
								</button>
							</div>
						</div>

						<div class="form-field">
							<label for="password2" class="form-label">Confirm Password</label>
							<div class="password-field">
								<input type="password" class="form-control" name="confirm-password" id="password2" v-model="confirmPassword" placeholder="Confirm your password" required>
							</div>
							<div class="password-errors mt-3" v-if="showErrors">
								<p class="text-danger fw-bolder small m-0" v-if="password !== confirmPassword">Your passwords do not match.</p>
								<p class="text-danger fw-bolder small m-0" v-if="password && password.length < 8">Password should be at least 8 characters</p>
								<p class="text-danger fw-bolder small m-0" v-if="!matchRegex(password, /[A-Z]/)">Add at least one uppercase character (A-Z)</p>
								<p class="text-danger fw-bolder small m-0" v-if="!matchRegex(password, /[a-z]/)">Add at least one lowercase character (a-z)</p>
								<p class="text-danger fw-bolder small m-0" v-if="!matchRegex(password, /[0-9]/)">Add at least one numeric character (0-9)</p>
							</div>
							<p class="small col-md-8 fst-italic text-muted mt-3">Password should be at least 8 characters, contain an uppercase character, a lowercase character, a number and a symbol.</p>
						</div>

						<div class="password-errors mt-3" v-if="error">
							<p class="text-danger fw-bolder small m-0" v-if="error === 'missing_data'">Please provide the new password to continue.</p>
							<p class="text-danger fw-bolder small m-0" v-if="error === 'invalid_code'">Invalid verification code. Please try again.</p>
						</div>

						<!-- Form Navigation -->
						<div class="d-flex flex-column flex-md-row mt-3 pt-3">
							<button class="btn btn-primary" type="submit" :disabled="loading">
								<span :class="{'opacity-0': loading}">Save Password</span>
								<span class="spinner-border" v-if="loading"></span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Completed -->
		<div class="login-wrapper" v-if="sent && confirmed && completed">
			<header class="sub-header">
				<div class="container">
					<div class="row">
						<div class="col-md-7 mx-auto">
							<h1>Password Successfully Updated</h1>
							<p>Your password has been updated. You will be automatically redirected to your Investor Portal within few moments.</p>
						</div>
					</div>
				</div>
			</header>
			<div class="container">
				<div class="row">
					<div class="col-md-7 mx-auto py-4 py-md-5">
						<div class="spinner-border mx-auto"></div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import repository from "@/repository/repository";
import {ONBOARDING_URL} from "@/main";

export default {
    name: "ForgotPassword",
    props: [],
	data() {
        return {
            email: null,
			code: null,
			password: null,
			confirmPassword: null,
			showPassword: false,
			showErrors: false,
            error: null,
			sent: false,
			confirmed: false,
			completed: false,
			codeResendTimeout: null,
			allowCodeResend: false,
			loading: false,
		}
	},
    methods: {
		getResetCode() {
            // Start Loading Effect
            this.loading = true;
            this.error = false;

			// Do not allow code re-send for 10 seconds
			clearTimeout(this.codeResendTimeout);
			this.allowCodeResend = false;

            // Submit Request & Proceed
            repository.get('/reset-password?email=' + encodeURIComponent(this.email))
				.then(() => {
					this.sent = true;
					this.error = false;
					this.loading = false;

					clearTimeout(this.codeResendTimeout);
					let app = this;
					this.codeResendTimeout = setTimeout(function () {
						app.allowCodeResend = true;
					}, 10000);
				})
				.catch(error => {
                    this.error = error.response.data.code;
                    this.loading = false;
				})
		},
		confirmResetCode() {
            // Start Loading Effect
            this.loading = true;
            this.error = false;

            // Submit Request & Proceed
            repository.put('/reset-password', {
				email: this.email,
				code: this.code,
			})
				.then(() => {
					this.confirmed = true;
					this.error = false;
					this.loading = false;
				})
				.catch(error => {
                    this.error = error.response.data.code;
                    this.loading = false;
				})
		},
		submit() {
			// Verify Password
			if(!this.checkPassword()) {
				this.showErrors = true;
				return false;
			}

            // Start Loading Effect
            this.loading = true;
            this.error = false;

            // Submit Request & Proceed
            repository.post('/reset-password', {
				email: this.email,
				code: this.code,
				password: this.password,
			})
				.then(response => {
					let app = this;
					this.completed = true;
					if(response.data.user.verified === 0) {
						let onboardingLink = ONBOARDING_URL + "/auth?t=" + response.data.token;
						setTimeout(function () {
							window.location.href = onboardingLink;
						}, 1500);
					} else {
						setTimeout(function () {
							app.$router.push("/auth?t=" + response.data.token);
						}, 1500);
					}
				})
				.catch(error => {
                    this.error = error.response.data.code;
                    this.loading = false;
				})
		},
		matchRegex(input, regex) {
			return regex.test(input);
		},
		checkPassword() {
			if(!this.password) return false;
			if(this.password.length < 8) return false;
			if(!this.matchRegex(this.password, /[A-Z]/)) return false;
			if(!this.matchRegex(this.password, /[a-z]/)) return false;
			if(!this.matchRegex(this.password, /[0-9]/)) return false;
			return this.password === this.confirmPassword;
		},
	},
}
</script>


<style lang="scss">
.no-account {
	p {
		a.disabled {
			color: #999;
			opacity: 0.5;
			cursor: default;
			pointer-events: none;
			user-select: none;
		}
	}
}

.form-field {
	.form-label {
		.btn.btn-link {
			float: right;

			&:after {
				display: none;
			}
		}
	}
}
</style>