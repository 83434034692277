<template>
	<img src="./../../assets/icons/refresh.svg" class="auto-investment-explanation" data-bs-toggle="tooltip" title="This transaction was processed automatically" alt="This transaction was processed automatically" ref="info">
</template>

<script>
import { Tooltip } from 'bootstrap'

export default {
    name: "AutoInvestmentExplanation",
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        })
    },
}
</script>

<style lang="scss">
.auto-investment-explanation {
	display: inline-block;
	cursor: pointer;
	width: 1rem;
    padding: 0.15rem;
    margin-top: -0.25rem;
    opacity: 0.5;

	@media (max-width: 767px) {
		opacity: 0.5;
		width: 1rem;
	}
}
</style>