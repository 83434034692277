<template>
	<img src="./../../assets/icons/question-circle.svg" :class="{'explanation': true}" data-bs-toggle="tooltip" :title="title" :alt="title" ref="info">
</template>

<script>
import { Tooltip } from 'bootstrap'

export default {
    name: "Explanation",
	props: ['title'],
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        })
    },
}
</script>

<style lang="scss">
.explanation {
	display: inline-block;
	cursor: pointer;
	width: 1.25rem;

	@media (max-width: 767px) {
		opacity: 0.5;
		width: 1rem;
	}
}

.tooltip {
	.tooltip-inner {
		background: #666;
		font-weight: bold;
		padding: 0.5rem 1rem;
		text-align: left;
		min-width: 15rem;
	}
	.tooltip-arrow {
		display: none;
	}
}
</style>