<template>
	<div :class="{'mobile-sidebar-wrapper d-md-none': true, 'active': mobileSidebar}">
		<header class="mobile-sidebar" @click="hideMobileSidebar">
			<div class="account-sidebar">
				<div class="account-avatar">
					<span class="spinner-border spinner-border-sm text-black-50 opacity-25" v-if="!user.loaded"></span>
					<span v-if="user.loaded">{{ user.credentials }}</span>
				</div>
				<div class="account-details">
					<span class="spinner-border spinner-border-sm text-black-50 opacity-25" v-if="!user.loaded"></span>
					<div v-if="user.loaded">
						<div class="account-name">{{ user.name }}</div>
						<div class="account-joined">Joined {{ user.joined }}</div>
						<div class="account-investments text-brand">${{ totalInvestments }}</div>
					</div>
				</div>
			</div>
			<router-link to="/invest" class="btn btn-primary btn-sm d-block w-100 mt-3">
				<img src="./../assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
				<span>Invest Now</span>
			</router-link>
			<ul class="navbar-nav mt-4">
				<li class="nav-item">
					<router-link to="/" class="nav-link">
						<img src="./../assets/icons/home.svg" alt="Home">
						<span>Home</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/invest" class="nav-link">
						<img src="./../assets/icons/dollar.svg" alt="Dollar">
						<span>Invest</span>
					</router-link>
				</li>
                <li class="nav-item">
                    <router-link to="/performance" class="nav-link">
                        <img src="./../assets/icons/performance.svg" alt="Performance">
                        <span>Performance</span>
                    </router-link>
                </li>
				<li class="nav-item">
					<router-link to="/transactions" class="nav-link">
						<img src="./../assets/icons/wallet.svg" alt="Wallet">
						<span>Transactions</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/documents" class="nav-link">
						<img src="./../assets/icons/document-alt.svg" alt="Document">
						<span>Documents</span>
                        <i v-if="unsignedAgreements > 0">{{ unsignedAgreements }}</i>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/portfolio" class="nav-link">
						<img src="./../assets/icons/pie-chart.svg" alt="Pie Chart">
						<span>Portfolio</span>
					</router-link>
				</li>
			</ul>
			<hr>
			<ul class="navbar-nav">
				<li class="nav-item">
					<router-link to="/impact" class="nav-link">
						<img src="./../assets/icons/earth.svg" alt="Impact">
						<span>Impact</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/calculator" class="nav-link">
						<img src="./../assets/icons/calculator.svg" alt="Calculator">
						<span>Calculator</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/insights" class="nav-link">
						<img src="./../assets/icons/insights.svg" alt="Insights">
						<span>Insights</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/faq" class="nav-link">
						<img src="./../assets/icons/users.svg" alt="Users">
						<span>FAQ</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/legal" class="nav-link">
						<img src="./../assets/icons/info.svg" alt="Info">
						<span>Legal Notices</span>
					</router-link>
				</li>
            </ul>
            <hr>
            <ul class="navbar-nav">
				<li class="nav-item">
					<router-link to="/account" class="nav-link">
						<img src="./../assets/icons/settings.svg" alt="Settings">
						<span>Account Settings</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/sign-out" class="nav-link">
						<img src="./../assets/icons/logout.svg" alt="Logout">
						<span>Sign Out</span>
					</router-link>
				</li>
			</ul>
		</header>
		<div class="mobile-sidebar-overlay" @click="hideMobileSidebar"></div>
	</div>
</template>

<script>
export default {
	name: "MobileSidebar",
	computed: {
		isAuthenticated() {
			return this.$store.getters.token !== null;
		},
		user() {
			return this.$store.getters.user;
		},
		totalInvestments() {
			if(!this.$store.getters.investments.loaded) return "N/A";
            return (this.$store.getters.investments.total - this.$store.getters.investments.refunded).toLocaleString();
		},
		mobileSidebar() {
			return this.$store.getters.mobileSidebar;
		},
        unsignedAgreements() {
            return this.$store.getters.unsignedAgreements;
        },
	},
	methods: {
		hideMobileSidebar() {
			this.$store.commit("hideMobileSidebar");
		}
	},
}
</script>

<style lang="scss" scoped>
.mobile-sidebar-wrapper {
	position: fixed;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: 0.15s ease all;

	.mobile-sidebar {
		position: fixed;
		top: 0;
		left: 0;
		width: 300px;
		height: 100%;
		background: #fff;
		z-index: 1;
		padding: 1.5rem;
		opacity: 0;
		visibility: hidden;
        overflow-x: hidden;
		transition: 0.3s ease all;
		transform: translateX(-200px);

		.account-sidebar {
			display: flex;
			align-items: center;

			.account-avatar {
				width: 3rem;
				height: 3rem;
				margin: -0.25rem 0;
				padding: 0;
				text-align: center;
				line-height: 3rem;
				font-weight: bold;
				background: #f2f3f4;
				color: #16193A;
				box-shadow: none;
				border: none;
				border-radius: 50%;
				font-size: 1rem;

				&:after {
					display: none;
				}
			}

			.account-details {
				padding-left: 1rem;

				.account-avatar {
					display: block;
					width: 3rem;
					height: 3rem;
					border-radius: 50%;
					text-align: center;
					line-height: 3rem;
					font-weight: bold;
					color: #16193A;
					background: #f2f3f4;
				}

				.account-name {
					font-weight: 700;
					font-size: 1rem;
					color: #16193A;
					margin: 0.5rem 0 0 0;
				}

				.account-joined {
					font-size: 0.8rem;
					color: #999;
				}

				.account-investments {
					font-weight: bold;
					color: #F37021;
					font-size: 0.8rem;
				}
			}
		}

		hr {
			margin: 1rem 0;
			opacity: 1;
			border-color: #eee;
		}

		.nav-link {
			font-weight: 700;
			display: flex;
			align-items: center;
			font-size: 1rem;
			color: #16193a;
			transition: 0.15s ease all;
			border-radius: 0.35rem;
			padding: 0.75rem 1rem;
			user-select: none;
            position: relative;

			&:hover,
			&.router-link-exact-active {
				background: #f2f3f4;
			}

			&:active {
				background: darken(#f2f3f4, 10%);
			}

			img {
				width: 1.5rem;
				height: 1.5rem;
			}

			span {
				margin-left: 0.5rem;
			}

            i {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: 1rem;
                width: 1.5rem;
                height: 1.5rem;
                line-height: 1.5rem;
                text-align: center;
                border-radius: 1rem;
                font-weight: bold;
                font-size: 0.9rem;
                text-transform: uppercase;
                background: #F37021;
                color: #fff;
                font-style: normal;
            }

			&.coming-soon {
				position: relative;
				pointer-events: none;
				user-select: none;

				&:after {
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					right: 1rem;
					width: 2.5rem;
					height: 1rem;
					text-align: center;
					border-radius: 0.5rem;
					padding: 0.1rem;
					font-weight: 600;
					font-size: 0.5rem;
					text-transform: uppercase;
					content: 'SOON';
					background: #F37021;
					color: #fff;
				}

				img, span {
					opacity: 0.25;
				}
			}
		}
	}

	.mobile-sidebar-overlay {
		position: fixed;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgba(10,10,10,0.35);
		backdrop-filter: blur(2px);
		z-index: 0;
		opacity: 0;
		visibility: hidden;
		transition: 0.15s ease all;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.mobile-sidebar {
			opacity: 1;
			visibility: visible;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			transform: translate3d(0,0,0);
		}

		.mobile-sidebar-overlay {
			opacity: 1;
			visibility: visible;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			transform: translate3d(0,0,0);
		}
	}
}
</style>