<template>
	<div class="step">
		<div class="login-wrapper" v-if="!preAuthenticate">
			<header class="sub-header">
				<div class="container">
					<div class="row">
						<div class="col-md-7 mx-auto">
							<h1>Welcome to Reiturn</h1>
							<p>Enter your account details below to proceed to your Investor Portal!</p>
						</div>
					</div>
				</div>
			</header>
			<div class="container">
				<div class="row">
					<form action="#" method="post" @submit.prevent="submit" class="col-md-7 mx-auto py-4 py-md-5">
						<!-- Form Steps -->
						<div class="form-field">
							<label for="email" class="form-label">Email</label>
							<input type="email" class="form-control" name="email" id="email" v-model="email" placeholder="Your email address" required>
						</div>
						<div class="form-field mt-3 pt-3">
							<label for="password" class="form-label">Password <router-link to="/forgot-password" class="btn btn-link" tabindex="-1">Forgot Password?</router-link></label>
							<div class="password-field">
								<input :type="(showPassword ? 'text' : 'password')" class="form-control" name="password" id="password" v-model="password" placeholder="Your password" required>
								<button type="button" @click="showPassword = !showPassword" tabindex="-1">
									<img src="../../assets/icons/eye.png" alt="Eye">
								</button>
							</div>
						</div>

						<div class="password-errors mt-3" v-if="error">
							<p class="text-danger fw-bolder small m-0" v-if="error === 'missing_data'">Please enter email and password.</p>
							<p class="text-danger fw-bolder small m-0" v-if="error === 'invalid_credentials'">Invalid email or password. Please try again.</p>
							<p class="text-danger fw-bolder small m-0" v-if="error === 'onboarding_incomplete'">You haven't completed the onboarding process.</p>
						</div>

						<!-- Form Navigation -->
						<div class="d-flex flex-column flex-md-row mt-3 pt-3">
							<button class="btn btn-primary" type="submit" :disabled="loading" v-if="!onboardingLink">
								<span :class="{'opacity-0': loading}">Sign In</span>
								<span class="spinner-border" v-if="loading"></span>
							</button>
							<a :href="onboardingLink" class="btn btn-secondary" v-if="onboardingLink">Finish Onboarding Process</a>
						</div>

						<!-- No Account -->
						<div class="no-account mt-4">
							<p>Don't have an account, but want to invest? <a :href="onboardingEndPoint">Create an account</a> </p>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="loading-effect py-5 text-center" v-if="preAuthenticate">
			<div class="spinner-border text-black-50 opacity-25"></div>
		</div>
	</div>
</template>

<script>
import repository from "@/repository/repository";
import {ONBOARDING_URL} from "@/main";

export default {
    name: "AuthForm",
    props: ['preAuthenticate',],
	data() {
        return {
            token: this.$store.getters.token,
            email: null,
			password: null,
            showPassword: false,
            error: null,
			onboardingLink: null,
			loading: false,
		}
	},
	created() {
        if(this.preAuthenticate) {
            repository.get("/me", {
                headers: {
                    'Authorization': 'Bearer ' + this.preAuthenticate,
				}
            })
                .then(response => {
                    this.$store.commit("updateAuth", {
                        token: this.preAuthenticate,
                    });
                    this.$store.commit("updateUser", response.data.user);
                    this.$store.commit("updateInvestments", {
                        investment: response.data.investment,
                        refund: response.data.total_refund,
                    });
                    this.$store.commit("updateAutoInvestment", response.data.user);
                    this.$store.commit("updateUnsignedAgreements", response.data.unsigned_agreements);
                    this.loaded = true;
                    this.$router.replace("/");
                })
                .catch((err) => {
                    console.log("Couldn't find this account");
                    console.error(err);
                    this.$store.commit("logoutUser");
                    this.loaded = false;
                    this.$router.replace("/login");
                })
		}
    },
    methods: {
        submit() {
            // Start Loading Effect
            this.loading = true;
            this.error = false;

            // Submit Request & Proceed
            repository.post('/login', {
                email: this.email,
                password: this.password,
            })
				.then(response => {
                    console.log(response);
                    if(response.data.user.verified === 0) {
                        this.error = 'onboarding_incomplete';
                        this.loading = false;
                        this.onboardingLink = ONBOARDING_URL + "/auth?t=" + response.data.token;
                        return;
					}

                    // Update Auth Details
                    this.$store.commit("updateAuth", {
                        token: response.data.token,
                    });

                    // Update User
					this.$store.commit("updateUser", response.data.user);
                    this.$store.commit("updateInvestments", {
                        investment: response.data.investment,
                        refund: response.data.total_refund,
                    });
					this.$store.commit("updateAutoInvestment", response.data.user);
                    this.$store.commit("updateUnsignedAgreements", response.data.unsigned_agreements);

                    // Proceed to Home page
                    this.loading = false;
                    if(this.redirect) {
                        this.$router.replace(this.redirect);
					} else {
                        this.$router.replace("/");
					}
				})
				.catch(error => {
                    this.error = error.response.data.code;
                    this.loading = false;
				})
		},
	},
	computed: {
        onboardingEndPoint() {
            return ONBOARDING_URL;
		}
	},
}
</script>


<style lang="scss">
.sub-header {
	background: #f1f6f8;
	padding: 5rem 0 3.5rem 0;

	@media (max-width: 767.99px) {
		padding: 1rem 0 0 0;
		background: #fff;
	}

	h1 {
		font-weight: bold;
		color: #16193a;
		font-size: 3rem;
		margin: 0 0 1rem 0;

		@media (max-width: 767px) {
			font-size: 2rem;
		}
	}

	p {
		color: #16193A;
		font-size: 1.25rem;
		margin: 0;

		@media (max-width: 767px) {
			font-size: 1rem;
		}

		a {
			color: #1697d6;
			text-decoration: none;
		}
	}
}

.password-field {
	position: relative;

	button {
		position: absolute;
		bottom: 0;
		right: 0;
		top: 0;
		margin: auto;
		background: none;
		border: none;
		box-shadow: none;
		outline: none;
		width: 3rem;
		height: 3rem;
		padding: 0.25rem;
		opacity: 0.25;
		transition: 0.2s ease all;

		&:hover {
			opacity: 1;
		}

		img {
			width: 1.5rem;
		}
	}
}

.step {
	h4 {
		font-size: 1.75rem;
		font-weight: bold;
		color: #16193A;

		@media (max-width: 767px) {
			font-size: 1.25rem;
		}
	}
}

.no-account {
	p {
		color: #16193A;
		font-size: 1rem;
		margin: 0;

		a {
			color: #F37021;
			font-weight: bold;
			text-decoration: none;
		}
	}
}

.form-field {
	.form-label {
		.btn.btn-link {
			float: right;

			&:after {
				display: none;
			}
		}
	}
}
</style>