<template>
	<div class="dashboard-wrapper">

		<!-- Desktop Sidebar -->
		<DesktopSidebar/>

		<!-- Dashboard Content -->
		<div class="dashboard-content">
			<router-view/>
		</div>

	</div>
</template>

<script>
import DesktopSidebar from "@/components/DesktopSidebar";
export default {
    name: "DashboardWrapper",
	components: {DesktopSidebar},
	data() {
        return {}
    },
}
</script>

<style lang="scss">
.dashboard-wrapper {
	display: flex;
	min-height: calc(100vh - 5.5rem - 1px);

	@media (max-width: 767.99px) {
		min-height: 100vh;
        flex-direction: column;
	}

	.dashboard-content {
		background: #f1f6f8;
		flex-grow: 1;
		padding-bottom: 3.75rem;
        width: calc(100% - 20rem);

        @media (max-width: 767.99px) {
            width: 100%;
        }
	}
}
</style>