<template>
	<div class="screen">

		<!-- Mobile Header -->
		<MobileHeader :dark="true" :menu-toggle="true" title="Home"/>

		<div class="container-fluid">

			<!-- Investment Details & Current Fund -->
			<div class="row">
				<div class="col-xxl-6 mb-md-4">

					<!-- Investment Details -->
					<InvestmentsSummary/>

				</div>
				<div class="col-xxl-6 mb-4">

					<!-- Current Fund -->
<!--					<OfferDetails/>-->
                    <div class="row">
                        <div class="col-xl-6 col-xxl-12 mb-md-4">

                            <!-- Invest Now -->
                            <div class="invest-now">
                                <img src="@/assets/finish.jpg" alt="">
                                <div>
                                    <h4>Invest your preferred amount</h4>
                                    <p>Become a property owner for a low minimum of $5,000. Receive immediate access to property and fund performance.</p>
                                    <router-link to="/invest" class="btn btn-primary d-inline-flex align-items-center mt-4 disabled" disabled="disabled">
                                        <img src="@/assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
                                        <span>Invest Today</span>
                                    </router-link>
                                </div>
                            </div>

                        </div>
                        <div class="col-xl-6 col-xxl-12 ">

                            <!-- Auto-investment Reminder -->
                            <AutoInvestmentReminder></AutoInvestmentReminder>

                        </div>
                    </div>



				</div>
			</div>

			<!-- Quick Actions -->
			<h3 class="screen-title">Quick actions</h3>
			<div class="scrollable-boxes mt-3 mb-md-3 pb-3 pb-md-0">
				<ActionBox link="/invest" title="Add Funds" icon="dollar.svg"/>
				<ActionBox link="/calculator" title="Calculate my growth" icon="calculator.svg"/>
                <ActionBox link="/performance" title="View your performance" icon="performance.svg"/>
				<ActionBox link="/documents" title="Sign required documents" icon="document-alt.svg"/>
				<ActionBox link="/invest" title="Configure auto-investing" icon="refresh.svg"/>
			</div>

            <!-- Portfolio -->
            <PortfolioOverview></PortfolioOverview>


		</div>
	</div>
</template>

<script>
import ActionBox from "@/components/widgets/ActionBox.vue";
import MobileHeader from "@/components/MobileHeader.vue";
import InvestmentsSummary from "@/components/elements/InvestmentsSummary.vue";
import AutoInvestmentReminder from "@/components/widgets/AutoInvestmentReminder.vue";
import PortfolioOverview from "@/components/widgets/PortfolioOverview.vue";

export default {
    name: "Home",
    components: {PortfolioOverview, AutoInvestmentReminder, InvestmentsSummary, MobileHeader, ActionBox},
}
</script>

<style lang="scss">
.screen {
	padding: 1.5rem 0;

	@media (max-width: 767.99px) {
		padding: 0;
	}

	.screen-title {
		font-size: 1.25rem;
		color: #16193A;
		font-weight: bold;
	}
}

.scrollable-boxes {
	display: flex;
	flex-wrap: wrap;

	@media (max-width: 767.99px) {
		width: calc(100% + 3rem);
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		padding: 0 0.5rem 0 1.5rem;
		flex-wrap: nowrap;
		overflow-y: hidden;
	}
}
</style>

<style lang="scss" scoped>
.invest-now {
    display: flex;
    align-items: flex-start;
    text-align: left;
    padding: 2.5rem;

    h4 {
        margin-top: 0;
    }

    > img {
        flex-shrink: 0;
        margin-right: 2.5rem;
        width: 6rem;
    }

	@media (max-width: 767.99px) {
		margin-bottom: 0;
		height: auto !important;
        padding: 2.5rem 1.5rem;
	}
}
</style>