<template>
	<div class="home-investment-details h-100">
		<div v-if="!investments.loaded || (investments.loaded && !investments.error)">
			<div class="row">
				<div class="col-md-6">
					<h5>Your Account Value</h5>
					<div :class="{'investment-amount': true, 'loading': !investments.loaded}">
						<span class="spinner-border text-white opacity-25" v-if="!investments.loaded"></span>
						<span>$</span>
						<strong>{{ investments.loaded ? totalAccountValue.toLocaleString() : 0 }}</strong>
						<span>.00</span>
					</div>
                    <p :class="{'small text-white mt-2 mb-0': true, 'opacity-25': investments.loaded, 'opacity-0': !investments.loaded}">* The account value above includes your pending & upcoming investments.</p>
					<router-link to="/invest" class="btn btn-primary d-inline-flex align-items-center mt-4 disabled" disabled="disabled">
						<img src="./../../assets/icons/dollar.svg" alt="Dollar" class="btn-icon icon-force-white">
						<span>Add Funds</span>
					</router-link>
				</div>
				<div class="col-md-5 ms-auto mt-4 mt-md-0">
					<h5>Funds Breakdown</h5>
					<div :class="{'investment-breakdown': true, 'loading': !investments.loaded}">
						<div class="breakdown-item">
							<span>Funded</span>
							<strong>${{ investments.loaded ? investments.cleared.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
						</div>
						<div class="breakdown-item" v-if="investments.manually_refunded > 0">
							<span>Returned Investments</span>
							<strong class="text-danger">-${{ investments.loaded ? investments.manually_refunded.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
						</div>
						<div class="breakdown-item">
							<span>Pending</span>
							<strong>${{ investments.loaded ? investments.pending.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
						</div>
						<div class="breakdown-item">
							<span>Reinvested</span>
							<strong>${{ investments.loaded ? investments.reinvested.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
						</div>
						<div class="breakdown-item">
							<span>Distributed</span>
							<strong>${{ investments.loaded ? investments.distributed.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>
						</div>
						<!--										<div class="breakdown-item">-->
						<!--											<span>Cancelled</span>-->
						<!--											<strong>-${{ investments.loaded ? investments.cancelled.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>-->
						<!--										</div>-->
						<!--										<div class="breakdown-item">-->
						<!--											<span>Refunded</span>-->
						<!--											<strong>-${{ investments.loaded ? investments.refunded.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00' }}</strong>-->
						<!--										</div>-->
					</div>
					<div class="d-flex justify-content-end">
						<router-link to="/transactions" class="btn btn-link mt-1">View Transactions</router-link>
					</div>
				</div>
			</div>
			<div class="investment-performance">
				<h4>Your Portfolio</h4>
				<p>Your portfolio is already powered by dozens of projects across the country.</p>
				<router-link to="/portfolio" class="btn btn-transparent-light d-inline-flex align-items-center">
					<img src="./../../assets/icons/pie-chart.svg" alt="Pie Chart" class="btn-icon icon-force-white">
					<span>View Portfolio</span>
				</router-link>
			</div>
		</div>
		<div v-if="investments.loaded && investments.error">
			<div class="investment-error">
				<img src="./../../assets/icons/cancel.png" alt="Cancel" class="error-icon">
				<h4>An error happened</h4>
				<p>We couldn't fetch your account's investments summary. Please try again by refreshing the page.</p>
			</div>
		</div>
	</div>
</template>

<script>
import repository from "@/repository/repository";

export default {
	name: "InvestmentsSummary",
	data() {
		return {
			investments: {
				total: null,
				cleared: null,
				pending: null,
				cancelled: null,
				refunded: null,
                manually_refunded: null,
				reinvested: null,
				distributed: null,
				loaded: false,
				error: false,
			},
		}
	},
	created() {
		this.loadInvestments();
	},
	methods: {
		loadInvestments() {
			repository.get("/investments/summary")
				.then(response => {
					this.investments = response.data.summary;
					this.investments.error = false;
					this.investments.loaded = true;
				})
				.catch(() => {
					this.investments.error = true;
					this.investments.loaded = true;
				});
		},
	},
    computed: {
        totalAccountValue() {
            return (this.investments.cleared + this.investments.reinvested) - this.investments.manually_refunded;
        },
    },
}
</script>

<style lang="scss" scoped>
.home-investment-details {
	display: block;
	position: relative;
	padding: 2.5rem;
	background: #16193A;
	border-radius: 0.5rem;

	@media (max-width: 767.99px) {
		border-radius: 0;
		padding: 1rem 1.5rem 2.5rem 1.5rem;
		width: calc(100% + 3rem);
		margin: 0 -1.5rem;
	}

	h5 {
		font-size: 1rem;
		color: rgba(255,255,255,0.45);
		font-weight: 600;
	}

	.investment-amount {
		display: flex;
		align-items: flex-end;
		color: #fff;

		&.loading {
			color: transparent;
			position: relative;
			user-select: none;

			.spinner-border {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				border-width: 2px;
				margin: auto;
			}
		}

		span {
			font-size: 1.5rem;
			font-weight: 400;
			line-height: 1;
			display: block;
			margin-bottom: 0.3rem;
		}

		strong {
			font-size: 2.75rem;
			font-weight: 600;
			line-height: 1;
			display: block;
		}
	}

	.investment-breakdown {
		.breakdown-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 0.8rem;
			color: rgba(255,255,255,0.25);
			margin-top: 0.5rem;

			strong {
				color: #fff;
			}
		}

		&.loading {
			.breakdown-item {
				strong {
					user-select: none;
					color: transparent;
					background: rgba(255,255,255,0.1);
					border-radius: 0.5rem;
					animation: pulse 1s infinite;
				}
			}
		}
	}

	.investment-performance {
		padding-top: 2rem;
		margin-top: 2rem;
		border-top: 1px solid rgba(255,255,255,0.1);

		h4 {
			font-size: 1.15rem;
			font-weight: 700;
			color: #fff;
		}

		p {
			color: rgb(255,255,255,0.5);
			font-size: 0.9rem;
			width: 50%;

			@media (max-width: 767.99px) {
				width: 100%;
			}
		}
	}

	.investment-error {
		img.error-icon {
			width: 4rem;
			height: 4rem;
			margin-right: 0.5rem;
		}

		h4 {
			font-size: 1.15rem;
			color: #fff;
			font-weight: bold;
			margin: 1rem 0 0.5rem 0
		}

		p {
			color: rgb(255,255,255,0.5);
			font-size: 0.9rem;
			width: 100%;
		}
	}
}
</style>