import Vue from "vue";
import VueRouter from "vue-router";
import {store} from "@/store";
import Home from "@/views/Home.vue";
import DashboardWrapper from "@/components/DashboardWrapper";
import Performance from "@/views/Performance.vue";
import Invest from "@/views/Invest.vue";
import Portfolio from "@/views/portfolio/Portfolio.vue";
import News from "@/views/resources/Insights.vue";
import Transactions from "@/views/Transactions.vue";
import Legal from "@/views/legal/Legal.vue";
import TermsOfUse from "@/views/legal/TermsOfUse";
import EftDisclosure from "@/views/legal/EftDisclosure";
import LegalWrapper from "@/views/legal/LegalWrapper";
import PrivacyPolicy from "@/views/legal/PrivacyPolicy";
import NewsArticle from "@/views/resources/InsightsArticle.vue";
import Documents from "@/views/Documents.vue";
import Calculator from "@/views/resources/Calculator.vue";
import Auth from "@/views/auth/Auth.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import FAQ from "@/views/resources/FAQ.vue";
import InvestmentDisclosure from "@/views/legal/InvestmentDisclosure";
import PortfolioPropertyDetails from "@/views/portfolio/PortfolioPropertyDetails.vue";
import AdminAuth from "@/views/auth/AdminAuth.vue";
import Verification from "@/views/account/Verification.vue";
import Personal from "@/views/account/Personal.vue";
import AccountWrapper from "@/views/account/AccountWrapper.vue";
import BankAccount from "@/views/account/BankAccount.vue";
import Distributions from "@/views/account/Distributions.vue";
import Security from "@/views/account/Security.vue";
import Impact from "@/views/resources/Impact.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: DashboardWrapper,
        children: [
            {
                path: '/',
                component: Home,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/performance',
                component: Performance,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/invest',
                component: Invest,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/portfolio',
                component: Portfolio,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/portfolio/:id',
                component: PortfolioPropertyDetails,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/impact',
                component: Impact,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/insights',
                component: News,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/insights/:id',
                component: NewsArticle,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/transactions',
                component: Transactions,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/documents',
                component: Documents,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/calculator',
                component: Calculator,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/legal',
                component: Legal,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/faq',
                component: FAQ,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/account',
                component: AccountWrapper,
                children: [
                    {
                        path: '/account',
                        component: Personal,
                        meta: {
                            requireAuth: true,
                            requireGuest: false,
                        },
                    },
                    {
                        path: '/account/bank-account',
                        component: BankAccount,
                        meta: {
                            requireAuth: true,
                            requireGuest: false,
                        },
                    },
                    {
                        path: '/account/distributions',
                        component: Distributions,
                        meta: {
                            requireAuth: true,
                            requireGuest: false,
                        },
                    },
                    {
                        path: '/account/verification',
                        component: Verification,
                        meta: {
                            requireAuth: true,
                            requireGuest: false,
                        },
                    },
                    {
                        path: '/account/security',
                        component: Security,
                        meta: {
                            requireAuth: true,
                            requireGuest: false,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        component: Auth,
        meta: {
            requireAuth: false,
            requireGuest: true,
        },
        props: (route) => ({ redirect: route.query.redirect }),
    },
    {
        path: '/auth',
        component: Auth,
        meta: {
            requireAuth: false,
            requireGuest: false,
        },
        props: (route) => ({ preAuthenticate: route.query.t }),
    },
    {
        path: '/admin-access',
        component: AdminAuth,
        meta: {
            requireAuth: false,
            requireGuest: false,
        },
        props: (route) => ({ userToken: route.query.u, adminToken: route.query.a }),
    },
    {
        path: '/sign-out',
        component: function () {
            store.commit("logoutUser");
            store.commit("logoutAdmin");
            router.replace("/login");
        },
        meta: {
            requireAuth: true,
            requireGuest: false,
        },
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        meta: {
            requireAuth: false,
            requireGuest: true,
        },
    },
    {
        path: '/',
        component: LegalWrapper,
        children: [
            {
                path: '/legal/tos',
                component: TermsOfUse,
                meta: {
                    requireAuth: false,
                    requireGuest: false,
                },
            },
            {
                path: '/legal/privacy-policy',
                component: PrivacyPolicy,
                meta: {
                    requireAuth: false,
                    requireGuest: false,
                },
            },
            {
                path: '/legal/eft-disclosure',
                component: EftDisclosure,
                meta: {
                    requireAuth: false,
                    requireGuest: false,
                },
            },
            {
                path: '/legal/investment-disclosure',
                component: InvestmentDisclosure,
                meta: {
                    requireAuth: false,
                    requireGuest: false,
                },
            },
        ],
    },
]

export const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (store.getters.token == null) {
            // If no user is signed in, simply redirect to /login
            next({
                path: "/login",
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            // Continue if ok
            next();
        }
    } else if (to.matched.some(record => record.meta.requireGuest)) {
        if (store.getters.token != null) {
            // If there is a user, simply redirect to home
            next({
                path: "/"
            });
        } else {
            next();
        }
    } else {
        // Simply continue
        next();
    }
});