<template>
	<router-link to="/documents" :class="{'subscription-agreements-notification': true, 'active': unsignedAgreements}">
        <span v-if="unsignedAgreements">There are subscription agreements awaiting your signature. Kindly sign them to ensure the completion of your investments.</span>
	</router-link>
</template>

<script>
export default {
	name: "SubscriptionAgreementsNotification",
    components: {},
    computed: {
        unsignedAgreements() {
            return this.$store.getters.unsignedAgreements;
        },
    },
}
</script>

<style lang="scss" scoped>
.subscription-agreements-notification {
    display: block;
    background: #16193A;
    padding: 0.5rem;
    text-align: center;
    font-weight: 700;
    font-size: 0.9rem;
    color: #fff;
    text-decoration: none;
    height: 2.35rem;
    opacity: 0;
    visibility: hidden;
    margin-top: -2.35rem;
    transition: 0.15s ease all;

    &:hover {
        background: lighten(#161931, 10%);
    }

    @media (max-width: 767.99px) {
        height: auto;
        margin-bottom: 0;
        margin-top: 0;
        display: none;
        background: lighten(#161931, 10%);
        text-align: left;
        padding: 0.75rem 1.5rem;

        &:hover {
            background: lighten(#161931, 15%);
        }
    }

    &.active {
        opacity: 1;
        visibility: visible;
        margin-top: -1.5rem;
        margin-bottom: 1.5rem;

        @media (max-width: 767.99px) {
            margin-bottom: 0;
            margin-top: 0;
            display: block;
        }
    }

    span {
        display: block;
    }
}
</style>