<template>
	<div :class="{'property-box': true, 'loading': loading === true, 'home-property-box': home }" @click.prevent="triggerPopup">
		<div class="property-thumbnail">
			<img :src="property.thumbnail.image" :alt="property.name" class="img-fluid" v-if="property">
		</div>
		<div class="property-details">
			<div class="property-title">{{ property ? property.name : 'Loading' }}</div>
			<div class="property-address">{{ property ? property.address : 'Loading' }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PropertyBox",
	props: ['property', 'openPopup', 'loading', 'home'],
	computed: {
	},
	methods: {
		triggerPopup() {
			this.openPopup(this.property.id);
		}
	},
}
</script>

<style lang="scss" scoped>
.property-box {
	background: #fff;
	border-radius: 0.5rem;
	overflow: hidden;
	cursor: pointer;
	height: 100%;
	transition: 0.15s ease all;
	user-select: none;

    &.home-property-box {
        box-shadow: rgba(#16193A, 0.1) 0 0.15rem 1rem;

        .property-details {
            .property-title {
                font-size: 1rem;
                margin-bottom: 1rem;
                line-height: 1.2;
            }
        }
    }

	&:hover {
		box-shadow: rgba(#16193A, 0.1) 0 0.15rem 1rem;

		.property-thumbnail {
			&:before {
				opacity: 1;
			}

			&:after {
				opacity: 1;
			}
		}
	}

	&:active {
		box-shadow: rgba(#16193A, 0.1) 0 0.15rem 1.25rem;
		transform-origin: center;
		transform: scale(0.975);
	}

	.property-thumbnail {
		position: relative;
		width: 100%;
		height: 15rem;

		img {
			width: 100%;
			height: 15rem;
			object-fit: cover;
			display: block;
			position: relative;
			z-index: 0;
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(#16193A, 0.35);
			content: ' ';
			z-index: 1;
			transition: 0.25s ease all;
			opacity: 0;
		}

		&:after {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 10rem;
			height: 2.5rem;
			content: 'More Details';
			margin: auto;
			text-align: center;
			color: #16193A;
			font-weight: bold;
			line-height: 2.5rem;
			z-index: 2;
			border-radius: 0.5rem;
			background: #fff;
			opacity: 0;
			transition: 0.4s ease all;
		}
	}

	.property-details {
		padding: 1.5rem;

		.property-title {
			font-weight: bold;
			color: #16193A;
			font-size: 1.25rem;
		}

		.property-address {
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: rgba(#16193A, 0.5);
			font-size: 0.9rem;
			padding-left: 1.25rem;
			position: relative;

			@media (max-width: 767.99px) {
				white-space: normal;
			}

			&:before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: -0.25rem;
				opacity: 0.25;
				width: 1.25rem;
				height: 1.25rem;
				background: url('../../assets/icons/map-marker.svg') center no-repeat;
				background-size: contain;
				content: ' ';
				margin: auto;
			}
		}
	}

	&.loading {
		background: none;
		box-shadow: none;
		pointer-events: none;
		user-select: none;

		.property-thumbnail {
			animation: pulse-light 1s infinite;
			border-radius: 0.5rem;
		}

		.property-details {
			padding-left: 0;

			.property-title {
				animation: pulse-light 1s infinite;
				border-radius: 0.5rem;
				color: transparent;
			}

			.property-address {
				animation: pulse-light 1s infinite;
				border-radius: 0.5rem;
				color: transparent;
				transform: translateY(0.5rem);

				&:before {
					opacity: 0;
				}
			}
		}

		&:hover, &:active {
			box-shadow: none;
			transform: none;
		}
	}
}
</style>