<template>
    <div class="screen">

        <!-- Mobile Header -->
        <MobileHeader :dark="true" :menu-toggle="true" title="Account Settings"/>

        <div class="container-fluid">
            <div class="row">
                <div class="col-xxl-3">
                    <div class="account-wrapper-header">
                        <div class="account-avatar">{{ user.credentials }}</div>
                        <div class="account-credentials">
                            <h2 class="account-name">{{ user.name }}</h2>
                            <h4 class="account-email">{{ user.email }}</h4>
                        </div>
                        <ul class="nav flex-md-column">
                            <li class="nav-item">
                                <router-link to="/account" class="nav-link">
                                    <img src="../../assets/icons/users.svg" alt="User">
                                    <span>Personal &amp; Tax</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/account/bank-account" class="nav-link">
                                    <img src="../../assets/icons/bank-account.svg" alt="Bank">
                                    <span>Bank Account</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/account/distributions" class="nav-link">
                                    <img src="../../assets/icons/performance.svg" alt="Performance">
                                    <span>Distributions</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/account/verification" class="nav-link">
                                    <img src="../../assets/icons/shield-check.svg" alt="Shield">
                                    <span>Verification</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/account/security" class="nav-link">
                                    <img src="../../assets/icons/lock.svg" alt="Lock">
                                    <span>Security</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xxl-9">
                    <div class="account-wrapper-body">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader.vue";

export default {
    name: "AccountWrapper",
    components: {MobileHeader},
    computed: {
        user() {
            return this.$store.getters.user;
        },
	},
}
</script>

<style lang="scss">
.account-wrapper-header {
    background: #fff;
    padding: 2.5rem;
    border-radius: 0.5rem;

    @media (max-width: 767.99px) {
        border-radius: 0;
        padding: 1rem 1.5rem 1rem 1.5rem;
        width: calc(100% + 3rem);
        margin: 0 -1.5rem;
        background: #16193A;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        align-items: center;
    }

    .account-avatar {
        background: #f2f3f4;
        color: #16193A;
        font-weight: bold;
        font-size: 1.5rem;
        width: 4rem;
        height: 4rem;
        display: block;
        text-align: center;
        line-height: 4rem;
        border-radius: 50%;

        @media (max-width: 767.99px) {
            width: 4rem;
            height: 4rem;
            line-height: 4rem;
            font-size: 1.25rem;
            background: rgba(#fff, 0.15);
            color: #fff;
            flex-shrink: 0;
        }
    }

    .account-credentials {
        @media (max-width: 767.99px) {
            padding-left: 1rem;
            width: calc(100% - 4rem);
        }
    }

    .account-name {
        font-weight: bold;
        font-size: 1.25rem;
        color: #16193A;
        margin: 1.5rem 0 0.5rem 0;

        @media (max-width: 767.99px) {
            color: #fff;
            margin-top: 0;
        }
    }

    .account-email {
        color: rgba(#16193A, 0.5);
        font-weight: 400;
        margin: 0;
        font-size: 0.9rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;

        @media (max-width: 767.99px) {
            color: rgba(#fff, 0.5);
            margin-top: 0;
        }
    }

    .nav {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid #eee;

        @media (max-width: 767.99px) {
            width: calc(100% + 3rem);
            flex-shrink: 1;
            flex-grow: 0;
            margin-left: -1.5rem;
            margin-right: -1.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            margin-top: 1rem;
            padding-top: 1rem;
            border-top-color: rgba(#fff, 0.1);
            display: block;
            white-space: nowrap;
            overflow-y: hidden;
        }

        .nav-item {
            margin-top: 0.1rem;
            flex-basis: auto;

            @media (max-width: 767.99px) {
                display: inline-block;
            }

            .nav-link {
                padding: 0.75rem 1rem;
                width: calc(100% + 2rem);
                margin: 0 -1rem;
                font-weight: 700;
                color: #16193A;
                transition: 0.15s ease all;
                border-radius: 0.35rem;

                @media (max-width: 767.99px) {
                    margin: 0;
                    width: auto;
                    color: #fff;
                }

                &:hover,
                &.router-link-exact-active {
                    background: #f2f3f4;
                    color: #16193A;

                    img {
                        filter: none;
                    }
                }

                &:active {
                    background: darken(#f2f3f4, 10%);
                }

                img {
                    width: 1.5rem;
                    height: 1.5rem;

                    @media (max-width: 767.99px) {
                        filter: brightness(0) invert(1);
                    }
                }

                span {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

.account-wrapper-body {
    @media (max-width: 1399px) {
        padding: 1.5rem 0 0 ;
    }

    @media (max-width: 767.99px) {
        width: calc(100% + 3rem);
        margin: 0 -1.5rem;
        padding: 2.5rem 1.5rem;
        background: #fff;
    }
}

.account-dashboard-box {
    display: block;
    position: relative;
    padding: 2.5rem;
    background: #fff;
    border-radius: 0.5rem;

    @media (max-width: 767.99px) {
        padding: 1rem 0 1.5rem 0;
        border-radius: 0;
    }

    > .spinner-border {
        opacity: 0.25;
    }
}

.account-screen-actions {
    position: sticky;
    bottom: 1rem;
    z-index: 2;

    .btn[disabled] {
        filter: grayscale(1);
        opacity: 0.5;
    }

    .btn:not(.changed) {
        opacity: 0;
    }
}
</style>