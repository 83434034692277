<template>
	<router-link to="/account/verification" :class="{'verification-wrapper': true, 'active': verification.loaded && !areDocumentsApproved && !verification.documents_uploaded}">
        <span v-if="verification.loaded && !verification.documents_uploaded">We couldn't verify some of your account details. Please verify your account.</span>
	</router-link>
</template>

<script>
export default {
	name: "AccountVerificationStatus",
    components: {},
    computed: {
        verification() {
            return this.$store.getters.verification;
        },
        areDocumentsApproved() {
            if(!this.verification.loaded) return true;
            return (this.verification.kyc === 'Auto Approved' || this.verification.kyc === 'Manually Approved') && (this.verification.aml === 'Auto Approved' || this.verification.aml === 'Manually Approved');
        },
    },
}
</script>

<style lang="scss" scoped>
.verification-wrapper {
    display: block;
    background: #e81e1e;
    padding: 0.5rem;
    text-align: center;
    font-weight: 700;
    font-size: 0.9rem;
    color: #fff;
    text-decoration: none;
    height: 2.35rem;
    opacity: 0;
    visibility: hidden;
    margin-top: -2.35rem;
    transition: 0.15s ease all;

    &:hover {
        background: lighten(#e81e1e, 10%);
    }

    @media (max-width: 767.99px) {
        height: auto;
        margin-top: 0;
        margin-bottom: 0;
        display: none;
        text-align: left;
        padding: 0.75rem 1.5rem;
    }

    &.active {
        opacity: 1;
        visibility: visible;
        margin-top: -1.5rem;
        margin-bottom: 1.5rem;

        @media (max-width: 767.99px) {
            margin-top: 0;
            margin-bottom: 0;
            display: block;
        }
    }

    span {
        display: block;
    }
}
</style>