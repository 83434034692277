<template>
	<div class="screen">

		<!-- Mobile Header -->
		<MobileHeader :dark="false" :menu-toggle="true" title="Investor Insights"/>

		<div class="container-fluid">

			<!-- Desktop Screen Title -->
			<h3 class="screen-title mb-3 d-none d-md-block">Investor Insights</h3>

			<!-- Articles List -->
			<div class="articles-list">
				<div class="article-item" v-for="(article, index) in articles" :key="index">
					<router-link :to="'/insights/' + article.id" class="article-image">
						<img :src="article.image" :alt="article.title">
					</router-link>
					<div class="article-info">
						<router-link :to="'/insights/' + article.id" class="article-title">{{ article.title }}</router-link>
						<p class="article-description">{{ article.description }}</p>
						<router-link :to="'/insights/' + article.id" class="btn btn-link">Read more</router-link>
					</div>
				</div>
				<div :class="{'articles-loading': true, 'active': !loaded}" v-if="hasMore">
					<div class="article-item loading-item">
						<div class="article-image"></div>
						<div class="article-info">
							<div class="article-title"></div>
							<div class="article-description"></div>
						</div>
					</div>
					<div class="article-item loading-item">
						<div class="article-image"></div>
						<div class="article-info">
							<div class="article-title"></div>
							<div class="article-description"></div>
						</div>
					</div>
					<div class="article-item loading-item">
						<div class="article-image"></div>
						<div class="article-info">
							<div class="article-title"></div>
							<div class="article-description"></div>
						</div>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader.vue";
import repository from "@/repository/repository";
export default {
    name: "Insights",
    components: {MobileHeader},
    data() {
        return {
            articles: [],
			cursor: null,
			hasMore: true,
			loaded: false,
        }
    },
	created() {
        this.loadArticles();
        this.scroll();
    },
    methods: {
        loadArticles() {
            if(!this.hasMore) return;
            this.loaded = false;
            repository.get("/news?cursor=" + this.cursor)
				.then(response => {
                    this.articles.push(...response.data.articles);
					this.cursor = response.data.next;
                    this.loaded = true;
                    if(!response.data.next) this.hasMore = false;
				})
		},
        scroll () {
            window.onscroll = () => {
                let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight - 400;
                if (bottomOfWindow && this.loaded) {
                    this.loadArticles();
                }
            }
        },
	},
}
</script>

<style lang="scss">
.articles-list {
	position: relative;

	.article-item {
		display: flex;
		align-items: flex-start;
		margin-bottom: 1.5rem;

		.article-image {
			width: 12rem;
			height: 8rem;
			flex-shrink: 0;
			border-radius: 0.5rem;
			overflow: hidden;
			background: rgba(#16193A, 0.05);

			@media (max-width: 767px) {
				width: 8rem;
				height: 6rem;
			}

			img {
				width: 100%;
				height: 8rem;
				object-fit: cover;

				@media (max-width: 767px) {
					height: 6rem;
				}
			}
		}

		.article-info {
			padding-left: 1.5rem;

			.article-title {
				display: block;
				font-weight: bold;
				color: #16193A;
				text-decoration: none;
				font-size: 1.15rem;
			}

			.article-description {
				margin-bottom: 0.5rem;
			}

			.btn.btn-link {
				color: #F37021;
				font-weight: 600;
				text-decoration: none;
				padding: 0;
				margin: 0;
				box-shadow: none;
			}
		}

		&.loading-item {

			.article-image {
				background: rgba(#16193A, 0.05);
				animation: loading-pulse 0.5s infinite;
			}

			.article-info {
				width: 100%;

				.article-title {
					background: rgba(#16193A, 0.05);
					display: block;
					border-radius: 0.25rem;
					width: 100%;
					height: 2rem;
					animation: loading-pulse 0.5s infinite;
				}

				.article-description {
					background: rgba(#16193A, 0.025);
					display: block;
					border-radius: 0.25rem;
					width: 100%;
					height: 4rem;
					margin-top: 0.5rem;
					animation: loading-pulse 0.5s infinite;
				}
			}

			@keyframes loading-pulse {
				0% {
					background: rgba(#16193A, 0.05);
				}
				50% {
					background: rgba(#16193A, 0.1);
				}
				100% {
					background: rgba(#16193A, 0.05);
				}
			}

		}
	}

	.articles-loading {
		position: relative;
		opacity: 0;
		transition: 0.15s ease all;

		&.active {
			opacity: 1;
		}
	}

}
</style>