<template>
    <div class="account-growth-box mb-4">
        <!-- Header -->
        <div class="row align-items-center">
            <div class="col-md-6">
                <h4>Your Account Growth</h4>
            </div>
            <div class="col-md-3 ms-md-auto">
                <select name="days" id="days" class="form-control" aria-label="Days" v-model="days">
                    <option value="all">All</option>
                    <option value="30">Last month</option>
                    <option value="90">Last 3 months</option>
                    <option value="180">Last 6 months</option>
                    <option value="360">Last year</option>
                    <option value="730">Last 2 years</option>
                </select>
            </div>
        </div>

        <!-- Chart -->
        <VueApexCharts type="area" height="300" :options="areaChartOptions" :series="areaSeries" v-if="transactions.loaded"></VueApexCharts>

        <!-- Loading -->
        <div class="area-loading text-center" v-if="!transactions.loaded">
            <div class="spinner-border d-block mx-auto opacity-25"></div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {readableDate} from "@/helper";

export default {
	name: "PerformanceAccountGrowthChart",
    props: ['transactions'],
    components: { VueApexCharts },
    data() {
        return {
            days: 'all',
        }
    },
    computed: {
        fundedInvestments() {
            if(!this.transactions.loaded) return [];
            return this.transactions.investments.filter(investment => investment.payment_status === 'funded');
        },
        reinvestedDistributions() {
            if(!this.transactions.loaded) return [];
            return this.transactions.distributions.filter(distribution => distribution.type === 'reinvested');
        },
        accountGrowthDaily() {
            // Combine investments and reinvestments into a single array
            const data = [...this.fundedInvestments, ...this.reinvestedDistributions];
            if (data.length === 0) return [];

            // Sort the combined data array by the distribution payable date
            data.sort((a, b) => {
                const aDate = a.distribution_period ? a.distribution_period.payable_date : a.created_at.substring(0, 10);
                const bDate = b.distribution_period ? b.distribution_period.payable_date : b.created_at.substring(0, 10);
                return new Date(aDate) - new Date(bDate);
            });

            // Create a map to store total values for each date
            const totalValueMap = new Map();

            // Iterate through the sorted data array and calculate cumulative total values
            let cumulativeValue = 0;
            let currentDateCopy = new Date(data[0].distribution_period?.payable_date || data[0].created_at.substring(0, 10));
            const currentDate = new Date(); // Current date

            while (currentDateCopy <= currentDate) {
                const dateStr = currentDateCopy.toISOString().substring(0, 10);
                const matchingData = data.filter(entry => {
                    const entryDate = entry.distribution_period ? entry.distribution_period.payable_date : entry.created_at;
                    return entryDate.substring(0, 10) === dateStr;
                });

                if (matchingData.length > 0) {
                    for (const entry of matchingData) {
                        cumulativeValue += entry.amount;
                    }
                }

                totalValueMap.set(dateStr, cumulativeValue);
                currentDateCopy.setDate(currentDateCopy.getDate() + 1); // Move to the next day
            }

            // Generate the result array with total values for each day, including today
            const resultArray = [];
            currentDateCopy = new Date(data[0].distribution_period?.payable_date || data[0].created_at.substring(0, 10));
            while (currentDateCopy <= currentDate) {
                const dateStr = currentDateCopy.toISOString().substring(0, 10);
                const totalValue = totalValueMap.get(dateStr) || 0;
                resultArray.push({ date: dateStr, total_value: totalValue });

                currentDateCopy.setDate(currentDateCopy.getDate() + 1); // Move to the next day
            }

            if(this.days === 'all') {
                return resultArray;
            }

            return resultArray.slice(this.days * -1);
        },
        highestAccountValue() {
            if (this.accountGrowthDaily.length === 0) {
                return 0; // Return 0 if no investments or distributions
            }
            let highestValue = this.accountGrowthDaily[0].total_value;
            for (const entry of this.accountGrowthDaily) {
                if (entry.total_value > highestValue) {
                    highestValue = entry.total_value;
                }
            }
            return highestValue * 1.2;
        },
        areaSeries() {
            // Verify loaded
            if(!this.transactions.loaded) return [];

            // Get daily growth for account
            let values = [];
            this.accountGrowthDaily.forEach(entry => {
                values.push(entry.total_value);
            })

            // Push series
            let series = [];
            series.push({
                name: 'Account Value',
                color: '#F37021',
                type: 'area',
                data: values,
            });
            return series;
        },
        areaCategories() {
            if (!this.transactions.loaded) return [];

            // Get daily growth for account
            let values = [];
            this.accountGrowthDaily.forEach(entry => {
                values.push(readableDate(entry.date));
            })

            return values;
        },
        areaChartOptions() {
            return {
                chart: {
                    height: 300,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                },
                fill: {
                    type: 'gradient',
                },
                stroke: {
                    curve: 'smooth',
                    width: 0,
                },
                grid: {
                    borderColor: "#eee",
                },
                tooltip: {
                    enabled: true,
                    marker: {
                        show: true,
                    },
                    x: {
                        format: 'dd/MM/yy'
                    },
                    fixed: {
                        enabled: true,
                        position: 'bottomRight',
                        offsetX: -20,
                        offsetY: -20,
                    },
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return `
        <div class="account-growth-chart-tooltip">
          <span>${w.globals.categoryLabels[dataPointIndex]}</span>
          <strong>$${series[seriesIndex][dataPointIndex].toLocaleString()}</strong>
        </div>
      `;
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    labels: {
                        colors: "#aaa",
                    }
                },
                xaxis: {
                    type: 'date',
                    categories: this.areaCategories,
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    forceNiceScale: true,
                    tickAmount: 5,
                    min: 0,
                    max: this.highestAccountValue,
                    labels: {
                        formatter: function (value) {
                            if (value >= 500000) {
                                return "$" + (value / 1000000).toFixed(1) + "M";
                            }
                            if (value >= 1000) {
                                return "$" + (value / 1000).toFixed(0) + "K";
                            }
                            return "$" + value;
                        },
                    },
                },
            };
        }
    },
}
</script>

<style lang="scss" scoped>
.account-growth-box {
    display: block;
    padding: 2.5rem 2.5rem 1.5rem 2.5rem;
    background: #fff;
    border-radius: 0.5rem;
    position: relative;

    @media (max-width: 767.99px) {
        border-radius: 0;
        padding: 1rem 1.5rem 2.5rem 1.5rem;
        width: calc(100% + 3rem);
        margin: -1.5rem -1.5rem 0 -1.5rem;
    }

    h4 {
        font-size: 1.25rem;
        color: #16193A;
        font-weight: 700;
    }

    .form-control {
        box-shadow: none;
        border: none;
        outline: none;
        background: rgba(22, 25, 58, 0.025);
        color: #16193A;
        font-weight: 600;
        cursor: pointer;

        @media (max-width: 767.99px) {
            margin: 0.75rem 0 1.5rem 0;
            padding: 1rem;
            background: rgba(22, 25, 58, 0.05);
        }
    }

    .area-loading {
        height: 315px;
        padding: 2.5rem 0;
    }
}
</style>

<style lang="scss">
.account-growth-chart-tooltip {
    padding: 1rem;

    span {
        display: block;
        font-weight: 600;
        color: rgba(22, 25, 58, 0.75);
        font-size: 0.8rem;
    }

    strong {
        display: block;
        font-weight: bold;
        color: #16193A;
        font-size: 1.2rem;
    }
}

.apexcharts-tooltip {
    border: none !important;
    border-radius: 1rem !important;
}
</style>