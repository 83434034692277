<template>
	<div :class="{'impact-box': true, 'loading': loading === true}">
        <img :src="impact.icon" :alt="impact.title" class="impact-icon" v-if="impact">
		<div class="impact-details">
            <div class="impact-number">
                {{ impact ? (impact.prefix ? impact.prefix : '') + impact.value.toLocaleString() + (impact.suffix ? impact.suffix : '') : '0,00' }}
            </div>
			<div class="impact-title">{{ impact ? impact.title : 'Loading' }}</div>
			<div class="impact-description">{{ impact ? impact.description : 'Loading' }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ImpactBox",
	props: ['impact', 'loading'],
	computed: {
	},
}
</script>

<style lang="scss" scoped>
.impact-box {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 1.5rem 1.5rem;
    height: 100%;

    @media (max-width: 1399px) {
        padding-left: 0;
    }

    img.impact-icon {
        width: 4rem;
        height: 4rem;
        flex-shrink: 0;
    }

    .impact-details {
        padding-left: 1rem;

        .impact-number {
            font-size: 2rem;
            font-weight: bold;
            color: #16193A;
            margin: 0;
            line-height: 1.2;
        }

        .impact-title {
            font-weight: 600;
            line-height: 1.2;
            display: block;
            color: #16193A;
            margin: 0.25rem 0 0.5rem 0;
        }

        .impact-description {
            color: rgba(#16193A, 0.5);
            font-size: 0.9rem;
        }
    }

    &.loading {
        &:before {
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
            width: 4rem;
            height: 4rem;
            border-radius: 0.5rem;
            background: rgba(#16193A, 0.025);
            content: ' ';
        }

        .impact-details {
            margin-left: 4rem;
            width: 100%;
            flex-grow: 1;

            .impact-number {
                color: transparent;
                background: rgba(#16193A, 0.025);
                border-radius: 0.5rem;
                width: 100%;
            }

            .impact-title {
                color: transparent;
                background: rgba(#16193A, 0.025);
                border-radius: 0.5rem;
                animation: loading-pulse 0.5s infinite;
                width: 100%;
            }

            .impact-description {
                color: transparent;
                background: rgba(#16193A, 0.025);
                border-radius: 0.5rem;
                animation: loading-pulse 0.5s infinite;
                width: 100%;
            }
        }
    }

    @keyframes loading-pulse {
        0% {
            background: rgba(#16193A, 0.05);
        }
        50% {
            background: rgba(#16193A, 0.1);
        }
        100% {
            background: rgba(#16193A, 0.05);
        }
    }
}
</style>