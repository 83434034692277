<template>
	<div :class="{'mobile-header': true, 'dark-version': dark}">
		<div class="container-fluid">
			<button @click.prevent="showMobileSidebar" v-if="menuToggle" class="button-icon">
				<img src="./../assets/icons/menu.svg" alt="Menu">
			</button>
			<router-link :to="backLink" v-if="backLink && backLink !== true" class="button-icon">
				<img src="./../assets/icons/arrow-left.svg" alt="Arrow Left">
			</router-link>
			<div @click.prevent="$router.back()" v-if="backLink === true" class="button-icon">
				<img src="./../assets/icons/arrow-left.svg" alt="Arrow Left">
			</div>
			<h4>{{ title }}</h4>
		</div>
	</div>
</template>

<script>

export default {
    name: "MobileHeader",
	props: ['title', 'dark', 'menuToggle', 'backLink'],
	methods: {
        showMobileSidebar() {
            this.$store.commit("showMobileSidebar");
		},
	},
}
</script>

<style lang="scss">
.mobile-header {
	background: #fff;
	padding: 1.25rem 0;
	margin-bottom: 2.5rem;

	@media (min-width: 768px) {
		display: none;
	}

	&.dark-version {
		margin-bottom: 0;
		background: #16193A;

		h4 {
			color: #fff;
		}

		.button-icon {

			&:active {
				background: rgba(255,255,255,0.15);
			}

			img {
				filter: brightness(0) invert(1);
			}
		}
	}

	.container-fluid {
		display: flex;
		align-items: center;
	}

	.button-icon {
		display: block;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0;
		margin: -1rem 0.5rem -1rem -0.5rem;
		border-radius: 0.25rem;
		box-shadow: none;
		background: none;
		border: none;
		text-align: center;
		line-height: 2rem;
		transition: 0.15s ease all;

		&:active {
			background: #f2f3f4;
			transform-origin: center;
			transform: scale(0.95);
		}

		img {
			width: 1.75rem;
			height: 1.75rem;
			display: inline-block;
			line-height: 1;
		}
	}

	h4 {
		margin: 0;
		font-size: 1rem;
		font-weight: bold;
		color: #16193A;
	}
}
</style>