<template>
    <div class="form-field">
        <label class="form-label">Date of Birth</label>
        <div class="birthday-fields">
            <select id="month" v-model="selectedMonth" @change="verifyDate" class="form-control" required ref="input">
                <option value="" selected disabled>Month</option>
                <option v-for="(month, index) in months" :key="'month-' + index" :value="index+1">{{ month }}</option>
            </select>
            <select id="day" v-model="selectedDay" @change="verifyDate" class="form-control" required>
                <option value="" selected disabled>Day</option>
                <option v-for="day in days" :key="'day-' + day" :value="day">{{ day }}</option>
            </select>
            <select id="year" v-model="selectedYear" @change="verifyDate" class="form-control" required>
                <option value="" selected disabled>Year</option>
                <option v-for="year in years" :key="'year-' + year" :value="year">{{ year }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            selectedDay: '',
            selectedMonth: '',
            selectedYear: '',
            days: [...Array(31).keys()].map(i => i + 1),
            months: [
                'January', 'February', 'March', 'April', 'May', 'June', 'July',
                'August', 'September', 'October', 'November', 'December'
            ],
            years: [...Array(100).keys()].map(i => new Date().getFullYear() - i),
        };
    },
    mounted() {
        // Set initial values based on the provided v-model value
        if(this.value && this.isValidDate(this.value)) {
            const date = new Date(this.value);
            this.selectedDay = date.getDate();
            this.selectedMonth = date.getMonth() + 1;
            this.selectedYear = date.getFullYear();
        }
    },
    methods: {
        verifyDate() {
            const isValid = this.selectedDay && this.selectedMonth && this.selectedYear;
            if (isValid) {
                const maxDays = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
                if (this.selectedDay > maxDays) {
                    this.selectedDay = '';
                    this.$emit('input', null);
                } else {
                    const selectedDate = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay);
                    this.$emit('input', this.getFullDate(selectedDate));
                    this.$emit('change', this.getFullDate(selectedDate));
                }
            } else {
                this.$emit('input', null);
            }
        },
        isValidDate(dateString) {
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateRegex.test(dateString)) {
                return false;
            }
            const date = new Date(dateString);
            return date.toString() !== 'Invalid Date';
        },
        getFullDate(date) {
            return [
                date.getFullYear(),
                ('0' + (date.getMonth() + 1)).slice(-2),
                ('0' + date.getDate()).slice(-2)
            ].join('-');
        },
        focus() {
            this.$refs.input.focus();
        }
    },
};
</script>

<style lang="scss" scoped>
.birthday-fields {
    display: flex;

    .form-control {
        margin-right: 1.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}
</style>