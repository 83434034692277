<template>
	<div class="screen documents-screen">

		<!-- Mobile Header -->
		<MobileHeader :dark="false" :menu-toggle="true" title="Your Documents"/>

		<div class="container-fluid">

			<!-- Desktop Screen Title -->
			<h3 class="screen-title mb-3 d-none d-md-block">Your Documents</h3>

			<!-- Pending Agreements -->
			<div class="documents-box">
				<h4>Pending Agreements</h4>
				<p>To confirm your investments, kindly provide your signature on the Agreements below.</p>
				<div v-if="transactions.loaded">
					<div v-for="(transaction, key) in notSignedInvestments" :key="key" class="document-item">
						<div class="document-id">
							<strong>Subscription Agreement</strong>
							<span>#R{{ transaction.offer_id }}{{ transaction.date.year }}{{ transaction.id }}</span>
						</div>
						<div class="document-amount">${{ transaction.amount.toLocaleString() }}</div>
						<div class="document-date">
							<span class="document-date-day">{{ transaction.date.day }}</span>
							<span class="document-date-month">{{ transaction.date.month }}</span>
							<span class="document-date-year">{{ transaction.date.year }}</span>
						</div>
						<div class="document-status">
<!--							<a :href="signAgreementDocumentLink(transaction)" target="_blank" class="btn btn-primary d-inline-flex align-items-center" v-if="!transaction.docu_sign">-->
<!--								<img src="../assets/icons/document.svg" alt="Document" class="btn-icon icon-force-white">-->
<!--								<span>Sign Agreement</span>-->
<!--							</a>-->
						</div>
					</div>
					<div class="documents-signed" v-if="notSignedInvestments.length === 0">
						<img src="../assets/icons/check.svg" alt="Check">
						<strong>All documents are signed. Thank you!</strong>
					</div>
				</div>
				<div v-if="!transactions.loaded">
					<div class="document-item loading-item">
						<div class="document-id">
							<strong>Loading</strong>
							<span>Loading</span>
						</div>
						<div class="document-amount">Loading</div>
						<div class="document-date">Loading</div>
						<div class="document-status">
							<div class="btn btn-primary">Placeholder Button</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Signed Agreements -->
			<div class="documents-box mt-4">
				<h4>Signed Agreements</h4>
				<p>Enclosed are the signed Subscription Agreements.</p>
				<div v-if="transactions.loaded">
					<div v-for="(transaction, key) in signedInvestments" :key="key" class="document-item">
						<div class="document-id">
							<strong>Subscription Agreement</strong>
							<span>#R{{ transaction.offer_id }}{{ transaction.date.year }}{{ transaction.id }}</span>
						</div>
						<div class="document-amount">${{ transaction.amount.toLocaleString() }}</div>
						<div class="document-date">
							<span class="document-date-day">{{ transaction.date.day }}</span>
							<span class="document-date-month">{{ transaction.date.month }}</span>
							<span class="document-date-year">{{ transaction.date.year }}</span>
						</div>
						<div class="document-status">
<!--							<a :href="downloadAgreement(transaction)" target="_blank" class="btn btn-secondary d-inline-flex align-items-center">-->
<!--								<img src="../assets/icons/document.svg" alt="Document" class="btn-icon icon-force-white">-->
<!--								<span>Open Agreement</span>-->
<!--							</a>-->
						</div>
					</div>
					<div class="no-documents" v-if="signedInvestments.length === 0">
						<img src="../assets/icons/document-alt.svg" alt="Document">
						<strong>You don't have any signed agreements.</strong>
					</div>
				</div>
				<div v-if="!transactions.loaded">
					<div class="document-item loading-item">
						<div class="document-id">
							<strong>Loading</strong>
							<span>Loading</span>
						</div>
						<div class="document-amount">Loading</div>
						<div class="document-date">Loading</div>
						<div class="document-status">
							<div class="btn btn-primary">Placeholder Button</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Offering Documents -->
			<div class="documents-box mt-4">
				<h4>Offering Documents</h4>
				<p>These documents that are provided for your reference.</p>
				<div v-if="!documents.loaded">
					<div class="document-item simple-document loading-item">
						<div class="document-id"></div>
						<div class="document-status">
							<div class="btn btn-primary">Placeholder Button</div>
						</div>
					</div>
				</div>
				<div class="document-item simple-document" v-for="document in documents.items" :key="document.id">
					<div class="document-id">
						<strong>{{ document.title }}</strong>
						<span>Reiturn Fund</span>
					</div>
					<div class="document-status">
						<a :href="document.url" target="_blank" class="btn btn-secondary d-inline-flex align-items-center">
							<img src="../assets/icons/document.svg" alt="Document" class="btn-icon icon-force-white">
							<span>View Document</span>
						</a>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import repository, {API_URL} from "@/repository/repository";
import MobileHeader from "@/components/MobileHeader.vue";

export default {
    name: "Documents",
    components: {MobileHeader},
    data() {
        return {
            transactions: {
                items: [],
                loaded: false,
                error: false,
			},
			documents: {
				items: [],
				loaded: false,
			},
        }
    },
	created() {
        this.loadTransactions();
		this.loadOfferingDocuments();
    },
    methods: {
        loadTransactions() {
            repository.get("/investments")
				.then(response => {
                    this.transactions.items = response.data.investments;
                    this.transactions.items.forEach(_item => _item.cancelling = false);
                    this.transactions.loaded = true;
                    this.transactions.error = false;
                    this.$store.commit("updateUnsignedAgreements", this.transactions.items.filter(item => item.status === 'CREATED' || item.status === 'FUNDED').filter(item => item.docu_sign === 0).length);
				})
				.catch(() => {
                    this.transactions.loaded = true;
                    this.transactions.error = true;
				})
		},
		loadOfferingDocuments() {
			repository.get("/documents")
				.then(response => {
					this.documents.items = response.data.documents;
					this.documents.loaded = true;
				})
		},
        signAgreementDocumentLink(transaction) {
            return API_URL + "/investments/" + transaction.id + "/sign?token=" + this.$store.getters.token;
		},
        downloadAgreement(transaction) {
            return API_URL + "/investments/" + transaction.id + "/download?token=" + this.$store.getters.token;
		},
	},
	computed: {
        filteredInvestments() {
            if(!this.transactions.loaded) return [];
            return this.transactions.items.filter(transaction => transaction.status === 'FUNDED' || transaction.status === 'CREATED');
		},
		signedInvestments() {
            if(this.filteredInvestments.length === 0) return [];
            return this.filteredInvestments.filter(transaction => transaction.docu_sign);
		},
		notSignedInvestments() {
            if(this.filteredInvestments.length === 0) return [];
            return this.filteredInvestments.filter(transaction => !transaction.docu_sign);
		}
	},
}
</script>

<style lang="scss">
.screen.documents-screen {
	@media (max-width: 767px) {
		padding-bottom: 3.75rem;
	}
}
.documents-box {
	display: block;
	position: relative;
	padding: 2.5rem;
	background: #fff;
	border-radius: 0.5rem;

	@media (max-width: 767.99px) {
		background: transparent;
		border-radius: 0;
		padding: 0;
	}

	hr {
		margin: 2rem 0;
		border-color: #ddd;
		opacity: 1;
	}

	h4 {
		color: #16193A;
		font-weight: bold;
		font-size: 1.25rem;
	}

	p {
		color: #16193A;
	}

	.document-item {
		display: flex;
		position: relative;
		transition: 0.15s ease all;
		border-radius: 0.5rem;
		background: #fff;
		border: 1px solid #eee;
		padding: 1rem;
		margin-top: 0.5rem;
		align-items: center;

		@media (min-width: 768px) and (max-width: 1199.99px) {
			flex-wrap: wrap;
		}

		@media (max-width: 767.99px) {
			flex-direction: column;
			align-items: flex-start;
		}

		&:hover {
			box-shadow: rgba(#16193A, 0.1) 0 0.1rem 0.25rem;
		}

		.document-id {
			display: block;
			color: #16193A;
			margin-right: 1.5rem;
			width: 25%;
			flex-shrink: 0;
			white-space: nowrap;

			@media (min-width: 768px) and (max-width: 1199.99px) {
				width: calc(40% - 1rem);
				margin-right: 1rem;
			}

			@media (max-width: 767.99px) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}

			strong {
				display: block;
				font-weight: bold;
			}

			span {
				display: block;
				font-weight: 400;
				font-size: 0.9rem;
				color: rgba(#16193A, 0.5);
			}
		}

		.document-amount {
			display: block;
			color: #F37021;
			font-weight: 700;
			margin-right: 1rem;
			width: 15%;
			flex-shrink: 0;

			@media (min-width: 768px) and (max-width: 1199.99px) {
				width: calc(30% - 1rem);
				margin-right: 1rem;
			}

			@media (max-width: 767.99px) {
				width: 100%;
				margin-top: 0.25rem;
			}

			&:before {
				content: 'Amount Invested:';
				display: block;
				font-size: 0.7rem;
				color: #16193A;
				font-weight: 400;

				@media (max-width: 767.99px) {
					display: none;
				}
			}
		}

		.document-date {
			display: block;
			color: #16193A;
			font-weight: 700;
			margin-right: 1.5rem;
			width: 15%;
			flex-shrink: 0;

			@media (min-width: 768px) and (max-width: 1199.99px) {
				width: 30%;
				margin-right: 0;
			}

			@media (max-width: 767.99px) {
				font-weight: 400;
				width: 100%;
				margin-top: 0.25rem;
			}

			&:before {
				content: 'Date:';
				display: block;
				font-size: 0.7rem;
				color: #16193A;
				font-weight: 400;

				@media (max-width: 767.99px) {
					display: none;
				}
			}

			.document-date-day {
				margin-right: 0.25rem;
			}

			.document-date-month {
				margin-right: 0.25rem;
			}
		}

		.document-status {
			margin-left: auto;

			@media (min-width: 768px) and (max-width: 1199.99px) {
				width: 100%;
				margin-left: 0;
				margin-top: 1rem;
			}

			@media (max-width: 767.99px) {
				margin-left: 0;
				width: 100%;
				margin-top: 1rem;

				.btn {
					width: 100%;
					justify-content: center;
				}
			}
		}

		&.loading-item {
			.document-id,
			.document-amount,
			.document-date,
			.document-status {
				background: #f2f3f4;
				border-radius: 0.5rem;
				color: transparent;
				min-height: 2rem;
				animation: loading-pulse 0.5s infinite;

				@media (max-width: 767px) {
					min-height: 1.5rem;
				}

				&:before {
					opacity: 0;
				}

				* {
					opacity: 0;
				}
			}

			@keyframes loading-pulse {
				0% {
					background: rgba(#16193A, 0.05);
				}
				50% {
					background: rgba(#16193A, 0.1);
				}
				100% {
					background: rgba(#16193A, 0.05);
				}
			}
		}

		&.simple-document {
			.document-status {
				@media (min-width: 768px) and (max-width: 1199.99px) {
					width: auto;
					margin-left: auto;
					margin-top: 0;
				}
			}
		}
	}

	.no-documents {
		display: flex;
		position: relative;
		transition: 0.15s ease all;
		border-radius: 0.5rem;
		background: #fafbfc;
		border: 1px solid transparent;
		padding: 1rem;
		margin-top: 0.5rem;
		align-items: center;

		@media (max-width: 767px) {
			background: transparent;
			padding: 1rem 0;
		}

		img {
			width: calc(3.225rem + 4px);
			height: calc(3.225rem + 4px);
			margin-right: 0.5rem;
			opacity: 0.25;

			@media (max-width: 767px) {
				width: calc(3rem + 4px);
				height: calc(3rem + 4px);
				margin-right: 0.25rem;
			}
		}

		strong {
			font-size: 1.15rem;
			color: rgba(#16193A, 0.5);

			@media (max-width: 767px) {
				font-size: 1rem;
			}
		}
	}

	.documents-signed {
		display: flex;
		position: relative;
		transition: 0.15s ease all;
		border-radius: 0.5rem;
		border: 1px solid transparent;
		margin-top: 0.5rem;
		align-items: center;

		@media (max-width: 767px) {
			background: transparent;
			padding: 1rem 0;
		}

		img {
			width: 2rem;
			height: 2rem;
			margin-right: 0.5rem;

			@media (max-width: 767px) {
				width: calc(3rem + 4px);
				height: calc(3rem + 4px);
				margin-right: 0.25rem;
			}
		}

		strong {
			font-size: 1rem;
			color: #1FAE5D;

			@media (max-width: 767px) {
				font-size: 1rem;
			}
		}
	}
}
</style>