<template>
    <form action="#" method="post" @submit.prevent="saveChanges" class="account-screen">

        <!-- Desktop Screen Title -->
        <h3 class="screen-title mb-3 d-none d-md-block">Change Your Password</h3>

        <div class="account-dashboard-box mb-3">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-field">
                        <label for="currentPassword" class="form-label">Current Password</label>
                        <input type="password" class="form-control" name="currentPassword" id="currentPassword" v-model="currentPassword" placeholder="Enter your current password" required>
                    </div>
                    <div class="form-field mt-3 pt-3">
                        <label for="newPassword" class="form-label">New Password</label>
                        <input type="password" class="form-control" name="newPassword" id="newPassword" v-model="newPassword" placeholder="Enter your new password" required>
                    </div>
                    <div class="form-field mt-3 pt-3">
                        <label for="confirmPassword" class="form-label">Confirm Password</label>
                        <input type="password" class="form-control" name="confirmPassword" id="confirmPassword" v-model="confirmPassword" placeholder="Confirm your new password" required>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="password-errors mt-3" v-if="showErrors">
                        <p class="text-danger fw-bolder small m-0" v-if="newPassword && newPassword.length < 8">Password should be at least 8 characters</p>
                        <p class="text-danger fw-bolder small m-0" v-if="!matchRegex(newPassword, /[A-Z]/)">Add at least one uppercase character (A-Z)</p>
                        <p class="text-danger fw-bolder small m-0" v-if="!matchRegex(newPassword, /[a-z]/)">Add at least one lowercase character (a-z)</p>
                        <p class="text-danger fw-bolder small m-0" v-if="!matchRegex(newPassword, /[0-9]/)">Add at least one numeric character (0-9)</p>
                        <p class="text-danger fw-bolder small m-0" v-if="newPassword !== confirmPassword">Passwords do not match</p>
                    </div>
                    <p class="small fst-italic text-muted mt-3">Password should be at least 8 characters, contain an uppercase character, a lowercase character, a number and a symbol.</p>
                    <p class="text-danger fw-bolder small mt-4" v-if="error">{{ error }}</p>
                    <p class="text-success fw-bolder small mt-4" v-if="success">Your password has been updated.</p>
                </div>
            </div>
        </div>

        <div class="account-screen-actions">
            <button type="submit" :class="{'btn btn-primary': true, 'disabled': saving, 'changed': changed}" :disabled="saving || !changed">
                <span class="spinner-border" v-if="saving"></span>
                <span :class="{'opacity-0': saving}">Save Changes</span>
            </button>
        </div>

    </form>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "Security",
    components: {},
    data() {
        return {
            success: false,
            error: false,
            changed: false,
            saving: false,
            showErrors: false,
            currentPassword: null,
            newPassword: null,
            confirmPassword: null,
        }
    },
    created() {
        let propertiesToWatch = Object.keys(this.$data).filter(prop => prop !== 'changed');
        propertiesToWatch.forEach(prop => {
            this.$watch(prop, () => {
                this.changed = true;
            }, { deep: true });
        });
    },
    methods: {
        matchRegex(input, regex) {
            return regex.test(input);
        },
        checkPassword() {
            if(!this.newPassword) return false;
            if(this.newPassword.length < 8) return false;
            if(!this.matchRegex(this.newPassword, /[A-Z]/)) return false;
            if(!this.matchRegex(this.newPassword, /[a-z]/)) return false;
            if(!this.matchRegex(this.newPassword, /[0-9]/)) return false;
            return this.newPassword === this.confirmPassword;
        },
        saveChanges() {
            this.showErrors = false;
            this.error = false;
            this.success = false;

            // Verify Password
            if(!this.checkPassword()) {
                this.showErrors = true;
                return false;
            }

            // Save changes
            this.saving = true;
            repository.post("/me/security/password", {
                currentPassword: this.currentPassword,
                newPassword: this.newPassword,
                confirmPassword: this.confirmPassword,
            })
                .then(response => {
                    console.log(response);
                    this.saving = false;
                    this.error = false;
                    this.success = true;
                    this.newPassword = null;
                    this.currentPassword = null;
                    this.confirmPassword = null;
                    this.$nextTick(() => {
                        this.changed = false;
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.saving = false;
                    this.error = error.response.data.message;
                })
        },
    },
}
</script>

<style lang="scss">
</style>