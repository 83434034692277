<template>
	<div class="screen">

		<!-- Mobile Header -->
		<MobileHeader :menu-toggle="true" title="Portfolio"/>

		<div class="container-fluid">

			<!-- Portfolio Box -->
			<div class="portfolio-box mb-4">
				<div class="row align-items-center">
					<div class="col-md-5 me-auto">
						<h3>High Quality Properties.<br>Low Minimum.<br>Impactful Returns.</h3>
						<p>We aim to purchase stabilized properties in our target geography. Our team works to improve the property and improves the lives of tenants and communities.</p>
						<router-link to="/invest" class="btn btn-primary">Invest Now</router-link>
					</div>
					<div class="col-md-6 d-none d-md-block">
						<img src="../../assets/where-we-invest.jpg" class="img-fluid" alt="High Quality Properties">
					</div>
				</div>
			</div>

			<!-- Desktop Screen Title -->
			<h3 class="screen-title mb-3">Properties Portfolio</h3>
			<div class="row" v-if="!properties.loaded">
				<div class="col-md-6 col-xl-4 mb-4">
					<PropertyBox :loading="true"/>
				</div>
				<div class="col-md-6 col-xl-4 mb-4">
					<PropertyBox :loading="true"/>
				</div>
				<div class="col-md-6 col-xl-4 mb-4">
					<PropertyBox :loading="true"/>
				</div>
			</div>
			<div class="row" v-if="properties.loaded">
				<div class="col-md-6 col-xl-4 mb-4" v-for="(property, key) in properties.items" :key="key">
					<PropertyBox :property="property" :openPopup="openPropertyPopup"/>
				</div>
			</div>

			<!-- Property Popup -->
			<Popup :class="{'active': modal !== null}" size="large" ref="propertyPopup">
				<PropertyDetails :propertyId="modal"/>
			</Popup>

		</div>

	</div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader.vue";
import repository from "@/repository/repository";
import PropertyBox from "@/components/widgets/PropertyBox.vue";
import Popup from "@/components/widgets/Popup.vue";
import PropertyDetails from "@/components/widgets/PropertyDetails.vue";
export default {
    name: "Portfolio",
	components: {PropertyDetails, Popup, PropertyBox, MobileHeader},
	data() {
        return {
			properties: {
				items: [],
				loaded: false,
			},
			modal: null,
        }
    },
	created() {
		// Load properties
		this.loadProperties();

		// Close modal on click
		let app = this;
		window.addEventListener('popstate', function () {
			app.modal = null;
			document.documentElement.style.overflow = "auto";
		});
	},
	methods: {
		loadProperties() {
			this.properties.loaded = false;
			repository.get("/properties")
				.then(response => {
					this.properties.items = response.data.properties;
					this.properties.loaded = true;
				});
		},
		openPropertyPopup(propertyId) {
			if(this.isMobile()) {
				this.$router.push('/portfolio/' + propertyId);
			} else {
				history.pushState({page: 1}, "", '/portfolio/' + propertyId);
				document.documentElement.style.overflow = "hidden";
				this.modal = propertyId;
				this.$refs.propertyPopup.$el.scrollTop = 0;
			}
		},
		isMobile() {
			return screen.width <= 760;
		},
	},
}
</script>

<style lang="scss" scoped>
.portfolio-box {
	padding: 2.5rem 4rem;
	background: #fff;
	border-radius: 0.5rem;

	@media (max-width: 767.99px) {
		width: calc(100% + 3rem);
		margin: -2.5rem -1.5rem 1.5rem -1.5rem;
		padding: 2rem 1.5rem;
	}

	h3 {
		font-size: 2rem;
		font-weight: bold;
		color: #16193A;
	}
}
</style>