export function getNextDate(day) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const nextDate = new Date(currentYear, currentMonth, day);

    if (nextDate < currentDate) {
        nextDate.setMonth(currentMonth + 1);
    }

    const nextDay = nextDate.getDate();
    const nextMonth = nextDate.toLocaleString('default', { month: 'long' });

    return `${nextMonth} ${nextDay}`;
}

/**
 * Converts a date string in the format "2023-08-13"
 * to a formatted string in the style "August 13, 2023"
 *
 * @param date
 * @returns {string}
 */
export function readableDate(date) {
    // Convert the given date string to a Date object
    const dateObject = new Date(date);

    // Options for formatting the date
    let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    // Convert the Date object to the desired format
    return dateObject.toLocaleString('en-US', options);
}