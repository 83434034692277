<template>
    <form action="#" method="post" @submit.prevent="saveChanges" class="account-screen">

        <!-- Desktop Screen Title -->
        <h3 class="screen-title mb-3 d-none d-md-block">Distributions</h3>

        <div class="account-dashboard-box mb-4">
            <div class="spinner-border" v-if="!loaded"></div>
            <div v-if="loaded">
                <div class="form-field mb-3">
                    <label class="form-label">I would like my distributions to be:</label>
                </div>
                <div class="form-check radio-checkbox">
                    <input class="form-check-input" type="radio" name="dividends" value="reinvested" id="dividendReinvested" v-model="dividendReinvestment">
                    <label class="form-check-label label-lg" for="dividendReinvested">
                        <span>Reinvested according to the <a :href="dripLink" target="_blank">Distribution Reinvestment Plan</a></span>
                    </label>
                </div>
                <div class="form-check radio-checkbox mt-3">
                    <input class="form-check-input" type="radio" name="dividends" value="distributed" id="dividendDistributed" v-model="dividendReinvestment">
                    <label class="form-check-label label-lg" for="dividendDistributed">
                        <span>Deposited to my bank account</span>
                    </label>
                </div>
            </div>

            <p class="text-danger fw-bolder small mt-4" v-if="error">Please select an option to continue</p>
        </div>

        <div class="account-screen-actions">
            <button type="submit" :class="{'btn btn-primary': true, 'disabled': saving || !loaded, 'changed': changed}" :disabled="saving || !loaded || !changed">
                <span class="spinner-border" v-if="saving"></span>
                <span :class="{'opacity-0': saving}">Save Changes</span>
            </button>
        </div>

    </form>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "Distributions",
    data() {
        return {
            loaded: false,
            error: false,
            changed: false,
            saving: false,
            dividendReinvestment: null,
        }
    },
    created() {
        this.loadDetails();
    },
    methods: {
        loadDetails() {
            this.loaded = false;
            this.error = false;

            repository.get("/me/distributions")
                .then(response => {
                    this.dividendReinvestment = response.data.dividend_reinvestment;

                    // Finish loading
                    this.loaded = true;
                    this.$nextTick(() => {
                        this.changed = false;
                    });
                })
                .catch(() => {
                    this.loaded = true;
                    this.error = true;
                })
        },
        saveChanges() {
            // Start saving effects
            this.error = false;
            this.saving = true;

            // Prepare payload
            let payload = {
                dividend_reinvestment: this.dividendReinvestment,
            };

            // Submit to server
            repository.post("/me/distributions", payload)
                .then(() => {
                    this.saving = false;
                    this.$nextTick(() => {
                        this.changed = false;
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.saving = false;
                    this.error = error.response.data.code;
                })
        },
    },
    computed: {
        dripLink() {
            return "https://reiturn.com/drip-plan-agreement";
        },
    },
    watch: {
        dividendReinvestment() {
            this.changed = true;
        }
    },
}
</script>

<style lang="scss" scoped>
.form-check.radio-checkbox {
    cursor: pointer;
    position: relative;
    padding-left: 2.5rem;

    .form-check-input {
        border-width: 4px;
        width: 1.25rem;
        height: 1.25rem;
        box-shadow: #dcddde 0 0 0 0.15rem;
        border-color: #fff;
        transition: 0.15s ease all;
        float: none;
        position: absolute;
        top: 0.25rem;
        left: 0.15rem;
        margin: 0;

        &:checked {
            box-shadow: #F37021 0 0 0 0.15rem;
            background: #F37021;
            border-color: #fff;
        }
    }

    .form-check-label {
        padding: 0;
        margin: 0;
        display: block;
        cursor: pointer;

        &.label-lg {
            color: #16193A;
            font-size: 1.15rem;

            @media (max-width: 767px) {
                font-size: 1rem;
            }
        }

        strong {
            display: block;
            font-size: 1.15rem;
            font-weight: bold;
            color: #16193A;

            @media (max-width: 767px) {
                font-size: 1rem;
            }
        }

        a {
            color: #F37021;
            font-weight: 600;
        }
    }
}
</style>